<template>
    <div class="container">
        <span class="btnArea">
            <el-button
              v-for="(item, index) in dBtn1"
              :key="index"
              :class="item == activeBtn1 ? 'isActiveStyle' : ''"
              size="mini"
              class="noActive" 
              plain
              @click="fnChangeBtn1(item)"
              >{{ item }}</el-button
            >
          </span>
    </div>
</template>


<script>

export default {
    // 从父组件传值的
    props:{
        // dButtons1:{
        //     type:Array,
        //     default:""
        // },
        // dButtons1_current:{
        //     type:String,
        //     default:""
        // },
         default: {
            type: String,
            default: '',
            },
    },
    methods:{
       fnChangeBtn1(val){
        console.log(val,'凯飞');
        this.activeBtn1 = val
        this.$emit('CunknowButtons_select_val',val)
        
       }
    },
    components:{
      
    },
    computed:{
       
    },
    data(){
        return {
          dBtn1: ["1D", "3D", "5D", "7D", "10D"],
          activeBtn1: this.default,
            //  dButtons1:['概览','市值相关','流动性相关'],
            //  dButtons1_current:'概览',
        }

    }
}
</script>

<style lang="less"  scoped>
    
.container{
   
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.25px;
    line-height: 20px;
    // color: rgba(255, 255, 255, 1);
    display: flex;
    align-items: center;
}

 .el-button {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  height: 35px;
}

 .noActive {
  // background-color: var(--Btn-download-bgc);
  // color: var(--Btn-download-color);
  // border: 1px solid #4a4c51;
  // margin-right:10px;


  background-color: var(--Btn-bgc2);
  color: var(--stock_area_item_color1) ;
  border: 1px solid var(--Btn-borderColor) ;


}

.isActiveStyle{
  background-color: var(--primary-color) !important;
  color:var(--Btn-color) !important;
  // border: none;
  border: 1px solid var(--primary-color) !important;
}


 .btnArea .el-button:hover {
  // background-color: var(--primary-color) !important;
  // color: var(--Btn-color) !important;
  // border: none;


  // background-color: var(--Btn-bgc_hover);
  background-color: var(--Btn-bgc_hover);
  color: var(--Btn-color2);
  border: 1px solid var(--primary-color) ;
}



</style>