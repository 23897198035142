<template>
    <div class="container--Style">
        <div class="primary">
            <div class="title">
                <div class="left">
                    <span class="hello--Style">Hi，{{InfoData.name}}
                        <span v-if="InfoData.gender=='男'">先生</span>
                        <span v-else>女士</span>
                    </span>
                    <span class="desc--Style">欢迎使用薇碧进销存管理系统！</span>
                </div>

                <div class="imgarea">
                    <!-- <img src="../assets/1657180315504.jpg" alt=""> -->
                </div>
            </div>

            <div class="content">
                <div class="tit">
                    个人信息
                </div>
                <div class="first">
                    <span>
                        <div>账号</div>
                        <div>{{InfoData.userid}}</div>
                    </span>
                    <span>
                        <div>手机</div>
                        <div>+86 {{InfoData.mobile}}</div>
                    </span>

                    <span>
                        <div>座机</div>
                        <div v-if="InfoData.telephone">{{InfoData.telephone}}</div>
                        <div v-else>未设置</div>
                    </span>

                    <span>
                        <div>邮箱</div>

                        <div v-if="InfoData.email">{{InfoData.email}}</div>
                        <div v-else>未设置</div>
                    </span>

                </div>


                <div class="second">
                    <span>
                        <div>管理主体</div>
                        <div>薇碧集团</div>
                    </span>
                    <!-- <span>
                    <div>部门</div>
                    <div></div>
                </span> -->
                    <!-- <span>
                    <div>所属下级</div>
                    <div></div>
                </span> -->
                    <span>

                        <div>职位</div>
                        <div>{{InfoData.position_name}}</div>
                    </span>
                </div>


                <div class="third">
                    <div class="title_team">
                        所属团队
                    </div>
                    <div class="content_team">
                        {{InfoData.department_full_name}}
                    </div>
                </div>


            </div>

            <div class="authority--Style">
                <div class="tit">
                    权限

                    <el-button size="mini" class="setProfileBtn setProfileStyle" @click="setProfile">设置偏好</el-button>
                </div>
                <div class="content_Style">
                    <div class="first_1">
                        <div class="tit">
                            操作仓库权限:

                        </div>
                        <div class="con">
                            <span v-for="(list,index) in InfoData.stores" :key="index">
                                {{list}}</span>
                        </div>
                    </div>

                    <div class="second_1">
                        <div class="tit">
                            个人权限:

                        </div>
                        <div class="con">
                            <span v-for="(list,index) in InfoData.user_tag_names" :key="index">
                                {{list}}</span>
                        </div>
                    </div>

                    <div class="thirty">
                        <div class="tit">
                            部门权限:

                        </div>
                        <div class="con">
                            <span v-for="(list,index) in InfoData.department_tag_names" :key="index">
                                {{list}}</span>
                        </div>
                    </div>


                    <div class="fourthly">
                        <div class="tit">
                            客户组权限:

                        </div>
                        <div class="con">
                            <span v-for="(list,index) in InfoData.group_names" :key="index">
                                {{list}}</span>
                        </div>
                    </div>


                    <div class="fifth">
                        <div class="tit">
                            客户权限:

                        </div>
                        <div class="con">

                            <span v-for="(list,index) in InfoData.customer_names" :key="index">
                                {{list}}</span>
                        </div>
                    </div>

                    <div class="sixth">
                        <div class="tit">
                            门店权限:

                        </div>
                        <div class="con">
                            <span v-for="(list,index) in InfoData.shop_names" :key="index">
                                {{list}}</span>
                        </div>
                    </div>

                    <div class="seven">
                        <div class="tit">
                            偏好仓库:

                        </div>
                        <div class="con">
                            <span>{{defaultStoreName}}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="logs_style">
                <div class="tit">
                    操作记录
                </div>
                <div class="content_style">
                    <el-timeline>
                        <el-timeline-item v-for="(activity, index) in logsData" :key="index"  icon="el-icon-check" type="primary"
                            :timestamp="activity.create_time">
                            {{activity.log}} <span class="textColor_timeline">—{{activity.user_name}}</span>
                        </el-timeline-item>
                    </el-timeline>

                    <!-- 分页器 -->
                    <el-pagination @current-change="handleCurrentChange" :current-page="form_logs.page"
                        :page-sizes="[5]" :page-size="form_logs.per_page"
                        layout="total, sizes, prev, pager, next, jumper" :total="logsTotal" align="center">
                    </el-pagination>
                </div>
            </div>
        </div>

        <div class="vice">
            <div class="inventoryWarning--Style">
                <div class="tit--Style">
                    <i class="el-icon-warning icon--Style"></i>
                    库存预警
                </div>
                <div class="content--Style">
                    <div v-for="(list,index) in inventoryWarnInfoData" :key="index">
                        <span class="el-icon-news icon-news--Style"></span>
                        <span>
                            <span>
                                {{list.sn}} {{list.name}} <span v-show="list.spec">({{list.spec}})</span>
                            </span><br>
                            <span class="inventoryDesc--Style">
                                预警数量：<i class="Number_333">{{list.warning}}</i> 现存：<i
                                    class="NumberRight">{{list.count}}</i> 存有仓库：<i
                                    class="NumberRight">{{list.storages_count}}</i>个
                            </span>
                        </span>
                    </div>

                </div>
            </div>

            <div class="pasytDueWarning--Style">
                <div class="tit--Style">
                    <i class="el-icon-warning icon__pasytDue--Style"></i>
                    过期预警
                </div>
                <div class="content--Style">
                    <div v-for="(list,index) in pasytdueWarnInfoData" :key="index">
                        <span class="el-icon-alarm-clock icon__pasytDue-news--Style"></span>
                        <span>
                            <span>
                                {{list.sn}} {{list.name}} <span v-show="list.spec">({{list.spec}})</span>
                            </span><br>
                            <span class="inventoryDesc--Style">
                                仓库：<span class="word_333">{{list.store_name}}</span> 现存数量：<i
                                    class="NumberRight">{{list.quantity}}</i> <br>
                                批次：<span class="word_333">{{list.batch_name}}</span> <br>
                                限用日期：<span class="word_333">{{list.expriy_date}}</span> <br>
                                还有多少天过期：<span class="word_333">{{list.valid_day}}</span>
                            </span>
                        </span>
                    </div>

                </div>
            </div>
        </div>


        <el-dialog title="设置偏好仓库" :visible.sync="is_setProfile" width="28%">

            <el-form :model="form_setProfile" label-width="100px">
                <el-form-item label="选择仓库:" prop="name">
                    <el-select size="small" clearanle v-model="form_setProfile.store_id" placeholder="请选择">
                        <el-option v-for="item in storeData" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="is_setProfile = false">取 消</el-button>
                <el-button size="mini" class="frontBtn" @click="setProfileDo">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>


<script>
    import {
        domainApi
    } from '../config'

    import {
        erpApi
    } from '../config'


    export default {
        data() {
            return {

                InfoData: '',
                inventoryWarnInfoData: [],
                pasytdueWarnInfoData: [],
                logsData: [],
                logsTotal: 0,
                form_logs: {
                    page: 1,
                    per_page: 5
                },

                is_setProfile: false,
                form_setProfile: {
                    store_id: '',
                },
                storeData: [],
                defaultStoreId: '',
                defaultStoreName: '',

            }
        },
        created() {
            this.getInfo()
            this.getInventoryWarnInfo()
            this.getPasytdueWarnInfo()
            this.getLogs()
            this.getProfile()

        },
       
        methods: {
            // 判断状态
            judgeState(res){
                // if (res.data.error_code == 1000) {
                //     this.$confirm('您现在还未登录, 请扫码登录', '提示', {
                //         confirmButtonText: '确定',
                //         cancelButtonText: '取消',
                //         type: 'error'
                //     }).then(() => {
                //         this.$router.push({
                //             path: '/login',
                //         })
                //     }).catch(() => {
                //         this.$message({
                //             type: 'info',
                //             message: '已取消'
                //         });
                //     });
                // }

                //  if (res.data.error_code > 50000) {
                //     this.$confirm('您尚无此权限', '提示', {
                //         confirmButtonText: '确定',
                //         type: 'warning'
                //     }).then(() => {
                //         this.$message({
                //             type: 'info',
                //             message: '需要此功能权限，请联系管理员'
                //         });
                //     }).catch(() => {
                //         this.$message({
                //             type: 'info',
                //             message: '已取消'
                //         });
                //     });
                // }
            },

            async getInfo() {
                let res = await this.$https.post(domainApi + '/profile?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.InfoData = res.data
                console.log(this.InfoData, 'InfoData');
            },

            // 获取库存预警信息
            async getInventoryWarnInfo() {
                let res = await this.$https.post(erpApi + '/storage/warning?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.inventoryWarnInfoData = res.data

            },


            // 获取过期预警信息
            async getPasytdueWarnInfo() {
                let res = await this.$https.post(erpApi + '/storage/expiring?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.pasytdueWarnInfoData = res.data
            },

            // 获取操作记录
            async getLogs() {
                let params = this.form_logs
                let res = await this.$https.post(domainApi + '/logs?_ajax=1', params)
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.logsData = res.data.data
                this.logsTotal = res.data.total
            },

            handleCurrentChange(data) {
                this.form_logs.page = data
                this.getLogs()
            },

            async getStore() {
                let res = await this.$https.post(erpApi + '/store/select?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.storeData = res.data
            },

            async getProfile() {
                let res = await this.$https.post(domainApi + '/profile?_ajax=1')
                if (res.status !== 200) return this.$message.error(res.statusText)
                this.defaultStoreName = res.data.default_store_name
                this.form_setProfile.store_id = res.data.default_store_id
            },

            setProfile() {
                this.form_setProfile.store_id = ''
                this.is_setProfile = true
                this.getStore()
            },
            async setProfileDo() {
                let params = this.form_setProfile
                let res = await this.$https.post(domainApi + '/set_profile?_ajax=1', params)
                if (res.data.error_code !== 0) return this.$message.error(res.data.msg)
                this.$message.success(res.data.msg)
                this.getProfile()
                this.is_setProfile = false
            },


        },
    }
</script>

<style lang="less" scoped>
    .content,
    .logs_style,
    .title {
        background-color: #ffffff;
        width: 100%;
        height: auto;
        margin: 10px 0px;
        border-radius: 3px;
        padding: 25px 32px;
        box-sizing: border-box;
    }

    .hello--Style {
        font-size: 20px;
        color: #191919;
        margin-right: 10px;
    }

    .desc--Style {
        font-size: 14px;
        color: #191919;
    }


    .content,
    .logs_style {
        margin-top: 20px;
        height: auto;
    }

    .content .tit,
    .authority--Style .tit,
    .logs_style .tit {
        color: #191919;
        font-size: 20px;
        margin-bottom: 30px;
    }



    .first,
    .second {
        display: flex;
        margin-bottom: 50px;
    }

    .first span,
    .second span {
        width: 200px;
        margin-right: 100px;
    }

    .first span div:nth-child(1),

    .second span div:nth-child(1) {
        color: #999999;
        font-size: 14px;
        margin-bottom: 20px;
    }

    .first span div:nth-child(2),
    .second span div:nth-child(2) {
        color: #666666;
        font-size: 16px;
    }

    .title_team {
        color: #999999;
        font-size: 14px;
        margin-bottom: 20px;
    }

    .content_team {
        color: #666666;
        font-size: 14px;
    }

    .left {}

    .title {
        position: relative;

    }

    .imgarea {
        position: absolute;
        right: 5%;
        top: 50%;
        transform: translateY(-50%);
        width: 15%;
    }

    .imgarea img {
        width: 100%;
    }

    .container--Style {
        display: flex;
    }

    .primary {
        flex: 8;
        box-sizing: border-box;
    }

    .vice {
        flex: 2;
        margin-left: 20px;
        margin-top: 10px;
        background-color: #fff;
        height: 800px;
        border-radius: 3px;


    }

    .inventoryWarning--Style,
    .pasytDueWarning--Style {
        width: 320px;
        background-color: #ffffff;
    }

    .inventoryWarning--Style .tit--Style,
    .pasytDueWarning--Style .tit--Style {
        width: 100%;
        height: 39px;
        display: flex;
        align-items: center;
        color: #333;
        font-size: 14px;
        font-weight: 550;
        // background-color: pink;
        background-image: linear-gradient(to right, #faecd8, #fffefe);
        padding: 0 16px;
        border-radius: 3px;
        box-sizing: border-box;


    }

    .pasytDueWarning--Style .tit--Style {
        // background-image: linear-gradient(to right, #d9ecff, #fffefe);
        background-image: linear-gradient(to right, #fde2e2, #fffefe);
        margin-top: 20px;
    }

    .icon--Style,
    .icon__pasytDue--Style {
        margin-right: 8px;
        vertical-align: middle;
        color: #f1a276;
        font-size: 14px;
    }

    .icon__pasytDue--Style {
        // color: #409eff;
        color: #f56c6c;
    }


    .vice .inventoryWarning--Style .content--Style div,
    .vice .pasytDueWarning--Style .content--Style div {
        padding: 10px 0px 10px 20px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        margin-bottom: 10px;
    }

    .icon-news--Style,
    .icon__pasytDue-news--Style {
        margin-right: 8px;
        color: #f1a276;
        font-size: 14px;
        margin-top: 3px;
    }

    .icon__pasytDue-news--Style {
        // color: #409eff;
        color: #f56c6c;

    }

    .vice .inventoryWarning--Style .content--Style div span:nth-child(2) span:nth-child(1),
    .vice .pasytDueWarning--Style .content--Style div span:nth-child(2) span:nth-child(1) {
        font-size: 12px;
        color: #333;
    }

    .inventoryDesc--Style {
        display: inline-block;
        margin-top: 5px;
        font-size: 12px;
        color: #999;
    }

    .vice .inventoryWarning--Style .content--Style,
    .vice .pasytDueWarning--Style .content--Style {
        height: auto;
        max-height: 320px;
        overflow-y: auto;
        border-radius: 3px;
    }



    .con::-webkit-scrollbar,
    .vice .inventoryWarning--Style .content--Style::-webkit-scrollbar,
    .vice .pasytDueWarning--Style .content--Style::-webkit-scrollbar {

        height: 8px;
        width: 2px;
    }



    .con::-webkit-scrollbar-thumb,
    .vice .inventoryWarning--Style .content--Style::-webkit-scrollbar-thumb,
    .vice .pasytDueWarning--Style .content--Style::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
    }

    .authority--Style {
        width: 100%;
        height: auto;
        background-color: #fff;
        margin: 20px 0px 0px 0px;
        border-radius: 3px;
        padding: 28px 32px;
        padding-bottom: 0px;
        box-sizing: border-box;
    }


    .authority--Style .content_Style {
        display: flex;
    }

    .authority--Style .content_Style .first_1,
    .authority--Style .content_Style .second_1,
    .authority--Style .content_Style .thirty,
    .authority--Style .content_Style .fourthly,
    .authority--Style .content_Style .fifth,
    .authority--Style .content_Style .seven,
    .authority--Style .content_Style .sixth {
        flex: 1;
        margin-right: 20px;
        text-align: center;
    }




    .authority--Style .content_Style .first_1 .tit,
    .authority--Style .content_Style .second_1 .tit,
    .authority--Style .content_Style .thirty .tit,
    .authority--Style .content_Style .fourthly .tit,
    .authority--Style .content_Style .fifth .tit,
    .authority--Style .content_Style .seven .tit,
    .authority--Style .content_Style .sixth .tit {
        color: #666666;
        font-size: 16px;
    }



    .con {
        max-height: 180px;
        overflow-y: auto;
        margin-bottom: 16px;
    }

    .authority--Style .content_Style .first_1 .con span,
    .authority--Style .content_Style .second_1 .con span,
    .authority--Style .content_Style .thirty .con span,
    .authority--Style .content_Style .fourthly .con span,
    .authority--Style .content_Style .fifth .con span,
    .authority--Style .content_Style .seven .con span,
    .authority--Style .content_Style .sixth .con span {
        display: block;
        color: #777;
        font-size: 14px;
        margin-top: 15px;

    }


    .logs_style .content_style {
        padding-left: 0;
    }

    .setProfileStyle {
        float: right;
    }
</style>