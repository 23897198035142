<template>
  <div class="shadow" v-if="show">
    <div class="close_b" @click="close"></div>
    <div class="case">
      <div class="big_title">
        更新说明 {{ info.version }}
        <div class="close" @click="close">x</div>
      </div>
      <div class="time">发布时间：{{ info.date }}</div>
      <div v-html="info.content"></div>
      <div class="tips">
        <div class="checked" @click="checked = !checked">
          <div class="g" v-show="checked">√</div>
        </div>
        <div class="tips_t" @click="checked = !checked">不再显示</div>
      </div>
    </div>
  </div>
</template>

<script>
import { storageUpdate } from '@/utils/util';

import update_info from './update_info';

export default {
  props: {
    show: Boolean,
    callback: Function,
  },
  data() {
    return {
      checked: false,
      info: update_info[0],
    };
  },
  methods: {
    close() {
      this.$emit('update:show', false);
      storageUpdate(this.checked);
      if (this.checked) {
        typeof this.callback === 'function' && this.callback();
      }
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
@vh:1 /10.8vh;
.shadow {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 999;

  .close_b {
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
  }
  .case {
    position: absolute;
    width: 80%;
    height: 80vh;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(42, 43, 47, 0.9);
    border-radius: 5vh;
    border: 2px solid white;
    color: white;
    font-size: 18 * @vh;
    .big_title {
      margin: auto;
      padding-top: 1vh;
      width: 95%;
      height: 5vh;
      border-bottom: 1px solid white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 22 * @vh;
      .close {
        font-size: 35 * @vh;
        line-height: 1;
        cursor: pointer;
      }
    }
    .time {
      margin: auto;
      width: 95%;
      height: 4vh;
      line-height: 4vh;
      font-size: 16 * @vh;
    }
    /deep/.content {
      margin: auto;
      width: 95%;
      height: 64vh;
      line-height: 2;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 10 * @vh;
        height: 10 * @vh;
        background-color: #dfd8d0;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #4de7e9;
      }
      span {
        color: #4de7e9;
      }
      .b_t {
        margin: auto;
        width: 100%;
      }
      .s_t {
        margin: auto;
        width: 93%;
      }
    }
    .tips {
      margin: auto;
      padding-top: 1vh;
      width: 90%;
      height: 3vh;
      display: flex;
      align-items: center;
      font-size: 16 * @vh;
      .checked {
        margin-right: 1vh;
        width: 2vh;
        height: 2vh;
        box-sizing: border-box;
        border: 1px solid white;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #4de7e9;
        font-size: 22 * @vh;
        cursor: pointer;
      }
      .tips_t {
        margin-right: 4vh;
        cursor: pointer;
        user-select: none;
      }
    }
  }
}
</style>
