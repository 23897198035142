<template>
  <div class="home" id="rootchart" :style="cssVars">
    <div class="title">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="市场走势" name="first">
          <ZouShi ref="one"></ZouShi>
        </el-tab-pane>

        <el-tab-pane label="收益分析" name="second">
          <BenefitAnalysis ref="second" />
        </el-tab-pane>

        <el-tab-pane label="风险相关" name="third">
          <RiskRelated ref="third" />
        </el-tab-pane>
        <el-tab-pane label="流动性分析" name="fourth">
          <Mobility ref="fourth" />
        </el-tab-pane>
        <el-tab-pane label="资金数据" name="fivth">
          <FinancialData ref="fivth" />
        </el-tab-pane>
      </el-tabs>
    </div>


  </div>
</template>

<script>
import RiskRelated from '@/components/MarketDynamics/RiskRelated';
import Mobility from '@/components/MarketDynamics/Mobility';
import FinancialData from '@/components/MarketDynamics/FinancialData';
import BenefitAnalysis from '@/components/MarketDynamics/BenefitAnalysis';
import ZouShi from '@/components/MarketDynamics/ZouShi';

export default {
  components: {
    RiskRelated,
    Mobility,
    FinancialData,
    BenefitAnalysis,
    ZouShi
  },
  data() {
    return {
      activeName: 'first',
      TABPANEHEIGHT: 0,
      ROOTFONTFIZE: 0,
      sCurrentTheme: this.$store.state.theme == 'dark' ? true : false,
    };
  },

  created() {
    this.$nextTick(() => {
      let name = localStorage.getItem('activeName');
      this.handleClick({
        name: name,
      });
    });
  },
  mounted() {
    this.fnGetWidth();
  },
  beforeDestroy() {
  },
  methods: {

    // 获取当前屏幕宽度
    fnGetWidth() {
      var element = document.getElementById('rootchart');
      let ROOTFONTFIZE = element.offsetWidth;
      ROOTFONTFIZE = ROOTFONTFIZE > 1440 ? 1440 : ROOTFONTFIZE;
      ROOTFONTFIZE = ROOTFONTFIZE < 1400 ? ROOTFONTFIZE - 200 : ROOTFONTFIZE;

      this.ROOTFONTFIZE = ROOTFONTFIZE / 10;

      window.sessionStorage.setItem('ROOTFONTFIZE', this.ROOTFONTFIZE);

      let OFFSETHEIGHT = element.offsetHeight;
      this.TABPANEHEIGHT = OFFSETHEIGHT - 135;

      var chartColumn1 = document.getElementById('chartColumn1');
      chartColumn1.style.height = this.ROOTFONTFIZE * (480 / 144) + 'px';

      var chartColumn2 = document.getElementById('chartColumn2');
      chartColumn2.style.height = this.ROOTFONTFIZE * (500 / 144) + 'px';

      var chartColumn3 = document.getElementById('chartColumn3');
      chartColumn3.style.height = this.ROOTFONTFIZE * (388 / 144) + 'px';

      return;
    },

    handleClick(e) {
      console.log("market dynamics handleClick", e)
      this.$refs.fourth.fnDispose();
      this.activeName = e.name;
      localStorage.setItem('activeName', this.activeName);
      this.fnMethods(e.label);
    },
    fnMethods(data) {
      this.$eventBus.$emit('setBreadcrumbs', data ? data : '市场走势');
      console.log("fnMethods", this.activeName, data);

      if (this.activeName == 'first') {
        this.$refs.one.reload();
      }
      if (this.activeName == 'second') {
        this.$refs.second.reload();
      }
      if (this.activeName == 'third') {
        this.$refs.third.reload();
      }

      if (this.activeName == 'fourth') {
        this.$refs.fourth.reload();
        
      }

      if (this.activeName == 'fivth') {
        this.$refs.fivth.reload();
      }
    },


  },
  computed: {
    cssVars() {
      return {
        '--ROOTFONTFIZE': this.ROOTFONTFIZE + 'px',
        '--TABPANEHEIGHT': this.TABPANEHEIGHT + 'px',
      };
    },
    currentTheme() {
      return this.$store.state.theme; // 从Vuex中获取当前的主题值
    },
  },
  // watch: {
  //   currentTheme(newVal, oldVal) {
  //     this.$nextTick(() => {
  //       this.sCurrentTheme = this.$store.state.theme == 'dark' ? true : false;
  //     });
  //   },
  // },
};
</script>

<style lang="less" scoped>
.home {
  background-color: var(--bigger-card-bgc);
  height: 100%;
}

// el-tabs
.el-tabs {
  background-color: var(--bigger-card-bgc);
}





.overall3 {
  background-color: var(--charts_bgc);

  height: calc(var(--ROOTFONTFIZE) * (673 / 144));
  margin-top: calc(var(--ROOTFONTFIZE) * (5 / 144));
  padding-top: calc(var(--ROOTFONTFIZE) * (15 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (39 / 144));
  color: #fff;
  box-sizing: border-box;
}

::v-deep .el-tabs__item {
  color: var(--el_tabs_item_color);
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  font-weight: 400;
  letter-spacing: 0.1px;
}

::v-deep .el-tabs__item:hover {
  color: var(--el_tabs_item_color_active_hover) !important;
}

::v-deep .el-tabs__item.is-active {
  color: var(--el_tabs_item_color_active) !important;
  font-size: 18px;
}

/*去掉tabs底部的下划线*/
::v-deep .el-tabs__nav-wrap::after {
  position: static !important;
}

/*去掉切换时el-tab-pane底部的蓝色下划线*/
::v-deep .el-tabs__active-bar {
  background-color: var(--primary-color) !important;
}

::v-deep .el-tab-pane {
  display: block;
  height: var(--TABPANEHEIGHT);
  overflow-y: auto;
  box-sizing: border-box;

  padding-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
}



.btnArea6 {
  display: flex;
  justify-content: space-between;
  padding-left: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.CTimeButtonStyle {
  margin-left: 20px;
  // position: absolute;
  // left: 50%;
  // transform: translateX(-50%);
  // margin: 0 auto;
}



.HEIGHTAUTO {
  height: auto;
  padding-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
}



.bn {
  border: none;
}


.overall3_top {
  display: flex;
  justify-content: space-between;
}

.overall3_tit {
  text-align: center;
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0px;
  line-height: calc(var(--ROOTFONTFIZE) * (16 / 144));
  color: rgba(218, 218, 218, 1);
  margin: calc(var(--ROOTFONTFIZE) * (30 / 144)) 0 calc(var(--ROOTFONTFIZE) * (22 / 144)) 0;
}

.overall3_bottom .overall3_bottom_fist,
.overall3_bottom .overall3_bottom_second {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding: 0 calc(var(--ROOTFONTFIZE) * (70 / 144));
}

.chart_area {
  flex: 1;
  // width: 33%;
  // width: 308px;
  height: calc(var(--ROOTFONTFIZE) * (247 / 144));
  background-color: #1a1c21;
  margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  border: 0.21px solid rgba(135, 135, 135, 1);
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  margin-right: calc(var(--ROOTFONTFIZE) * (50 / 144));
}

.chart6_name {
  // padding-bottom: 5px;
  padding-left: calc(var(--ROOTFONTFIZE) * (25 / 144));
  padding-top: calc(var(--ROOTFONTFIZE) * (13 / 144));
  color: rgba(255, 255, 255, 1);
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
}

// ----------------收益分析---------------------------------------------------------------------------------------------------------

.income {}

.income_card1,
.income_card2 {
  height: 474px;
  border-radius: 20px;
  padding-right: 38px;
  padding-top: 15px;
  background-color: #1d1f25;
  margin-bottom: 45px;
}

.income_card2_820 {
  height: 820px;
}

.income_card1_tit,
.income_card2_tit {
  display: flex;
  align-content: center;
  justify-content: space-between;
  border-left: 5px solid #1573fe;
  padding-left: 13px;
}

.income_card1_tit_left,
.income_card2_tit_left {
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1);
}

.el-icon-download {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
}

.income_card1_con_left {
  position: relative;
}

.income_card1_con_right,
.income_card2_con_right {
  padding-left: 60px;
}

.setArea1 {}

.setArea1_item,
.setArea2_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.setArea1_item_left,
.setArea2_item_left {
  flex: 3;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  color: rgba(255, 255, 255, 1);
}

.setArea1_item_right,
.setArea2_item_right {
  flex: 7;
  display: flex;
  justify-content: end;
}

.setArea1_item_right .el-checkbox,
.setArea2_item_right .el-checkbox {
  margin-left: 35px;
  color: #a5a6a9;
}

.moreYear {
  display: flex;
  justify-content: start;
  width: 100%;
}

.bar {
  width: 300px;
  width: 255px;
}

.sDiffTimeReturnDDL {
  color: rgba(158, 158, 158, 1);
  position: absolute;
  bottom: 30;
  left: 20;
}

.chartColumn2_2_style {}

.chartColumn2_1_style,
.chartColumn2_2_style_pack {
  border: 0.2px solid #2a2c31;
  border-radius: 10px;
  margin: 15px;
  background-color: #1a1c21;
  display: flex;
  justify-content: center;
  position: relative;
}

.income_card2_con {}

.income_card2_con_left {}

.income_card2_btn {
  margin-top: 9px;
}

.title_chart6 {
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20.32px;
  color: rgba(218, 218, 218, 1);
  text-align: center;
  margin-top: 60px;
  margin-bottom: 50px;
}
</style>
