<template>
  <div class="container">
    <span class="btnArea">
      <slot></slot>
      <el-button
        v-for="(item, index) in list"
        :key="index"
        :class="item.title == default_value ? 'isActiveStyle' : ''"
        size="mini"
        plain
        @click="fnChangeBtn1(item.title)"
        >{{ item.title }}</el-button
      >
    </span>
  </div>
</template>

<script>
export default {
  // 从父组件传值的
  props: {
    list:Array,
    default_value: String,
  },
  methods: {
    fnChangeBtn1(val) {
      this.$emit("CCategoryButton_select_val", val);
    },
  },
  components: {},
  computed: {},
  data() {
    return {
    };
  },
};
</script>

<style lang="less" scoped>
.container {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 20px;
  color: var(--stock_area_item_color1) !important;
}

.el-button {
  margin-left: 8px;
  font-size: 14px;
  width: auto;
  height: 30px;
  // line-height: 24px;
//   background-color: var(--Btn-sep_bgc);
//   color: var(--Btn-sep_color);
//   border: 0.76px solid rgba(255, 255, 255, 0.2);

  background-color: var(--Btn-bgc2) !important;
  color: var(--stock_area_item_color1) !important;
  border: 1px solid var(--Btn-borderColor)  !important;


}

.btnArea .el-button:hover {
//   background-color: var(--Btn-sep_bgc) !important;

   background-color: var(--Btn-bgc_hover) !important;
  color: var(--Btn-color2) !important;
  border: 1px solid var(--primary-color) !important;
}

.btnArea .isActiveStyle {
  // background-color: #1573fe;
//   background-color: var(--primary-color) !important;
//   color: var(--Btn-color) !important;
//   border: none;

   background-color: var(--primary-color) !important;
  color: var(--Btn-color) !important;
  border: 1px solid var(--primary-color) !important;
}

.btnArea .isActiveStyle:hover {
   background-color: var(--primary-color) !important;
    color: var(--Btn-color) !important;
  border: 1px solid var(--primary-color) !important;
}
</style>
