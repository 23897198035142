<template>
    <div class="module">
        <div class="card">
            <div class="card_tit2">
                <div v-show="bJYQLCARD">
                    <el-button @click="fnFirstCategory()" class="backBtn_style" size="small">返回上一级</el-button>
                </div>

                <div v-show="bCQLCARD || CQL_more">
                    <el-button @click="fnFirstCategory()" class="backBtn_style" size="small">返回上一级</el-button>
                </div>
            </div>
            <transition name="fade" mode="out-in">
                <div v-show="bMAINCARD" class="main">
                    <div class="main_item">
                        <div class="main_item_top">
                            <div>公募REITs</div>
                            <div>
                                分派率：<span>{{
                                    Number(dValuationSnapshot1_1_YR[dValuationSnapshot1_1_YR.length - 1]).toFixed(
                                        1
                                    ) + '%'
                                }}</span>
                            </div>
                        </div>
                        <div class="canvasArea" ref="chartColumn1" style="width: 100%; height: 230px"></div>
                    </div>
                    <div class="main_item">
                        <div class="main_item_top">
                            <div>产权类REITs</div>
                            <div>
                                分派率：<span class="CQL_color">{{
                                    Number(dValuationSnapshot2_1_YR[dValuationSnapshot2_1_YR.length - 1]).toFixed(
                                        1
                                    ) + '%'
                                }}</span>
                            </div>
                        </div>
                        <div class="canvasArea" ref="chartColumn2_1" style="width: 100%; height: 230px"></div>
                        <div @click="fnSecondCategory('产权类')" class="main_item_bottom">>> 二级类别</div>
                    </div>
                    <div class="main_item">
                        <div class="main_item_top">
                            <div>经营权类REITs</div>
                            <div>
                                分派率：<span class="JYQL_color">{{
                                    Number(dValuationSnapshot3_1_YR[dValuationSnapshot3_1_YR.length - 1]).toFixed(
                                        1
                                    ) + '%'
                                }}</span>
                            </div>
                        </div>
                        <div class="canvasArea" ref="chartColumn3_1" style="width: 100%; height: 230px"></div>
                        <div @click="fnSecondCategory('经营权类')" class="main_item_bottom">
                            >> 二级类别
                        </div>
                    </div>

                    <div class="main_item">
                        <div class="main_item_tit">估值位数</div>
                        <div class="main_item_first">
                            <div class="canvasArea" ref="chartColumn2" style="width: 100%; height: 160px"></div>
                        </div>
                        <div class="main_item_tit">百分位数</div>
                        <div class="main_item_second">
                            <div class="canvasArea" ref="chartColumn3" style="width: 100%; height: 160px"></div>
                        </div>
                    </div>

                    <div class="main_item">
                        <div class="main_item_tit">估值位数</div>
                        <div class="main_item_first">
                            <div class="canvasArea" ref="chartColumn2_2" style="width: 100%; height: 160px"></div>
                        </div>
                        <div class="main_item_tit">百分位数</div>
                        <div class="main_item_second">
                            <div class="canvasArea" ref="chartColumn2_3" style="width: 100%; height: 160px"></div>
                        </div>
                    </div>
                    <div class="main_item">
                        <div class="main_item_tit">估值位数</div>
                        <div class="main_item_first">
                            <div class="canvasArea" ref="chartColumn3_2" style="width: 100%; height: 160px"></div>
                        </div>
                        <div class="main_item_tit">百分位数</div>
                        <div class="main_item_second">
                            <div class="canvasArea" ref="chartColumn3_3" style="width: 100%; height: 160px"></div>
                        </div>
                    </div>
                </div>
            </transition>

            <transition name="fade" mode="out-in">
                <div v-show="bCQLCARD" class="main hasMore">
                    <div class="main_item">
                        <div class="main_item_top">
                            <div>物流仓储</div>
                            <div>
                                分派率：<span class="WLCH_color">{{
                                    Number(
                                        dValuationSnapshot2_1_1_YR[dValuationSnapshot2_1_1_YR.length - 1]
                                    ).toFixed(1) + '%'
                                }}</span>
                            </div>
                        </div>
                        <div class="canvasArea" ref="chartColumn2_1_1" style="width: 100%; height: 230px"></div>
                    </div>
                    <div class="main_item">
                        <div class="main_item_top">
                            <div>产业园</div>
                            <div>
                                分派率：<span class="CYY_color">{{
                                    Number(dValuationSnapshot2_1_YR[dValuationSnapshot2_1_YR.length - 1]).toFixed(
                                        1
                                    ) + '%'
                                }}</span>
                            </div>
                        </div>
                        <div class="canvasArea" ref="chartColumn2_2_1" style="width: 100%; height: 230px"></div>
                    </div>
                    <div class="main_item">
                        <div class="main_item_top">
                            <div>保障性住房</div>
                            <div>
                                分派率：<span class="BZXZF_color">{{
                                    Number(
                                        dValuationSnapshot2_3_1_YR[dValuationSnapshot2_3_1_YR.length - 1]
                                    ).toFixed(1) + '%'
                                }}</span>
                            </div>
                        </div>
                        <div class="canvasArea" ref="chartColumn2_3_1" style="width: 100%; height: 230px"></div>
                    </div>

                    <div class="main_item">
                        <div class="main_item_tit">估值位数</div>
                        <div class="main_item_first">
                            <div class="canvasArea" ref="chartColumn2_1_2" style="width: 100%; height: 160px"></div>
                        </div>
                        <div class="main_item_tit">百分位数</div>
                        <div class="main_item_second">
                            <div class="canvasArea" ref="chartColumn2_1_3" style="width: 100%; height: 160px"></div>
                        </div>
                    </div>
                    <div class="main_item">
                        <div class="main_item_tit">估值位数</div>
                        <div class="main_item_first">
                            <div class="canvasArea" ref="chartColumn2_2_2" style="width: 100%; height: 160px"></div>
                        </div>
                        <div class="main_item_tit">百分位数</div>
                        <div class="main_item_second">
                            <div class="canvasArea" ref="chartColumn2_2_3" style="width: 100%; height: 160px"></div>
                        </div>
                    </div>
                    <div class="main_item">
                        <div class="main_item_tit">估值位数</div>
                        <div class="main_item_first">
                            <div class="canvasArea" ref="chartColumn2_3_2" style="width: 100%; height: 160px"></div>
                        </div>
                        <div class="main_item_tit">百分位数</div>
                        <div class="main_item_second">
                            <div class="canvasArea" ref="chartColumn2_3_3" style="width: 100%; height: 160px"></div>
                        </div>
                    </div>
                    <div class="btnMo">
                        <i class="OVERVIEWICONSTYLE el-icon-arrow-right" @click="fnMore()"></i>
                    </div>
                </div>
            </transition>

            <transition name="slide-fade">
                <div v-show="CQL_more" class="main specialMain hasMore">
                    <div class="main_item">
                        <div class="main_item_top">
                            <div>消费</div>
                            <div>
                                分派率：<span class="XF_color">{{
                                    Number(
                                        dValuationSnapshot2_4_1_YR[dValuationSnapshot2_4_1_YR.length - 1]
                                    ).toFixed(1) + '%'
                                }}</span>
                            </div>
                        </div>
                        <div class="canvasArea" ref="chartColumn2_4_1" style="width: 100%; height: 230px"></div>
                    </div>

                    <div class="main_item h400">
                        <div class="main_item_tit">估值位数</div>
                        <div class="main_item_first">
                            <div class="canvasArea" ref="chartColumn2_4_2" style="width: 100%; height: 160px"></div>
                        </div>
                        <div class="main_item_tit">百分位数</div>
                        <div class="main_item_second">
                            <div class="canvasArea" ref="chartColumn2_4_3" style="width: 100%; height: 160px"></div>
                        </div>
                    </div>

                    <div class="leftBtn">
                        <i class="OVERVIEWICONSTYLE el-icon-arrow-left" @click="fnGoRight()"></i>
                    </div>
                </div>
            </transition>

            <transition name="fade" mode="out-in">
                <div v-show="bJYQLCARD" class="main">
                    <div class="main_item">
                        <div class="main_item_top">
                            <div>市政生态</div>
                            <div>
                                分派率：<span class="SZST_color">{{
                                    Number(
                                        dValuationSnapshot3_1_1_YR[dValuationSnapshot3_1_1_YR.length - 1]
                                    ).toFixed(1) + '%'
                                }}</span>
                            </div>
                        </div>
                        <div class="canvasArea" ref="chartColumn3_1_1" style="width: 100%; height: 230px"></div>
                    </div>
                    <div class="main_item">
                        <div class="main_item_top">
                            <div>能源</div>
                            <div>
                                分派率：<span class="NY_color">{{
                                    Number(
                                        dValuationSnapshot3_2_1_YR[dValuationSnapshot3_2_1_YR.length - 1]
                                    ).toFixed(1) + '%'
                                }}</span>
                            </div>
                        </div>
                        <div class="canvasArea" ref="chartColumn3_2_1" style="width: 100%; height: 230px"></div>
                    </div>
                    <div class="main_item">
                        <div class="main_item_top">
                            <div>收费公路</div>
                            <div>
                                分派率：<span class="SFGL_color">{{
                                    Number(
                                        dValuationSnapshot3_3_1_YR[dValuationSnapshot3_3_1_YR.length - 1]
                                    ).toFixed(1) + '%'
                                }}</span>
                            </div>
                        </div>
                        <div class="canvasArea" ref="chartColumn3_3_1" style="width: 100%; height: 230px"></div>
                    </div>
                    <div class="main_item">
                        <div class="main_item_tit">估值位数</div>
                        <div class="main_item_first">
                            <div class="canvasArea" ref="chartColumn3_1_2" style="width: 100%; height: 160px"></div>
                        </div>
                        <div class="main_item_tit">百分位数</div>
                        <div class="main_item_second">
                            <div class="canvasArea" ref="chartColumn3_1_3" style="width: 100%; height: 160px"></div>
                        </div>
                    </div>
                    <div class="main_item">
                        <div class="main_item_tit">估值位数</div>
                        <div class="main_item_first">
                            <div class="canvasArea" ref="chartColumn3_2_2" style="width: 100%; height: 160px"></div>
                        </div>
                        <div class="main_item_tit">百分位数</div>
                        <div class="main_item_second">
                            <div class="canvasArea" ref="chartColumn3_2_3" style="width: 100%; height: 160px"></div>
                        </div>
                    </div>
                    <div class="main_item">
                        <div class="main_item_tit">估值位数</div>
                        <div class="main_item_first">
                            <div class="canvasArea" ref="chartColumn3_3_2" style="width: 100%; height: 160px"></div>
                        </div>
                        <div class="main_item_tit">百分位数</div>
                        <div class="main_item_second">
                            <div class="canvasArea" ref="chartColumn3_3_3" style="width: 100%; height: 160px"></div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import CSelect from '@/components/Basic/CSelect';
import CCategoryButton from '@/components/Basic/CCategoryButton';
import CCascader from '@/components/Basic/CCascader';
import CTimeButtons from '@/components/Basic/CTimeButtons';
import CotherButtons from '@/components/Basic/CotherButtons';
import CButtonsSeparate from '@/components/Basic/CButtons_separate';
import CButtonsDownload from '@/components/Basic/CButtons_download';
import CDialog from '@/components/Basic/CDialog';

import {
    fnReturnTableStyle,
    fnGetMuchDecimals,
    fnDownloadBgc,
    hexToRgba,
} from '@/utils/util';

export default {
    components: {},
    computed: {},
    created() {
    },
    mounted() {
        this.fnGetWidth();
        // window.addEventListener('resize', this.funResize);
    },
    beforeDestroy() {
        // window.removeEventListener('resize', this.funResize);
        this.chartColumn1 && this.chartColumn1.dispose();
        this.chartColumn2 && this.chartColumn2.dispose();
        this.chartColumn3 && this.chartColumn3.dispose();
        this.chartColumn2_1 && this.chartColumn2_1.dispose();
        this.chartColumn2_2 && this.chartColumn2_2.dispose();
        this.chartColumn2_3 && this.chartColumn2_3.dispose();
        this.chartColumn3_1 && this.chartColumn3_1.dispose();
        this.chartColumn3_2 && this.chartColumn3_2.dispose();
        this.chartColumn3_3 && this.chartColumn3_3.dispose();
        this.chartColumn2_1_1 && this.chartColumn2_1_1.dispose();
        this.chartColumn2_1_2 && this.chartColumn2_1_2.dispose();
        this.chartColumn2_1_3 && this.chartColumn2_1_3.dispose();
        this.chartColumn2_2_3 && this.chartColumn2_2_3.dispose();
        this.chartColumn2_2_2 && this.chartColumn2_2_2.dispose();
        this.chartColumn2_2_1 && this.chartColumn2_2_1.dispose();
        this.chartColumn2_3_1 && this.chartColumn2_3_1.dispose();
        this.chartColumn2_3_2 && this.chartColumn2_3_2.dispose();
        this.chartColumn2_3_3 && this.chartColumn2_3_3.dispose();
        this.chartColumn2_4_1 && this.chartColumn2_4_1.dispose();
        this.chartColumn2_4_2 && this.chartColumn2_4_2.dispose();
        this.chartColumn2_4_3 && this.chartColumn2_4_3.dispose();
        this.chartColumn3_1_3 && this.chartColumn3_1_3.dispose();
        this.chartColumn3_1_2 && this.chartColumn3_1_2.dispose();
        this.chartColumn3_1_1 && this.chartColumn3_1_1.dispose();
        this.chartColumn3_2_1 && this.chartColumn3_2_1.dispose();
        this.chartColumn3_2_2 && this.chartColumn3_2_2.dispose();
        this.chartColumn3_2_3 && this.chartColumn3_2_3.dispose();
        this.chartColumn3_3_3 && this.chartColumn3_3_3.dispose();
        this.chartColumn3_3_2 && this.chartColumn3_3_2.dispose();
        this.chartColumn3_3_1 && this.chartColumn3_3_1.dispose();
    },
    data() {
        return {
            
            sCurrentTheme: this.$store.state.theme == 'dark' ? true : false,

            GMREITs: '',
            CQLREITs: '',
            JYQLREITs: '',
            WLCC: '',
            CYY: '',
            BZXZF: '',
            XF: '',
            SZST: '',
            NY: '',
            SFGL: '',

            bMAINCARD: true,
            bCQLCARD: false,
            bJYQLCARD: false,
            CQL_more: false,

            ROOTFONTFIZE: 0,

            dValuationSnapshot1_1: [],
            dValuationSnapshot1_1_X: [],
            dValuationSnapshot1_1_YR: [],
            dValuationSnapshot1_1_YL: [],

            dValuationSnapshot1_2: [],
            dValuationSnapshot1_3: [],

            dValuationSnapshot2_1: [],
            dValuationSnapshot2_1_X: [],
            dValuationSnapshot2_1_YL: [],
            dValuationSnapshot2_1_YR: [],
            dValuationSnapshot2_2: [],
            dValuationSnapshot2_3: [],

            dValuationSnapshot3_1: [],
            dValuationSnapshot3_1_X: [],
            dValuationSnapshot3_1_YL: [],
            dValuationSnapshot3_1_YR: [],
            dValuationSnapshot3_2: [],
            dValuationSnapshot3_3: [],

            chartColumn1: null,
            chartColumn2: null,
            chartColumn3: null,
            chartColumn2_1: null,
            chartColumn2_2: null,
            chartColumn2_3: null,
            chartColumn3_1: null,
            chartColumn3_2: null,
            chartColumn3_3: null,
            // 产权类子1
            chartColumn2_1_1: null,
            chartColumn2_1_2: null,
            chartColumn2_1_3: null,
            dValuationSnapshot2_1_1: [],
            dValuationSnapshot2_1_1_X: [],
            dValuationSnapshot2_1_1_YL: [],
            dValuationSnapshot2_1_1_YR: [],
            dValuationSnapshot2_1_2: [],
            dValuationSnapshot2_1_3: [],

            // 产权类子2
            chartColumn2_2_1: null,
            chartColumn2_2_2: null,
            chartColumn2_2_3: null,
            dValuationSnapshot2_2_1: [],
            dValuationSnapshot2_2_1_X: [],
            dValuationSnapshot2_2_1_YL: [],
            dValuationSnapshot2_2_1_YR: [],
            dValuationSnapshot2_2_2: [],
            dValuationSnapshot2_2_3: [],

            // 产权类子3
            chartColumn2_3_1: null,
            chartColumn2_3_2: null,
            chartColumn2_3_3: null,
            dValuationSnapshot2_3_1: [],
            dValuationSnapshot2_3_1_X: [],
            dValuationSnapshot2_3_1_YL: [],
            dValuationSnapshot2_3_1_YR: [],
            dValuationSnapshot2_3_2: [],
            dValuationSnapshot2_3_3: [],

            // 产权类子4
            chartColumn2_4_1: null,
            chartColumn2_4_2: null,
            chartColumn2_4_3: null,
            dValuationSnapshot2_4_1: [],
            dValuationSnapshot2_4_1_X: [],
            dValuationSnapshot2_4_1_YL: [],
            dValuationSnapshot2_4_1_YR: [],
            dValuationSnapshot2_4_2: [],
            dValuationSnapshot2_4_3: [],

            // 经营权类子1
            chartColumn3_1_1: null,
            chartColumn3_1_2: null,
            chartColumn3_1_3: null,
            dValuationSnapshot3_1_1: [],
            dValuationSnapshot3_1_1_X: [],
            dValuationSnapshot3_1_1_YL: [],
            dValuationSnapshot3_1_1_YR: [],
            dValuationSnapshot3_1_2: [],
            dValuationSnapshot3_1_3: [],

            // 经营权类子2
            chartColumn3_2_1: null,
            chartColumn3_2_2: null,
            chartColumn3_2_3: null,
            dValuationSnapshot3_2_1: [],
            dValuationSnapshot3_2_1_X: [],
            dValuationSnapshot3_2_1_YL: [],
            dValuationSnapshot3_2_1_YR: [],
            dValuationSnapshot3_2_2: [],
            dValuationSnapshot3_2_3: [],

            // 经营权类子3
            chartColumn3_3_1: null,
            chartColumn3_3_2: null,
            chartColumn3_3_3: null,
            dValuationSnapshot3_3_1: [],
            dValuationSnapshot3_3_1_X: [],
            dValuationSnapshot3_3_1_YL: [],
            dValuationSnapshot3_3_1_YR: [],
            dValuationSnapshot3_3_2: [],
            dValuationSnapshot3_3_3: [],

        };
    },
    methods: {


        async handleClick(e) {
            console.log("summer handleClick", e);

            this.activeName = e.name;
            localStorage.setItem('activeName', this.activeName);
            let activeName = e.name;
            if (activeName == 'first') {
                this.$eventBus.$emit('setBreadcrumbs', '概览');

                await this.fnGetValuationSnapshot();
                await this.fnGetValuationSnapshot2();
                await this.fnGetValuationSnapshot3();

                await this.$nextTick(() => {
                    this.initChart1_2();
                });

                await this.$nextTick(() => {
                    this.initChart1_3();
                });

                await this.$nextTick(() => {
                    this.initChart2_2();
                });
                await this.$nextTick(() => {
                    this.initChart2_3();
                });

                await this.$nextTick(() => {
                    this.initChart3_2();
                });
                await this.$nextTick(() => {
                    this.initChart3_3();
                });

            }
        },
        fnFirstCategory() {
            this.bMAINCARD = true;
            this.bCQLCARD = false;
            this.bJYQLCARD = false;
            this.CQL_more = false;
            this.$nextTick(() => {
                this.initChart1();
                this.initChart1_2();
                this.initChart1_3();
                this.initChart2();
                this.initChart2_2();
                this.initChart2_3();
                this.initChart3();
                this.initChart3_2();
                this.initChart3_3();
            });
        },
        // 公募REITs
        async fnGetValuationSnapshot() {
            let r_type = '公募REITs';

            const { data } = await this.$https.get('/api/valuation_snapshot?r_type=' + r_type);

            if (data.code !== 200) return this.$message.error(data.msg);

            // 第一个图
            this.dValuationSnapshot1_1 = data.data.data1;
            this.dValuationSnapshot1_1_X = [];
            this.dValuationSnapshot1_1_YL = [];
            this.dValuationSnapshot1_1_YR = [];

            for (let index = 0; index < this.dValuationSnapshot1_1.length; index++) {
                this.dValuationSnapshot1_1_X.push(this.dValuationSnapshot1_1[index].tr_date);
                this.dValuationSnapshot1_1_YL.push(this.dValuationSnapshot1_1[index].close);
                this.dValuationSnapshot1_1_YR.push(this.dValuationSnapshot1_1[index].div_yield * 100);
            }

            // 第二个图
            this.dValuationSnapshot1_2 = data.data.data2;
            // 第三个图
            this.dValuationSnapshot1_3 = data.data.data3;

            this.L1MAX =
                Math.max(...Object.values(this.dValuationSnapshot1_2.bar)) > this.L1MAX
                    ? Math.max(...Object.values(this.dValuationSnapshot1_2.bar))
                    : this.L1MAX;

            this.L1MAX_percen =
                Math.max(...Object.values(this.dValuationSnapshot1_3.scatter)) > this.L1MAX_percen
                    ? Math.max(...Object.values(this.dValuationSnapshot1_3.scatter))
                    : this.L1MAX_percen;
            this.$nextTick(() => {
                this.initChart1();
            });
        },

        // 产权类
        async fnGetValuationSnapshot2() {
            let r_type = '产权类';

            const { data } = await this.$https.get('/api/valuation_snapshot?r_type=' + r_type);

            if (data.code !== 200) return this.$message.error(data.msg);

            // 第一个图
            this.dValuationSnapshot2_1 = data.data.data1;
            this.dValuationSnapshot2_1_X = [];
            this.dValuationSnapshot2_1_YL = [];
            this.dValuationSnapshot2_1_YR = [];

            for (let index = 0; index < this.dValuationSnapshot2_1.length; index++) {
                this.dValuationSnapshot2_1_X.push(this.dValuationSnapshot2_1[index].tr_date);
                this.dValuationSnapshot2_1_YL.push(this.dValuationSnapshot2_1[index].close);
                this.dValuationSnapshot2_1_YR.push(this.dValuationSnapshot2_1[index].div_yield * 100);
            }

            // 第二个图
            this.dValuationSnapshot2_2 = data.data.data2;
            // 第三个图
            this.dValuationSnapshot2_3 = data.data.data3;

            this.L1MAX =
                Math.max(...Object.values(this.dValuationSnapshot2_2.bar)) > this.L1MAX
                    ? Math.max(...Object.values(this.dValuationSnapshot2_2.bar))
                    : this.L1MAX;

            this.L1MAX_percen =
                Math.max(...Object.values(this.dValuationSnapshot2_3.scatter)) > this.L1MAX_percen
                    ? Math.max(...Object.values(this.dValuationSnapshot2_3.scatter))
                    : this.L1MAX_percen;
            this.$nextTick(() => {
                this.initChart2();
            });
        },

        // 经营权类
        async fnGetValuationSnapshot3() {
            let r_type = '经营权类';

            const { data } = await this.$https.get('/api/valuation_snapshot?r_type=' + r_type);

            if (data.code !== 200) return this.$message.error(data.msg);

            // 第一个图
            this.dValuationSnapshot3_1 = data.data.data1;
            this.dValuationSnapshot3_1_X = [];
            this.dValuationSnapshot3_1_YL = [];
            this.dValuationSnapshot3_1_YR = [];

            for (let index = 0; index < this.dValuationSnapshot3_1.length; index++) {
                this.dValuationSnapshot3_1_X.push(this.dValuationSnapshot3_1[index].tr_date);
                this.dValuationSnapshot3_1_YL.push(this.dValuationSnapshot3_1[index].close);
                this.dValuationSnapshot3_1_YR.push(this.dValuationSnapshot3_1[index].div_yield * 100);
            }

            // 第二个图
            this.dValuationSnapshot3_2 = data.data.data2;
            // 第三个图
            this.dValuationSnapshot3_3 = data.data.data3;

            this.L1MAX =
                Math.max(...Object.values(this.dValuationSnapshot3_2.bar)) > this.L1MAX
                    ? Math.max(...Object.values(this.dValuationSnapshot3_2.bar))
                    : this.L1MAX;

            this.L1MAX_percen =
                Math.max(...Object.values(this.dValuationSnapshot3_3.scatter)) > this.L1MAX_percen
                    ? Math.max(...Object.values(this.dValuationSnapshot3_3.scatter))
                    : this.L1MAX_percen;
            this.$nextTick(() => {
                this.initChart3();
            });
        },
        initChart1() {
            this.chartColumn1 = this.$echarts.init(this.$refs.chartColumn1);

            let dValuationSnapshot1_1_X = this.dValuationSnapshot1_1_X;
            let dValuationSnapshot1_1_YL = this.dValuationSnapshot1_1_YL;
            let dValuationSnapshot1_1_YR = this.dValuationSnapshot1_1_YR;

            let OPTIONDATA =
                this.$store.state.theme == 'dark'
                    ? this.$store.state.OPTIONDATA
                    : this.$store.state.OPTIONDATA_light;

            let theme = this.$store.state.theme;

            let color = this.$store.state.colors.find((node) => node.name == '公募REITs').value;
            this.GMREITs = color;
            let option = {
                grid: {
                    height: '75%',
                    top: 50,
                    bottom: 0,
                    right: 50,
                    left: 30,
                    containLabel: true,
                },
                tooltip: {
                    trigger: 'axis',
                    triggerOn: 'mousemove|click',
                    formatter: function (args) {
                        let str = '';
                        str += `${args[0].axisValueLabel}`;
                        str += '<br>';
                        args.forEach((node, idx) => {
                            let val = Number(node.value).toFixed(2);
                            if (idx == 0) {
                                str += `${node.marker}${node.seriesName}：${val}`;
                            } else {
                                str += `${node.marker}${node.seriesName}：${val}%`;
                            }
                            str += '<br>';

                            return str;
                        });
                        return str;
                    },
                },

                legend: [
                    {
                        show: true,
                        itemWidth: 30,
                        itemHeight: 3,
                        data: [
                            {
                                name: '指数走势（左轴）',
                                color: 'rgba(86, 84, 202, 1)',
                            },
                        ],
                        textStyle: {
                            color: OPTIONDATA.legend.textStyle.color,
                            fontSize: 14,
                        },
                        left: 30,
                        y: 8,
                    },

                    {
                        show: true,
                        itemWidth: 30,
                        itemHeight: 10,
                        data: [
                            {
                                name: '分派率（右轴）',
                                icon: 'roundRect',
                                color: 'rgba(56, 56, 118, 1)',
                            },
                        ],
                        textStyle: {
                            color: OPTIONDATA.legend.textStyle.color,
                            fontSize: 14,
                        },
                        left: 200,
                        y: 8,
                    },
                ],

                xAxis: {
                    type: 'category',
                    // boundaryGap: true,
                    boundaryGap: true,
                    splitNumber: 4,
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        margin: 20,
                        fontSize: 14,
                        show: true,
                        showMaxLabel: true,
                        color: OPTIONDATA.xAxis.axisLabel.color,
                    },

                    data: dValuationSnapshot1_1_X,
                    // data: ['1','2','3','4'],
                },
                yAxis: [
                    {
                        // type: 'value',
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        position: 'left',
                        axisLabel: {
                            fontSize: 14,
                            show: true,
                            color: OPTIONDATA.yAxis.axisLabel.color,
                            formatter: function (args) {
                                if (String(args).length == 3) {
                                    return args + '0';
                                } else {
                                    return args;
                                }
                            },
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                    {
                        // type: 'value',
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        position: 'right',
                        axisLabel: {
                            fontSize: 14,
                            show: true,
                            color: OPTIONDATA.yAxis.axisLabel.color,
                            formatter: function (args) {
                                if (String(args).length == 2) {
                                    return args + '.0' + '%';
                                }
                                if (String(args).length == 1 && args !== 0) {
                                    return args + '.00' + '%';
                                } else {
                                    return 0;
                                }
                            },
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                ],
                series: [
                    {
                        // data: [29000, 33000, 34000, 34100],
                        name: '指数走势（左轴）',
                        data: dValuationSnapshot1_1_YL,
                        yAxisIndex: 0,
                        type: 'line',

                        itemStyle: {
                            // color: "rgba(97, 1, 234, 1)",
                            // color: "#5ae8ed",
                            color: color,
                        },
                        symbol: 'none',

                        // itemStyle : { normal: {label : {show: true}}}
                    },
                    {
                        name: '分派率（右轴）',
                        data: dValuationSnapshot1_1_YR,
                        yAxisIndex: 1,
                        type: 'line',
                        areaStyle: {
                            // color: "rgba(97, 1, 234, 0.3)",
                            color:
                                // theme == "dark"
                                //   ? "rgba(90, 232, 237, 0.3)"
                                //   : "rgba(90, 232, 237, 0.8)",
                                theme == 'dark' ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
                        },
                        itemStyle: {
                            // color: "rgba(97, 1, 234, 0.3)",
                            color: theme == 'dark' ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
                        },
                        symbol: 'none',
                    },
                ],
            };

            this.chartColumn1.setOption(option);
            this.chartColumn1.resize();
            // this.funResize()
        },

        initChart1_2() {
            this.chartColumn2 = this.$echarts.init(this.$refs.chartColumn2);

            let dValuationSnapshot1_2 = this.dValuationSnapshot1_2;
            let X = Object.keys(dValuationSnapshot1_2.bar);
            X = X.map((node) => {
                return node.slice(0, -4);
            });
            let Y = Object.values(dValuationSnapshot1_2.bar);

            let colors = [
                {
                    name: 'PNAV',
                    value: 'rgba(99, 230, 226, 1)',
                },
                {
                    name: 'PFFO',
                    value: 'rgba(86, 84, 202, 1)',
                },
                {
                    name: 'PCFO',
                    value: 'rgba(89, 143, 200, 1)',
                },
                {
                    name: 'EV/EBITDA',
                    value: 'rgba(97, 172, 228, 1)',
                },
            ];

            let OPTIONDATA =
                this.$store.state.theme == 'dark'
                    ? this.$store.state.OPTIONDATA
                    : this.$store.state.OPTIONDATA_light;

            let L1MAX = this.L1MAX;

            let option = {
                grid: {
                    top: 30,
                    bottom: 10,
                    left: 0,
                    right: 0,
                    containLabel: true,
                },

                xAxis: {
                    type: 'category',
                    data: X,

                    axisLine: {
                        show: true,
                        onZero: false, //轴线是否在0刻度轴上
                    },

                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        interval: 0,
                        fontSize: 14,
                        // rotate: 5,
                        show: true,
                        showMaxLabel: true,
                        margin: 10,
                        color: OPTIONDATA.xAxis.axisLabel.color,
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                        //x轴刻度相关设置
                        alignWithLabel: true,
                    },
                    max: L1MAX,
                    // interval:20,
                    axisLabel: {
                        fontSize: 12,
                        show: false,
                        color: OPTIONDATA.yAxis.axisLabel.color,
                        formatter: function (args) {
                            return args + 'qq';
                        },
                    },
                    // data: [0, 1, 2, 3, 4],
                    splitLine: {
                        show: false,
                        lineStyle: {
                            type: 'dashed',
                            color: OPTIONDATA.splitLine.lineStyle.color,
                        },
                    },
                },
                series: [
                    {
                        type: 'bar',
                        data: Y,
                        itemStyle: {
                            // color:'#5f7fe5'
                            color: function (params) {
                                let name = params.name;

                                let obj2 = {};
                                obj2 = colors.find((item) => {
                                    return item.name == name;
                                });
                                return obj2.value;
                            },
                        },
                        barWidth: '16px',
                        label: {
                            show: true,
                            position: 'top',
                            textStyle: {
                                color: OPTIONDATA.title.textStyle.color,
                            },
                            formatter: function (val) {
                                val = Number(val.value).toFixed(1);
                                val = val + 'x';
                                return val;
                            },
                        },
                    },
                ],
            };

            this.chartColumn2.setOption(option);
            this.chartColumn2.resize();
            // this.funResize()
        },

        initChart1_3() {
            this.chartColumn3 = this.$echarts.init(this.$refs.chartColumn3);

            let dValuationSnapshot1_3 = this.dValuationSnapshot1_3;
            let X = Object.keys(dValuationSnapshot1_3.scatter);
            let Y = Object.values(dValuationSnapshot1_3.scatter);
            X = X.map((node) => {
                return node.slice(0, -4);
            });

            let colors = [
                {
                    name: 'PNAV',
                    value: 'rgba(99, 230, 226, 1)',
                },
                {
                    name: 'PFFO',
                    value: 'rgba(86, 84, 202, 1)',
                },
                {
                    name: 'PCFO',
                    value: 'rgba(89, 143, 200, 1)',
                },
                {
                    name: 'EV/EBITDA',
                    value: 'rgba(97, 172, 228, 1)',
                },
            ];

            let OPTIONDATA =
                this.$store.state.theme == 'dark'
                    ? this.$store.state.OPTIONDATA
                    : this.$store.state.OPTIONDATA_light;

            let L1MAX_percen = this.L1MAX_percen;

            let option = {
                grid: {
                    top: 30,
                    left: 0,
                    bottom: 10,
                    right: 0,
                    containLabel: true,
                },

                xAxis: {
                    type: 'category',
                    boundaryGap: true,
                    axisLine: {
                        show: true,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        interval: 0,
                        fontSize: 14,
                        show: true,
                        showMaxLabel: true,
                        margin: 10,
                        color: OPTIONDATA.xAxis.axisLabel.color,
                    },

                    data: X,
                    // data: ['1','2','3','4'],
                },
                yAxis: {
                    max: L1MAX_percen,
                    type: 'value',
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        fontSize: 14,
                        show: false,
                        color: OPTIONDATA.yAxis.axisLabel.color,
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            type: 'dashed',
                            color: OPTIONDATA.splitLine.lineStyle.color,
                        },
                    },
                },
                series: [
                    {
                        // data: [29000, 33000, 34000, 34100],
                        name: '公募REITs',
                        data: Y,
                        type: 'line',
                        smooth: false,
                        lineStyle: {
                            color: 'rgba(118, 118, 118, 1)',
                        },
                        itemStyle: {
                            color: function (params) {
                                let name = params.name;

                                let obj2 = {};
                                obj2 = colors.find((item) => {
                                    return item.name == name;
                                });
                                return obj2.value;
                            },
                        },
                        label: {
                            show: true,
                            position: 'top',
                            textStyle: {
                                color: OPTIONDATA.title.textStyle.color,
                            },
                            formatter: function (val) {
                                val = Number(val.value).toFixed(1);
                                return val;
                            },
                        },

                        symbol: 'circle',
                        symbolSize: 18,
                    },
                ],
            };

            this.chartColumn3.setOption(option);
            this.chartColumn3.resize();
            // this.funResize()
        },

        initChart2() {
            this.chartColumn2_1 = this.$echarts.init(this.$refs.chartColumn2_1);

            let dValuationSnapshot2_1_X = this.dValuationSnapshot2_1_X;
            let dValuationSnapshot2_1_YL = this.dValuationSnapshot2_1_YL;
            let dValuationSnapshot2_1_YR = this.dValuationSnapshot2_1_YR;

            let OPTIONDATA =
                this.$store.state.theme == 'dark'
                    ? this.$store.state.OPTIONDATA
                    : this.$store.state.OPTIONDATA_light;

            let theme = this.$store.state.theme;

            let color = this.$store.state.colors.find((node) => node.name == '产权类').value;
            this.CQLREITs = color;

            let option = {
                grid: {
                    height: '75%',
                    top: 50,
                    bottom: 0,
                    right: 50,
                    left: 30,
                    containLabel: true,
                },
                tooltip: {
                    trigger: 'axis',
                    triggerOn: 'mousemove|click',
                    formatter: function (args) {
                        let str = '';
                        str += `${args[0].axisValueLabel}`;
                        str += '<br>';
                        args.forEach((node, idx) => {
                            let val = Number(node.value).toFixed(2);
                            if (idx == 0) {
                                str += `${node.marker}${node.seriesName}：${val}`;
                            } else {
                                str += `${node.marker}${node.seriesName}：${val}%`;
                            }
                            str += '<br>';

                            return str;
                        });
                        return str;
                    },
                },

                legend: [
                    {
                        show: true,
                        itemWidth: 30,
                        itemHeight: 3,
                        data: [
                            {
                                name: '指数走势（左轴）',
                                color: '#21266b',
                            },
                        ],
                        textStyle: {
                            color: OPTIONDATA.legend.textStyle.color,
                            fontSize: 14,
                        },
                        left: 30,
                        y: 8,
                    },

                    {
                        show: true,
                        itemWidth: 30,
                        itemHeight: 10,
                        data: [
                            {
                                name: '分派率（右轴）',
                                icon: 'roundRect',
                                color: '#252b90',
                            },
                        ],
                        textStyle: {
                            color: OPTIONDATA.legend.textStyle.color,

                            fontSize: 14,
                        },
                        left: 200,
                        y: 8,
                    },
                ],

                xAxis: {
                    type: 'category',
                    // boundaryGap: true,
                    boundaryGap: true,
                    splitNumber: 4,
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        margin: 20,
                        fontSize: 14,
                        show: true,
                        showMaxLabel: true,
                        color: OPTIONDATA.xAxis.axisLabel.color,
                    },
                    data: dValuationSnapshot2_1_X,
                },
                yAxis: [
                    {
                        // type: 'value',
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        position: 'left',
                        axisLabel: {
                            fontSize: 14,
                            show: true,
                            color: OPTIONDATA.yAxis.axisLabel.color,
                            formatter: function (args) {
                                if (String(args).length == 3) {
                                    return args + '0';
                                } else {
                                    return args;
                                }
                            },
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                    {
                        // type: 'value',
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        position: 'right',
                        axisLabel: {
                            fontSize: 14,
                            show: true,
                            color: OPTIONDATA.yAxis.axisLabel.color,
                            formatter: function (args) {
                                if (String(args).length == 2) {
                                    return args + '.0' + '%';
                                }
                                if (String(args).length == 1 && args !== 0) {
                                    return args + '.00' + '%';
                                } else {
                                    return 0;
                                }
                            },
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                ],
                series: [
                    {
                        name: '指数走势（左轴）',
                        data: dValuationSnapshot2_1_YL,
                        yAxisIndex: 0,
                        type: 'line',

                        itemStyle: {
                            color: color,
                        },
                        symbol: 'none',

                        // itemStyle : { normal: {label : {show: true}}}
                    },
                    {
                        name: '分派率（右轴）',
                        data: dValuationSnapshot2_1_YR,
                        yAxisIndex: 1,
                        type: 'line',
                        areaStyle: {
                            color: theme == 'dark' ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
                        },
                        itemStyle: {
                            color: theme == 'dark' ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
                        },
                        symbol: 'none',
                    },
                ],
            };

            this.chartColumn2_1.setOption(option);
            this.chartColumn2_1.resize();
            // this.funResize()
        },

        initChart2_2() {
            this.chartColumn2_2 = this.$echarts.init(this.$refs.chartColumn2_2);

            let dValuationSnapshot2_2 = this.dValuationSnapshot2_2;
            let X = Object.keys(dValuationSnapshot2_2.bar);
            X = X.map((node) => {
                return node.slice(0, -4);
            });
            let Y = Object.values(dValuationSnapshot2_2.bar);

            let colors = [
                {
                    name: 'PNAV',
                    value: 'rgba(99, 230, 226, 1)',
                },
                {
                    name: 'PFFO',
                    value: 'rgba(86, 84, 202, 1)',
                },
                {
                    name: 'PCFO',
                    value: 'rgba(89, 143, 200, 1)',
                },
                {
                    name: 'EV/EBITDA',
                    value: 'rgba(97, 172, 228, 1)',
                },
            ];

            let OPTIONDATA =
                this.$store.state.theme == 'dark'
                    ? this.$store.state.OPTIONDATA
                    : this.$store.state.OPTIONDATA_light;

            let L1MAX = this.L1MAX;

            let option = {
                grid: {
                    top: 30,
                    bottom: 10,
                    left: 0,
                    right: 0,
                    containLabel: true,
                },

                xAxis: {
                    type: 'category',
                    // data: ['S＆P500', '10Yr国债', '沪深300', '公用指数', 'CREITs','','','',''],
                    data: X,

                    axisLine: {
                        show: true,
                        onZero: false, //轴线是否在0刻度轴上
                    },

                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        interval: 0,
                        fontSize: 14,
                        // rotate: 5,
                        show: true,
                        showMaxLabel: true,
                        margin: 10,
                        color: OPTIONDATA.xAxis.axisLabel.color,
                    },
                },
                yAxis: {
                    type: 'value',
                    max: L1MAX,
                    // interval:20,
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                        //x轴刻度相关设置
                        alignWithLabel: true,
                    },
                    axisLabel: {
                        fontSize: 12,
                        show: false,
                        color: OPTIONDATA.yAxis.axisLabel.color,
                    },
                    // data: [0, 1, 2, 3, 4],
                    splitLine: {
                        show: false,
                        lineStyle: {
                            type: 'dashed',
                            color: OPTIONDATA.splitLine.lineStyle.color,
                        },
                    },
                },
                series: [
                    {
                        type: 'bar',
                        data: Y,
                        itemStyle: {
                            // color:'#5f7fe5'
                            color: function (params) {
                                let name = params.name;

                                let obj2 = {};
                                obj2 = colors.find((item) => {
                                    return item.name == name;
                                });
                                return obj2.value;
                            },
                        },
                        barWidth: '16px',
                        label: {
                            show: true,
                            position: 'top',
                            textStyle: {
                                color: OPTIONDATA.title.textStyle.color,
                            },
                            formatter: function (val) {
                                val = Number(val.value).toFixed(1);
                                val = val + 'x';
                                return val;
                            },
                        },
                    },
                ],
            };

            this.chartColumn2_2.setOption(option);
            this.chartColumn2_2.resize();
            // this.funResize()
        },

        initChart2_3() {
            this.chartColumn2_3 = this.$echarts.init(this.$refs.chartColumn2_3);

            let dValuationSnapshot2_3 = this.dValuationSnapshot2_3;
            let X = Object.keys(dValuationSnapshot2_3.scatter);
            let Y = Object.values(dValuationSnapshot2_3.scatter);
            X = X.map((node) => {
                return node.slice(0, -4);
            });

            let colors = [
                {
                    name: 'PNAV',
                    value: 'rgba(99, 230, 226, 1)',
                },
                {
                    name: 'PFFO',
                    value: 'rgba(86, 84, 202, 1)',
                },
                {
                    name: 'PCFO',
                    value: 'rgba(89, 143, 200, 1)',
                },
                {
                    name: 'EV/EBITDA',
                    value: 'rgba(97, 172, 228, 1)',
                },
            ];

            let OPTIONDATA =
                this.$store.state.theme == 'dark'
                    ? this.$store.state.OPTIONDATA
                    : this.$store.state.OPTIONDATA_light;

            let L1MAX_percen = this.L1MAX_percen;

            let option = {
                grid: {
                    top: 30,
                    left: 0,
                    bottom: 10,
                    right: 0,
                    containLabel: true,
                },

                xAxis: {
                    type: 'category',
                    boundaryGap: true,
                    axisLine: {
                        show: true,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        interval: 0,
                        fontSize: 14,
                        show: true,
                        showMaxLabel: true,
                        margin: 10,
                        color: OPTIONDATA.xAxis.axisLabel.color,
                    },

                    data: X,
                    // data: ['1','2','3','4'],
                },
                yAxis: {
                    max: L1MAX_percen,
                    type: 'value',
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        fontSize: 14,
                        show: false,
                        color: OPTIONDATA.yAxis.axisLabel.color,
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            type: 'dashed',
                            color: OPTIONDATA.splitLine.lineStyle.color,
                        },
                    },
                },
                series: [
                    {
                        // data: [29000, 33000, 34000, 34100],
                        name: '公募REITs',
                        data: Y,
                        type: 'line',
                        smooth: false,
                        lineStyle: {
                            color: 'rgba(118, 118, 118, 1)',
                        },
                        itemStyle: {
                            color: function (params) {
                                let name = params.name;

                                let obj2 = {};
                                obj2 = colors.find((item) => {
                                    return item.name == name;
                                });
                                return obj2.value;
                            },
                        },
                        label: {
                            show: true,
                            position: 'top',
                            textStyle: {
                                color: OPTIONDATA.title.textStyle.color,
                            },
                            formatter: function (val) {
                                val = Number(val.value).toFixed(1);
                                return val;
                            },
                        },

                        symbol: 'circle',
                        symbolSize: 18,
                    },
                ],
            };

            this.chartColumn2_3.setOption(option);
            this.chartColumn2_3.resize();
            // this.funResize()
        },


        initChart3() {
            this.chartColumn3_1 = this.$echarts.init(this.$refs.chartColumn3_1);

            let dValuationSnapshot3_1_X = this.dValuationSnapshot3_1_X;
            let dValuationSnapshot3_1_YL = this.dValuationSnapshot3_1_YL;
            let dValuationSnapshot3_1_YR = this.dValuationSnapshot3_1_YR;

            let OPTIONDATA =
                this.$store.state.theme == 'dark'
                    ? this.$store.state.OPTIONDATA
                    : this.$store.state.OPTIONDATA_light;

            let theme = this.$store.state.theme;

            let color = this.$store.state.colors.find((node) => node.name == '经营权类').value;
            this.JYQLREITs = color;

            let option = {
                grid: {
                    height: '75%',
                    top: 50,
                    bottom: 0,
                    right: 50,
                    left: 30,
                    containLabel: true,
                },
                tooltip: {
                    trigger: 'axis',
                    triggerOn: 'mousemove|click',
                    formatter: function (args) {
                        let str = '';
                        str += `${args[0].axisValueLabel}`;
                        str += '<br>';
                        args.forEach((node, idx) => {
                            let val = Number(node.value).toFixed(2);
                            if (idx == 0) {
                                str += `${node.marker}${node.seriesName}：${val}`;
                            } else {
                                str += `${node.marker}${node.seriesName}：${val}%`;
                            }
                            str += '<br>';

                            return str;
                        });
                        return str;
                    },
                },

                legend: [
                    {
                        show: true,
                        itemWidth: 30,
                        itemHeight: 3,
                        data: [
                            {
                                name: '指数走势（左轴）',
                                color: '#177475',
                            },
                        ],
                        textStyle: {
                            color: OPTIONDATA.legend.textStyle.color,
                            fontSize: 14,
                        },
                        left: 30,
                        y: 8,
                    },

                    {
                        show: true,
                        itemWidth: 30,
                        itemHeight: 10,
                        data: [
                            {
                                name: '分派率（右轴）',
                                icon: 'roundRect',
                                color: '#177576',
                            },
                        ],
                        textStyle: {
                            color: OPTIONDATA.legend.textStyle.color,
                            fontSize: 14,
                        },
                        left: 200,
                        y: 8,
                    },
                ],

                xAxis: {
                    type: 'category',
                    // boundaryGap: true,
                    boundaryGap: true,
                    splitNumber: 4,
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        margin: 20,
                        fontSize: 14,
                        show: true,
                        showMaxLabel: true,
                        color: OPTIONDATA.xAxis.axisLabel.color,
                    },
                    data: dValuationSnapshot3_1_X,
                },
                yAxis: [
                    {
                        // type: 'value',
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        position: 'left',
                        axisLabel: {
                            fontSize: 14,
                            show: true,

                            color: OPTIONDATA.yAxis.axisLabel.color,
                            formatter: function (args) {
                                if (String(args).length == 3) {
                                    return args + '0';
                                } else {
                                    return args;
                                }
                            },
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                    {
                        // type: 'value',
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        position: 'right',
                        axisLabel: {
                            fontSize: 14,
                            show: true,
                            color: OPTIONDATA.yAxis.axisLabel.color,
                            formatter: function (args) {
                                if (String(args).length == 2) {
                                    return args + '.0' + '%';
                                }
                                if (String(args).length == 1 && args !== 0) {
                                    return args + '.00' + '%';
                                } else {
                                    return 0;
                                }
                            },
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                ],
                series: [
                    {
                        name: '指数走势（左轴）',
                        data: dValuationSnapshot3_1_YL,
                        yAxisIndex: 0,
                        type: 'line',

                        itemStyle: {
                            color: color,
                        },
                        symbol: 'none',

                        // itemStyle : { normal: {label : {show: true}}}
                    },
                    {
                        name: '分派率（右轴）',
                        data: dValuationSnapshot3_1_YR,
                        yAxisIndex: 1,
                        type: 'line',
                        areaStyle: {
                            color: theme == 'dark' ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
                        },
                        itemStyle: {
                            color: theme == 'dark' ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
                        },
                        symbol: 'none',
                    },
                ],
            };

            this.chartColumn3_1.setOption(option);
            this.chartColumn3_1.resize();
            // this.funResize()
        },

        initChart3_2() {
            this.chartColumn3_2 = this.$echarts.init(this.$refs.chartColumn3_2);

            let dValuationSnapshot3_2 = this.dValuationSnapshot3_2;
            let X = Object.keys(dValuationSnapshot3_2.bar);
            X = X.map((node) => {
                return node.slice(0, -4);
            });
            let Y = Object.values(dValuationSnapshot3_2.bar);

            let colors = [
                {
                    name: 'PNAV',
                    value: 'rgba(99, 230, 226, 1)',
                },
                {
                    name: 'PFFO',
                    value: 'rgba(86, 84, 202, 1)',
                },
                {
                    name: 'PCFO',
                    value: 'rgba(89, 143, 200, 1)',
                },
                {
                    name: 'EV/EBITDA',
                    value: 'rgba(97, 172, 228, 1)',
                },
            ];

            let OPTIONDATA =
                this.$store.state.theme == 'dark'
                    ? this.$store.state.OPTIONDATA
                    : this.$store.state.OPTIONDATA_light;
            let L1MAX = this.L1MAX;

            let option = {
                grid: {
                    top: 30,
                    bottom: 10,
                    left: 0,
                    right: 0,
                    containLabel: true,
                },
                // tooltip: {
                //   // 工具提示
                //   // trigger:'item',
                //   trigger: "axis", // 工具提示的类型 item代表的是每个柱本身, axis代表的是坐标轴
                //   triggerOn: "click", // 触发时机, click代表点击, mouseOver代表鼠标移过
                //   formatter: function (args) {
                //     // 文字格式化
                //     return args[0].name + "的YTD回报率：" + args[0].data + "%";
                //   },
                // },
                xAxis: {
                    type: 'category',
                    // data: ['S＆P500', '10Yr国债', '沪深300', '公用指数', 'CREITs','','','',''],
                    data: X,

                    axisLine: {
                        show: true,
                        onZero: false, //轴线是否在0刻度轴上
                    },

                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        interval: 0,
                        fontSize: 14,
                        // rotate: 5,
                        show: true,
                        showMaxLabel: true,
                        margin: 10,
                        color: OPTIONDATA.xAxis.axisLabel.color,
                    },
                },
                yAxis: {
                    // type: 'value',
                    max: L1MAX,
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                        //x轴刻度相关设置
                        alignWithLabel: true,
                    },
                    axisLabel: {
                        fontSize: 12,
                        show: false,
                        color: OPTIONDATA.yAxis.axisLabel.color,
                    },
                    // data: [0, 1, 2, 3, 4],
                    splitLine: {
                        show: false,
                        lineStyle: {
                            type: 'dashed',
                            color: OPTIONDATA.splitLine.lineStyle.color,
                        },
                    },
                },
                series: [
                    {
                        type: 'bar',
                        data: Y,
                        itemStyle: {
                            // color:'#5f7fe5'
                            color: function (params) {
                                let name = params.name;

                                let obj2 = {};
                                obj2 = colors.find((item) => {
                                    return item.name == name;
                                });
                                return obj2.value;
                            },
                        },
                        barWidth: '16px',
                        label: {
                            show: true,
                            position: 'top',
                            textStyle: {
                                color: OPTIONDATA.title.textStyle.color,
                            },
                            formatter: function (val) {
                                val = Number(val.value).toFixed(1);
                                val = val + 'x';
                                return val;
                            },
                        },
                    },
                ],
            };

            this.chartColumn3_2.setOption(option);
            this.chartColumn3_2.resize();
            // this.funResize()
        },

        initChart3_3() {
            this.chartColumn3_3 = this.$echarts.init(this.$refs.chartColumn3_3);

            let dValuationSnapshot3_3 = this.dValuationSnapshot3_3;
            let X = Object.keys(dValuationSnapshot3_3.scatter);
            let Y = Object.values(dValuationSnapshot3_3.scatter);
            X = X.map((node) => {
                return node.slice(0, -4);
            });

            let colors = [
                {
                    name: 'PNAV',
                    value: 'rgba(99, 230, 226, 1)',
                },
                {
                    name: 'PFFO',
                    value: 'rgba(86, 84, 202, 1)',
                },
                {
                    name: 'PCFO',
                    value: 'rgba(89, 143, 200, 1)',
                },
                {
                    name: 'EV/EBITDA',
                    value: 'rgba(97, 172, 228, 1)',
                },
            ];

            let OPTIONDATA =
                this.$store.state.theme == 'dark'
                    ? this.$store.state.OPTIONDATA
                    : this.$store.state.OPTIONDATA_light;

            let L1MAX_percen = this.L1MAX_percen;

            let option = {
                grid: {
                    top: 30,
                    left: 0,
                    bottom: 10,
                    right: 0,
                    containLabel: true,
                },

                xAxis: {
                    type: 'category',
                    boundaryGap: true,
                    axisLine: {
                        show: true,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        interval: 0,
                        fontSize: 14,
                        show: true,
                        showMaxLabel: true,
                        margin: 10,
                        color: OPTIONDATA.xAxis.axisLabel.color,
                    },

                    data: X,
                    // data: ['1','2','3','4'],
                },
                yAxis: {
                    max: L1MAX_percen,
                    type: 'value',
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        fontSize: 14,
                        show: false,
                        color: OPTIONDATA.yAxis.axisLabel.color,
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            type: 'dashed',
                            color: OPTIONDATA.splitLine.lineStyle.color,
                        },
                    },
                },
                series: [
                    {
                        // data: [29000, 33000, 34000, 34100],
                        name: '公募REITs',
                        data: Y,
                        type: 'line',
                        smooth: false,
                        lineStyle: {
                            color: 'rgba(118, 118, 118, 1)',
                        },
                        itemStyle: {
                            color: function (params) {
                                let name = params.name;

                                let obj2 = {};
                                obj2 = colors.find((item) => {
                                    return item.name == name;
                                });
                                return obj2.value;
                            },
                        },
                        label: {
                            show: true,
                            position: 'top',
                            textStyle: {
                                color: OPTIONDATA.title.textStyle.color,
                            },
                            formatter: function (val) {
                                val = Number(val.value).toFixed(1);
                                return val;
                            },
                        },

                        symbol: 'circle',
                        symbolSize: 18,
                    },
                ],
            };

            this.chartColumn3_3.setOption(option);
            this.chartColumn3_3.resize();
            // this.funResize()
        },


        // 物流仓储
        async fnGetValuationSnapshot4() {
            let r_type = '物流仓储';

            const { data } = await this.$https.get('/api/valuation_snapshot?r_type=' + r_type);

            if (data.code !== 200) return this.$message.error(data.msg);

            // 第一个图
            this.dValuationSnapshot2_1_1 = data.data.data1;
            this.dValuationSnapshot2_1_1_X = [];
            this.dValuationSnapshot2_1_1_YL = [];
            this.dValuationSnapshot2_1_1_YR = [];

            for (let index = 0; index < this.dValuationSnapshot2_1_1.length; index++) {
                this.dValuationSnapshot2_1_1_X.push(this.dValuationSnapshot2_1_1[index].tr_date);
                this.dValuationSnapshot2_1_1_YL.push(this.dValuationSnapshot2_1_1[index].close);
                this.dValuationSnapshot2_1_1_YR.push(this.dValuationSnapshot2_1_1[index].div_yield * 100);
            }

            // 第二个图
            this.dValuationSnapshot2_1_2 = data.data.data2;
            this.CQMAX =
                Math.max(...Object.values(this.dValuationSnapshot2_1_2.bar)) > this.CQMAX
                    ? Math.max(...Object.values(this.dValuationSnapshot2_1_2.bar))
                    : this.CQMAX;

            // 第三个图
            this.dValuationSnapshot2_1_3 = data.data.data3;
            this.CQMAX_percen =
                Math.max(...Object.values(this.dValuationSnapshot2_1_3.scatter)) > this.CQMAX_percen
                    ? Math.max(...Object.values(this.dValuationSnapshot2_1_3.scatter))
                    : this.CQMAX_percen;
            this.$nextTick(() => {
                this.initChart2_1_1();
            });
            // this.initChart2_1_2();
            // this.initChart2_1_3();
        },
        initChart2_1_1() {
            this.chartColumn2_1_1 = this.$echarts.init(this.$refs.chartColumn2_1_1);

            let dValuationSnapshot2_1_1_X = this.dValuationSnapshot2_1_1_X;
            let dValuationSnapshot2_1_1_YL = this.dValuationSnapshot2_1_1_YL;
            let dValuationSnapshot2_1_1_YR = this.dValuationSnapshot2_1_1_YR;

            let OPTIONDATA =
                this.$store.state.theme == 'dark'
                    ? this.$store.state.OPTIONDATA
                    : this.$store.state.OPTIONDATA_light;

            let theme = this.$store.state.theme;

            let color = this.$store.state.colors.find((node) => node.name == '物流仓储').value;
            this.WLCC = color;

            let option = {
                grid: {
                    height: '75%',
                    top: 50,
                    bottom: 0,
                    right: 50,
                    left: 30,
                    containLabel: true,
                },
                tooltip: {
                    trigger: 'axis',
                    triggerOn: 'mousemove|click',
                    formatter: function (args) {
                        let str = '';
                        str += `${args[0].axisValueLabel}`;
                        str += '<br>';
                        args.forEach((node, idx) => {
                            let val = Number(node.value).toFixed(2);
                            if (idx == 0) {
                                str += `${node.marker}${node.seriesName}：${val}`;
                            } else {
                                str += `${node.marker}${node.seriesName}：${val}%`;
                            }
                            str += '<br>';

                            return str;
                        });
                        return str;
                    },
                },

                legend: [
                    {
                        show: true,
                        itemWidth: 30,
                        itemHeight: 3,
                        data: [
                            {
                                name: '指数走势（左轴）',
                                color: 'rgba(86, 84, 202, 1)',
                            },
                        ],
                        textStyle: {
                            color: OPTIONDATA.legend.textStyle.color,
                            fontSize: 14,
                        },
                        left: 30,
                        y: 8,
                    },

                    {
                        show: true,
                        itemWidth: 30,
                        itemHeight: 10,
                        data: [
                            {
                                name: '分派率（右轴）',
                                icon: 'roundRect',
                                color: 'rgba(56, 56, 118, 1)',
                            },
                        ],
                        textStyle: {
                            color: OPTIONDATA.legend.textStyle.color,
                            fontSize: 14,
                        },
                        left: 200,
                        y: 8,
                    },
                ],

                xAxis: {
                    type: 'category',
                    // boundaryGap: true,
                    boundaryGap: true,
                    splitNumber: 4,
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        margin: 20,
                        fontSize: 14,
                        show: true,
                        showMaxLabel: true,
                        color: OPTIONDATA.xAxis.axisLabel.color,
                    },

                    data: dValuationSnapshot2_1_1_X,
                    // data: ['1','2','3','4'],
                },
                yAxis: [
                    {
                        // type: 'value',
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        position: 'left',
                        axisLabel: {
                            fontSize: 14,
                            show: true,

                            color: OPTIONDATA.yAxis.axisLabel.color,
                            formatter: function (args) {
                                if (String(args).length == 3) {
                                    return args + '0';
                                } else {
                                    return args;
                                }
                            },
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                    {
                        // type: 'value',
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        position: 'right',
                        axisLabel: {
                            fontSize: 14,
                            show: true,

                            color: OPTIONDATA.yAxis.axisLabel.color,
                            formatter: function (args) {
                                if (String(args).length == 2) {
                                    return args + '.0' + '%';
                                }
                                if (String(args).length == 1 && args !== 0) {
                                    return args + '.00' + '%';
                                } else {
                                    return 0;
                                }
                            },
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                ],
                series: [
                    {
                        // data: [29000, 33000, 34000, 34100],
                        name: '指数走势（左轴）',
                        data: dValuationSnapshot2_1_1_YL,
                        yAxisIndex: 0,
                        type: 'line',

                        itemStyle: {
                            // color: "rgba(97, 1, 234, 1)",
                            color: color,
                        },
                        symbol: 'none',

                        // itemStyle : { normal: {label : {show: true}}}
                    },
                    {
                        name: '分派率（右轴）',
                        data: dValuationSnapshot2_1_1_YR,
                        yAxisIndex: 1,
                        type: 'line',
                        areaStyle: {
                            // color: "rgba(97, 1, 234, 0.3)",
                            color: theme == 'dark' ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
                        },
                        itemStyle: {
                            // color: "rgba(97, 1, 234, 0.3)",
                            color: theme == 'dark' ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
                        },
                        symbol: 'none',
                    },
                ],
            };

            this.chartColumn2_1_1.setOption(option);
            this.chartColumn2_1_1.resize();
            // this.funResize()
        },

        initChart2_1_2() {
            this.chartColumn2_1_2 = this.$echarts.init(this.$refs.chartColumn2_1_2);

            let dValuationSnapshot2_1_2 = this.dValuationSnapshot2_1_2;
            console.log(dValuationSnapshot2_1_2, 'dValuationSnapshot2_1_2');

            let X = Object.keys(dValuationSnapshot2_1_2.bar);
            X = X.map((node) => {
                return node.slice(0, -4);
            });
            let Y = Object.values(dValuationSnapshot2_1_2.bar);

            let colors = [
                {
                    name: 'PNAV',
                    value: 'rgba(99, 230, 226, 1)',
                },
                {
                    name: 'PFFO',
                    value: 'rgba(86, 84, 202, 1)',
                },
                {
                    name: 'PCFO',
                    value: 'rgba(89, 143, 200, 1)',
                },
                {
                    name: 'EV/EBITDA',
                    value: 'rgba(97, 172, 228, 1)',
                },
            ];

            let OPTIONDATA =
                this.$store.state.theme == 'dark'
                    ? this.$store.state.OPTIONDATA
                    : this.$store.state.OPTIONDATA_light;

            let CQMAX = this.CQMAX;

            let option = {
                grid: {
                    top: 30,
                    bottom: 10,
                    left: 0,
                    right: 0,
                    containLabel: true,
                },

                xAxis: {
                    type: 'category',
                    data: X,

                    axisLine: {
                        show: true,
                        onZero: false, //轴线是否在0刻度轴上
                    },

                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        interval: 0,
                        fontSize: 14,
                        // rotate: 5,
                        show: true,
                        showMaxLabel: true,
                        margin: 10,
                        color: OPTIONDATA.xAxis.axisLabel.color,
                    },
                },
                yAxis: {
                    max: CQMAX,
                    type: 'value',
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                        //x轴刻度相关设置
                        alignWithLabel: true,
                    },
                    axisLabel: {
                        fontSize: 12,
                        show: false,
                        color: OPTIONDATA.yAxis.axisLabel.color,
                    },
                    // data: [0, 1, 2, 3, 4],
                    splitLine: {
                        show: false,
                        lineStyle: {
                            type: 'dashed',
                            color: OPTIONDATA.splitLine.lineStyle.color,
                        },
                    },
                },
                series: [
                    {
                        type: 'bar',
                        data: Y,
                        itemStyle: {
                            // color:'#5f7fe5'
                            color: function (params) {
                                let name = params.name;

                                let obj2 = {};
                                obj2 = colors.find((item) => {
                                    return item.name == name;
                                });
                                return obj2.value;
                            },
                        },
                        barWidth: '16px',
                        label: {
                            show: true,
                            position: 'top',
                            textStyle: {
                                color: OPTIONDATA.title.textStyle.color,
                            },
                            formatter: function (val) {
                                val = Number(val.value).toFixed(1);
                                val = val + 'x';
                                return val;
                            },
                        },
                    },
                ],
            };

            this.chartColumn2_1_2.setOption(option);
            this.chartColumn2_1_2.resize();
            // this.funResize()
        },

        initChart2_1_3() {
            this.chartColumn2_1_3 = this.$echarts.init(this.$refs.chartColumn2_1_3);

            let dValuationSnapshot2_1_3 = this.dValuationSnapshot2_1_3;
            let X = Object.keys(dValuationSnapshot2_1_3.scatter);
            let Y = Object.values(dValuationSnapshot2_1_3.scatter);
            X = X.map((node) => {
                return node.slice(0, -4);
            });

            let colors = [
                {
                    name: 'PNAV',
                    value: 'rgba(99, 230, 226, 1)',
                },
                {
                    name: 'PFFO',
                    value: 'rgba(86, 84, 202, 1)',
                },
                {
                    name: 'PCFO',
                    value: 'rgba(89, 143, 200, 1)',
                },
                {
                    name: 'EV/EBITDA',
                    value: 'rgba(97, 172, 228, 1)',
                },
            ];

            let OPTIONDATA =
                this.$store.state.theme == 'dark'
                    ? this.$store.state.OPTIONDATA
                    : this.$store.state.OPTIONDATA_light;

            let CQMAX_percen = this.CQMAX_percen;

            let option = {
                grid: {
                    top: 30,
                    left: 0,
                    bottom: 10,
                    right: 0,
                    containLabel: true,
                },

                xAxis: {
                    type: 'category',
                    boundaryGap: true,
                    axisLine: {
                        show: true,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        interval: 0,
                        fontSize: 14,
                        show: true,
                        showMaxLabel: true,
                        margin: 10,
                        color: OPTIONDATA.xAxis.axisLabel.color,
                    },

                    data: X,
                    // data: ['1','2','3','4'],
                },
                yAxis: {
                    max: CQMAX_percen,
                    type: 'value',
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        fontSize: 14,
                        show: false,
                        color: OPTIONDATA.yAxis.axisLabel.color,
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            type: 'dashed',
                            color: OPTIONDATA.splitLine.lineStyle.color,
                        },
                    },
                },
                series: [
                    {
                        // data: [29000, 33000, 34000, 34100],
                        name: '公募REITs',
                        data: Y,
                        type: 'line',
                        smooth: false,
                        lineStyle: {
                            color: 'rgba(118, 118, 118, 1)',
                        },
                        itemStyle: {
                            color: function (params) {
                                let name = params.name;

                                let obj2 = {};
                                obj2 = colors.find((item) => {
                                    return item.name == name;
                                });
                                return obj2.value;
                            },
                        },
                        label: {
                            show: true,
                            position: 'top',
                            textStyle: {
                                color: OPTIONDATA.title.textStyle.color,
                            },
                            formatter: function (val) {
                                val = Number(val.value).toFixed(1);
                                return val;
                            },
                        },

                        symbol: 'circle',
                        symbolSize: 18,
                    },
                ],
            };

            this.chartColumn2_1_3.setOption(option);
            this.chartColumn2_1_3.resize();
            // this.funResize()
        },

        // 产业园
        async fnGetValuationSnapshot5() {
            let r_type = '产业园';

            const { data } = await this.$https.get('/api/valuation_snapshot?r_type=' + r_type);

            if (data.code !== 200) return this.$message.error(data.msg);

            // 第一个图
            this.dValuationSnapshot2_2_1 = data.data.data1;
            this.dValuationSnapshot2_2_1_X = [];
            this.dValuationSnapshot2_2_1_YL = [];
            this.dValuationSnapshot2_2_1_YR = [];

            for (let index = 0; index < this.dValuationSnapshot2_2_1.length; index++) {
                this.dValuationSnapshot2_2_1_X.push(this.dValuationSnapshot2_2_1[index].tr_date);
                this.dValuationSnapshot2_2_1_YL.push(this.dValuationSnapshot2_2_1[index].close);
                this.dValuationSnapshot2_2_1_YR.push(this.dValuationSnapshot2_2_1[index].div_yield * 100);
            }

            // 第二个图
            this.dValuationSnapshot2_2_2 = data.data.data2;
            this.CQMAX =
                Math.max(...Object.values(this.dValuationSnapshot2_2_2.bar)) > this.CQMAX
                    ? Math.max(...Object.values(this.dValuationSnapshot2_2_2.bar))
                    : this.CQMAX;

            // 第三个图
            this.dValuationSnapshot2_2_3 = data.data.data3;
            this.CQMAX_percen =
                Math.max(...Object.values(this.dValuationSnapshot2_2_3.scatter)) > this.CQMAX_percen
                    ? Math.max(...Object.values(this.dValuationSnapshot2_2_3.scatter))
                    : this.CQMAX_percen;
            this.$nextTick(() => {
                this.initChart2_2_1();
            });
            // this.initChart2_2_2();
            // this.initChart2_2_3();
        },
        initChart2_2_1() {
            this.chartColumn2_2_1 = this.$echarts.init(this.$refs.chartColumn2_2_1);

            let dValuationSnapshot2_2_1_X = this.dValuationSnapshot2_2_1_X;
            let dValuationSnapshot2_2_1_YL = this.dValuationSnapshot2_2_1_YL;
            let dValuationSnapshot2_2_1_YR = this.dValuationSnapshot2_2_1_YR;

            let OPTIONDATA =
                this.$store.state.theme == 'dark'
                    ? this.$store.state.OPTIONDATA
                    : this.$store.state.OPTIONDATA_light;

            let theme = this.$store.state.theme;
            let color = this.$store.state.colors.find((node) => node.name == '产业园').value;
            this.CYY = color;

            let option = {
                grid: {
                    height: '75%',
                    top: 50,
                    bottom: 0,
                    right: 50,
                    left: 30,
                    containLabel: true,
                },
                tooltip: {
                    trigger: 'axis',
                    triggerOn: 'mousemove|click',
                    formatter: function (args) {
                        let str = '';
                        str += `${args[0].axisValueLabel}`;
                        str += '<br>';
                        args.forEach((node, idx) => {
                            let val = Number(node.value).toFixed(2);
                            if (idx == 0) {
                                str += `${node.marker}${node.seriesName}：${val}`;
                            } else {
                                str += `${node.marker}${node.seriesName}：${val}%`;
                            }
                            str += '<br>';

                            return str;
                        });
                        return str;
                    },
                },

                legend: [
                    {
                        show: true,
                        itemWidth: 30,
                        itemHeight: 3,
                        data: [
                            {
                                name: '指数走势（左轴）',
                                color: 'rgba(86, 84, 202, 1)',
                            },
                        ],
                        textStyle: {
                            color: OPTIONDATA.legend.textStyle.color,
                            fontSize: 14,
                        },
                        left: 30,
                        y: 8,
                    },

                    {
                        show: true,
                        itemWidth: 30,
                        itemHeight: 10,
                        data: [
                            {
                                name: '分派率（右轴）',
                                icon: 'roundRect',
                                color: 'rgba(56, 56, 118, 1)',
                            },
                        ],
                        textStyle: {
                            color: OPTIONDATA.legend.textStyle.color,
                            fontSize: 14,
                        },
                        left: 200,
                        y: 8,
                    },
                ],

                xAxis: {
                    type: 'category',
                    // boundaryGap: true,
                    boundaryGap: true,
                    splitNumber: 4,
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        margin: 20,
                        fontSize: 14,
                        show: true,
                        showMaxLabel: true,
                        color: OPTIONDATA.xAxis.axisLabel.color,
                    },

                    data: dValuationSnapshot2_2_1_X,
                    // data: ['1','2','3','4'],
                },
                yAxis: [
                    {
                        // type: 'value',
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        position: 'left',
                        axisLabel: {
                            fontSize: 14,
                            show: true,

                            color: OPTIONDATA.yAxis.axisLabel.color,
                            formatter: function (args) {
                                if (String(args).length == 3) {
                                    return args + '0';
                                } else {
                                    return args;
                                }
                            },
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                    {
                        // type: 'value',
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        position: 'right',
                        axisLabel: {
                            fontSize: 14,
                            show: true,
                            color: OPTIONDATA.yAxis.axisLabel.color,
                            formatter: function (args) {
                                if (String(args).length == 2) {
                                    return args + '.0' + '%';
                                }
                                if (String(args).length == 1 && args !== 0) {
                                    return args + '.00' + '%';
                                } else {
                                    return 0;
                                }
                            },
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                ],
                series: [
                    {
                        name: '指数走势（左轴）',
                        data: dValuationSnapshot2_2_1_YL,
                        yAxisIndex: 0,
                        type: 'line',

                        itemStyle: {
                            color: color,
                        },
                        symbol: 'none',
                    },
                    {
                        name: '分派率（右轴）',
                        data: dValuationSnapshot2_2_1_YR,
                        yAxisIndex: 1,
                        type: 'line',
                        areaStyle: {
                            color: theme == 'dark' ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
                        },
                        itemStyle: {
                            color: theme == 'dark' ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
                        },
                        symbol: 'none',
                    },
                ],
            };

            this.chartColumn2_2_1.setOption(option);
            this.chartColumn2_2_1.resize();
            // this.funResize()
        },

        initChart2_2_2() {
            this.chartColumn2_2_2 = this.$echarts.init(this.$refs.chartColumn2_2_2);

            let dValuationSnapshot2_2_2 = this.dValuationSnapshot2_2_2;
            let X = Object.keys(dValuationSnapshot2_2_2.bar);
            X = X.map((node) => {
                return node.slice(0, -4);
            });
            let Y = Object.values(dValuationSnapshot2_2_2.bar);

            let colors = [
                {
                    name: 'PNAV',
                    value: 'rgba(99, 230, 226, 1)',
                },
                {
                    name: 'PFFO',
                    value: 'rgba(86, 84, 202, 1)',
                },
                {
                    name: 'PCFO',
                    value: 'rgba(89, 143, 200, 1)',
                },
                {
                    name: 'EV/EBITDA',
                    value: 'rgba(97, 172, 228, 1)',
                },
            ];

            let OPTIONDATA =
                this.$store.state.theme == 'dark'
                    ? this.$store.state.OPTIONDATA
                    : this.$store.state.OPTIONDATA_light;

            let CQMAX = this.CQMAX;

            let option = {
                grid: {
                    top: 30,
                    bottom: 10,
                    left: 0,
                    right: 0,
                    containLabel: true,
                },

                xAxis: {
                    type: 'category',
                    // data: ['S＆P500', '10Yr国债', '沪深300', '公用指数', 'CREITs','','','',''],
                    data: X,

                    axisLine: {
                        show: true,
                        onZero: false, //轴线是否在0刻度轴上
                    },

                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        interval: 0,
                        fontSize: 14,
                        // rotate: 5,
                        show: true,
                        showMaxLabel: true,
                        margin: 10,
                        color: OPTIONDATA.xAxis.axisLabel.color,
                    },
                },
                yAxis: {
                    max: CQMAX,
                    type: 'value',
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                        //x轴刻度相关设置
                        alignWithLabel: true,
                    },
                    axisLabel: {
                        fontSize: 12,
                        show: false,
                        color: OPTIONDATA.yAxis.axisLabel.color,
                    },
                    // data: [0, 1, 2, 3, 4],
                    splitLine: {
                        show: false,
                        lineStyle: {
                            type: 'dashed',
                            color: OPTIONDATA.splitLine.lineStyle.color,
                        },
                    },
                },
                series: [
                    {
                        type: 'bar',
                        data: Y,
                        itemStyle: {
                            // color:'#5f7fe5'
                            color: function (params) {
                                let name = params.name;

                                let obj2 = {};
                                obj2 = colors.find((item) => {
                                    return item.name == name;
                                });
                                return obj2.value;
                            },
                        },
                        barWidth: '16px',
                        label: {
                            show: true,
                            position: 'top',
                            textStyle: {
                                color: OPTIONDATA.title.textStyle.color,
                            },
                            formatter: function (val) {
                                val = Number(val.value).toFixed(1);
                                val = val + 'x';
                                return val;
                            },
                        },
                    },
                ],
            };

            this.chartColumn2_2_2.setOption(option);
            this.chartColumn2_2_2.resize();
            // this.funResize()
        },

        initChart2_2_3() {
            this.chartColumn2_2_3 = this.$echarts.init(this.$refs.chartColumn2_2_3);

            let dValuationSnapshot2_2_3 = this.dValuationSnapshot2_2_3;
            let X = Object.keys(dValuationSnapshot2_2_3.scatter);
            let Y = Object.values(dValuationSnapshot2_2_3.scatter);
            X = X.map((node) => {
                return node.slice(0, -4);
            });

            let colors = [
                {
                    name: 'PNAV',
                    value: 'rgba(99, 230, 226, 1)',
                },
                {
                    name: 'PFFO',
                    value: 'rgba(86, 84, 202, 1)',
                },
                {
                    name: 'PCFO',
                    value: 'rgba(89, 143, 200, 1)',
                },
                {
                    name: 'EV/EBITDA',
                    value: 'rgba(97, 172, 228, 1)',
                },
            ];

            let OPTIONDATA =
                this.$store.state.theme == 'dark'
                    ? this.$store.state.OPTIONDATA
                    : this.$store.state.OPTIONDATA_light;

            let CQMAX_percen = this.CQMAX_percen;
            let option = {
                grid: {
                    top: 30,
                    left: 0,
                    bottom: 10,
                    right: 0,
                    containLabel: true,
                },

                xAxis: {
                    type: 'category',
                    boundaryGap: true,
                    axisLine: {
                        show: true,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        interval: 0,
                        fontSize: 14,
                        show: true,
                        showMaxLabel: true,
                        margin: 10,
                        color: OPTIONDATA.xAxis.axisLabel.color,
                    },

                    data: X,
                    // data: ['1','2','3','4'],
                },
                yAxis: {
                    max: CQMAX_percen,
                    type: 'value',
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        fontSize: 14,
                        show: false,
                        color: OPTIONDATA.yAxis.axisLabel.color,
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            type: 'dashed',
                            color: OPTIONDATA.splitLine.lineStyle.color,
                        },
                    },
                },
                series: [
                    {
                        // data: [29000, 33000, 34000, 34100],
                        name: '公募REITs',
                        data: Y,
                        type: 'line',
                        smooth: false,
                        lineStyle: {
                            color: 'rgba(118, 118, 118, 1)',
                        },
                        itemStyle: {
                            color: function (params) {
                                let name = params.name;

                                let obj2 = {};
                                obj2 = colors.find((item) => {
                                    return item.name == name;
                                });
                                return obj2.value;
                            },
                        },
                        label: {
                            show: true,
                            position: 'top',
                            textStyle: {
                                color: OPTIONDATA.title.textStyle.color,
                            },
                            formatter: function (val) {
                                val = Number(val.value).toFixed(1);
                                return val;
                            },
                        },

                        symbol: 'circle',
                        symbolSize: 18,
                    },
                ],
            };

            this.chartColumn2_2_3.setOption(option);
            this.chartColumn2_2_3.resize();
            // this.funResize()
        },

        // 保障性住房
        async fnGetValuationSnapshot6() {
            let r_type = '保障性住房';

            const { data } = await this.$https.get('/api/valuation_snapshot?r_type=' + r_type);

            if (data.code !== 200) return this.$message.error(data.msg);

            // 第一个图
            this.dValuationSnapshot2_3_1 = data.data.data1;
            this.dValuationSnapshot2_3_1_X = [];
            this.dValuationSnapshot2_3_1_YL = [];
            this.dValuationSnapshot2_3_1_YR = [];

            for (let index = 0; index < this.dValuationSnapshot2_3_1.length; index++) {
                this.dValuationSnapshot2_3_1_X.push(this.dValuationSnapshot2_3_1[index].tr_date);
                this.dValuationSnapshot2_3_1_YL.push(this.dValuationSnapshot2_3_1[index].close);
                this.dValuationSnapshot2_3_1_YR.push(this.dValuationSnapshot2_3_1[index].div_yield * 100);
            }

            // 第二个图
            this.dValuationSnapshot2_3_2 = data.data.data2;
            this.CQMAX =
                Math.max(...Object.values(this.dValuationSnapshot2_3_2.bar)) > this.CQMAX
                    ? Math.max(...Object.values(this.dValuationSnapshot2_3_2.bar))
                    : this.CQMAX;

            // 第三个图
            this.dValuationSnapshot2_3_3 = data.data.data3;
            this.CQMAX_percen =
                Math.max(...Object.values(this.dValuationSnapshot2_3_3.scatter)) > this.CQMAX_percen
                    ? Math.max(...Object.values(this.dValuationSnapshot2_3_3.scatter))
                    : this.CQMAX_percen;
            this.$nextTick(() => {
                this.initChart2_3_1();
            });
            // this.initChart2_3_2();
            // this.initChart2_3_3();
        },
        initChart2_3_1() {
            this.chartColumn2_3_1 = this.$echarts.init(this.$refs.chartColumn2_3_1);

            let dValuationSnapshot2_3_1_X = this.dValuationSnapshot2_3_1_X;
            let dValuationSnapshot2_3_1_YL = this.dValuationSnapshot2_3_1_YL;
            let dValuationSnapshot2_3_1_YR = this.dValuationSnapshot2_3_1_YR;

            let OPTIONDATA =
                this.$store.state.theme == 'dark'
                    ? this.$store.state.OPTIONDATA
                    : this.$store.state.OPTIONDATA_light;

            let theme = this.$store.state.theme;
            let color = this.$store.state.colors.find((node) => node.name == '保障性住房').value;
            this.BZXZF = color;

            let option = {
                grid: {
                    height: '75%',
                    top: 50,
                    bottom: 0,
                    right: 50,
                    left: 30,
                    containLabel: true,
                },
                tooltip: {
                    trigger: 'axis',
                    triggerOn: 'mousemove|click',
                    formatter: function (args) {
                        let str = '';
                        str += `${args[0].axisValueLabel}`;
                        str += '<br>';
                        args.forEach((node, idx) => {
                            let val = Number(node.value).toFixed(2);
                            if (idx == 0) {
                                str += `${node.marker}${node.seriesName}：${val}`;
                            } else {
                                str += `${node.marker}${node.seriesName}：${val}%`;
                            }
                            str += '<br>';

                            return str;
                        });
                        return str;
                    },
                },

                legend: [
                    {
                        show: true,
                        itemWidth: 30,
                        itemHeight: 3,
                        data: [
                            {
                                name: '指数走势（左轴）',
                                color: 'rgba(86, 84, 202, 1)',
                            },
                        ],
                        textStyle: {
                            color: OPTIONDATA.legend.textStyle.color,
                            fontSize: 14,
                        },
                        left: 30,
                        y: 8,
                    },

                    {
                        show: true,
                        itemWidth: 30,
                        itemHeight: 10,
                        data: [
                            {
                                name: '分派率（右轴）',
                                icon: 'roundRect',
                                color: 'rgba(56, 56, 118, 1)',
                            },
                        ],
                        textStyle: {
                            color: OPTIONDATA.legend.textStyle.color,
                            fontSize: 14,
                        },
                        left: 200,
                        y: 8,
                    },
                ],

                xAxis: {
                    type: 'category',
                    // boundaryGap: true,
                    boundaryGap: true,
                    splitNumber: 4,
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        margin: 20,
                        fontSize: 14,
                        show: true,
                        showMaxLabel: true,
                        color: OPTIONDATA.xAxis.axisLabel.color,
                    },

                    data: dValuationSnapshot2_3_1_X,
                    // data: ['1','2','3','4'],
                },
                yAxis: [
                    {
                        // type: 'value',
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        position: 'left',
                        axisLabel: {
                            fontSize: 14,
                            show: true,
                            color: OPTIONDATA.yAxis.axisLabel.color,
                            formatter: function (args) {
                                if (String(args).length == 3) {
                                    return args + '0';
                                } else {
                                    return args;
                                }
                            },
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                    {
                        // type: 'value',
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        position: 'right',
                        axisLabel: {
                            fontSize: 14,
                            show: true,
                            color: OPTIONDATA.yAxis.axisLabel.color,
                            formatter: function (args) {
                                if (String(args).length == 2) {
                                    return args + '.0' + '%';
                                }
                                if (String(args).length == 1 && args !== 0) {
                                    return args + '.00' + '%';
                                } else {
                                    return 0;
                                }
                            },
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                ],
                series: [
                    {
                        name: '指数走势（左轴）',
                        data: dValuationSnapshot2_3_1_YL,
                        yAxisIndex: 0,
                        type: 'line',

                        itemStyle: {
                            color: color,
                        },
                        symbol: 'none',
                    },
                    {
                        name: '分派率（右轴）',
                        data: dValuationSnapshot2_3_1_YR,
                        yAxisIndex: 1,
                        type: 'line',
                        areaStyle: {
                            color: theme == 'dark' ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
                        },
                        itemStyle: {
                            color: theme == 'dark' ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
                        },
                        symbol: 'none',
                    },
                ],
            };

            this.chartColumn2_3_1.setOption(option);
            this.chartColumn2_3_1.resize();
            // this.funResize()
        },

        initChart2_3_2() {
            this.chartColumn2_3_2 = this.$echarts.init(this.$refs.chartColumn2_3_2);

            let dValuationSnapshot2_3_2 = this.dValuationSnapshot2_3_2;
            let X = Object.keys(dValuationSnapshot2_3_2.bar);
            X = X.map((node) => {
                return node.slice(0, -4);
            });
            let Y = Object.values(dValuationSnapshot2_3_2.bar);

            let colors = [
                {
                    name: 'PNAV',
                    value: 'rgba(99, 230, 226, 1)',
                },
                {
                    name: 'PFFO',
                    value: 'rgba(86, 84, 202, 1)',
                },
                {
                    name: 'PCFO',
                    value: 'rgba(89, 143, 200, 1)',
                },
                {
                    name: 'EV/EBITDA',
                    value: 'rgba(97, 172, 228, 1)',
                },
            ];

            let OPTIONDATA =
                this.$store.state.theme == 'dark'
                    ? this.$store.state.OPTIONDATA
                    : this.$store.state.OPTIONDATA_light;

            let CQMAX = this.CQMAX;

            let option = {
                grid: {
                    top: 30,
                    bottom: 10,
                    left: 0,
                    right: 0,
                    containLabel: true,
                },

                xAxis: {
                    type: 'category',
                    data: X,

                    axisLine: {
                        show: true,
                        onZero: false, //轴线是否在0刻度轴上
                    },

                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        interval: 0,
                        fontSize: 14,
                        // rotate: 5,
                        show: true,
                        showMaxLabel: true,
                        margin: 10,
                        color: OPTIONDATA.xAxis.axisLabel.color,
                    },
                },
                yAxis: {
                    max: CQMAX,
                    type: 'value',
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                        //x轴刻度相关设置
                        alignWithLabel: true,
                    },
                    axisLabel: {
                        fontSize: 12,
                        show: false,
                        color: OPTIONDATA.yAxis.axisLabel.color,
                    },
                    // data: [0, 1, 2, 3, 4],
                    splitLine: {
                        show: false,
                        lineStyle: {
                            type: 'dashed',
                            color: OPTIONDATA.splitLine.lineStyle.color,
                        },
                    },
                },
                series: [
                    {
                        type: 'bar',
                        data: Y,
                        itemStyle: {
                            // color:'#5f7fe5'
                            color: function (params) {
                                let name = params.name;

                                let obj2 = {};
                                obj2 = colors.find((item) => {
                                    return item.name == name;
                                });
                                return obj2.value;
                            },
                        },
                        barWidth: '16px',
                        label: {
                            show: true,
                            position: 'top',
                            textStyle: {
                                color: OPTIONDATA.title.textStyle.color,
                            },
                            formatter: function (val) {
                                val = Number(val.value).toFixed(1);
                                val = val + 'x';
                                return val;
                            },
                        },
                    },
                ],
            };

            this.chartColumn2_3_2.setOption(option);
            this.chartColumn2_3_2.resize();
            // this.funResize()
        },

        initChart2_3_3() {
            this.chartColumn2_3_3 = this.$echarts.init(this.$refs.chartColumn2_3_3);

            let dValuationSnapshot2_3_3 = this.dValuationSnapshot2_3_3;
            let X = Object.keys(dValuationSnapshot2_3_3.scatter);
            let Y = Object.values(dValuationSnapshot2_3_3.scatter);
            X = X.map((node) => {
                return node.slice(0, -4);
            });

            let colors = [
                {
                    name: 'PNAV',
                    value: 'rgba(99, 230, 226, 1)',
                },
                {
                    name: 'PFFO',
                    value: 'rgba(86, 84, 202, 1)',
                },
                {
                    name: 'PCFO',
                    value: 'rgba(89, 143, 200, 1)',
                },
                {
                    name: 'EV/EBITDA',
                    value: 'rgba(97, 172, 228, 1)',
                },
            ];

            let OPTIONDATA =
                this.$store.state.theme == 'dark'
                    ? this.$store.state.OPTIONDATA
                    : this.$store.state.OPTIONDATA_light;

            let CQMAX_percen = this.CQMAX_percen;

            let option = {
                grid: {
                    top: 30,
                    left: 0,
                    bottom: 10,
                    right: 0,
                    containLabel: true,
                },

                xAxis: {
                    type: 'category',
                    boundaryGap: true,
                    axisLine: {
                        show: true,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        interval: 0,
                        fontSize: 14,
                        show: true,
                        showMaxLabel: true,
                        margin: 10,
                        color: OPTIONDATA.xAxis.axisLabel.color,
                    },

                    data: X,
                    // data: ['1','2','3','4'],
                },
                yAxis: {
                    max: CQMAX_percen,
                    type: 'value',
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        fontSize: 14,
                        show: false,
                        color: '#8d8d8e',
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            type: 'dashed',
                            color: '#48494c',
                        },
                    },
                },
                series: [
                    {
                        // data: [29000, 33000, 34000, 34100],
                        name: '公募REITs',
                        data: Y,
                        type: 'line',
                        smooth: false,
                        lineStyle: {
                            color: 'rgba(118, 118, 118, 1)',
                        },
                        itemStyle: {
                            color: function (params) {
                                let name = params.name;

                                let obj2 = {};
                                obj2 = colors.find((item) => {
                                    return item.name == name;
                                });
                                return obj2.value;
                            },
                        },
                        label: {
                            show: true,
                            position: 'top',
                            textStyle: {
                                color: OPTIONDATA.title.textStyle.color,
                            },
                            formatter: function (val) {
                                val = Number(val.value).toFixed(1);
                                return val;
                            },
                        },

                        symbol: 'circle',
                        symbolSize: 18,
                    },
                ],
            };

            this.chartColumn2_3_3.setOption(option);
            this.chartColumn2_3_3.resize();
            // this.funResize()
        },

        // 消费
        async fnGetValuationSnapshot7() {
            let r_type = '消费';

            const { data } = await this.$https.get('/api/valuation_snapshot?r_type=' + r_type);

            if (data.code !== 200) return this.$message.error(data.msg);

            // 第一个图
            this.dValuationSnapshot2_4_1 = data.data.data1;
            this.dValuationSnapshot2_4_1_X = [];
            this.dValuationSnapshot2_4_1_YL = [];
            this.dValuationSnapshot2_4_1_YR = [];

            for (let index = 0; index < this.dValuationSnapshot2_4_1.length; index++) {
                this.dValuationSnapshot2_4_1_X.push(this.dValuationSnapshot2_4_1[index].tr_date);
                this.dValuationSnapshot2_4_1_YL.push(this.dValuationSnapshot2_4_1[index].close);
                this.dValuationSnapshot2_4_1_YR.push(this.dValuationSnapshot2_4_1[index].div_yield * 100);
            }

            // 第二个图
            this.dValuationSnapshot2_4_2 = data.data.data2;
            // 第三个图
            this.dValuationSnapshot2_4_3 = data.data.data3;
            this.$nextTick(() => {
                this.initChart2_4_1();
                this.initChart2_4_2();
                this.initChart2_4_3();
            });
        },
        initChart2_4_1() {
            this.chartColumn2_4_1 = this.$echarts.init(this.$refs.chartColumn2_4_1);

            let dValuationSnapshot2_4_1_X = this.dValuationSnapshot2_4_1_X;
            let dValuationSnapshot2_4_1_YL = this.dValuationSnapshot2_4_1_YL;
            let dValuationSnapshot2_4_1_YR = this.dValuationSnapshot2_4_1_YR;

            let OPTIONDATA =
                this.$store.state.theme == 'dark'
                    ? this.$store.state.OPTIONDATA
                    : this.$store.state.OPTIONDATA_light;

            let theme = this.$store.state.theme;
            let color = this.$store.state.colors.find((node) => node.name == '消费').value;
            this.XF = color;

            let option = {
                grid: {
                    height: '75%',
                    top: 50,
                    bottom: 0,
                    right: 50,
                    left: 30,
                    containLabel: true,
                },
                tooltip: {
                    trigger: 'axis',
                    triggerOn: 'mousemove|click',
                    formatter: function (args) {
                        let str = '';
                        str += `${args[0].axisValueLabel}`;
                        str += '<br>';
                        args.forEach((node, idx) => {
                            let val = Number(node.value).toFixed(2);
                            if (idx == 0) {
                                str += `${node.marker}${node.seriesName}：${val}`;
                            } else {
                                str += `${node.marker}${node.seriesName}：${val}%`;
                            }
                            str += '<br>';

                            return str;
                        });
                        return str;
                    },
                },

                legend: [
                    {
                        show: true,
                        itemWidth: 30,
                        itemHeight: 3,
                        data: [
                            {
                                name: '指数走势（左轴）',
                                color: 'rgba(86, 84, 202, 1)',
                            },
                        ],
                        textStyle: {
                            color: OPTIONDATA.legend.textStyle.color,
                            fontSize: 14,
                        },
                        left: 30,
                        y: 8,
                    },

                    {
                        show: true,
                        itemWidth: 30,
                        itemHeight: 10,
                        data: [
                            {
                                name: '分派率（右轴）',
                                icon: 'roundRect',
                                color: 'rgba(56, 56, 118, 1)',
                            },
                        ],
                        textStyle: {
                            color: OPTIONDATA.legend.textStyle.color,
                            fontSize: 14,
                        },
                        left: 200,
                        y: 8,
                    },
                ],

                xAxis: {
                    type: 'category',
                    // boundaryGap: true,
                    boundaryGap: true,
                    splitNumber: 4,
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        margin: 20,
                        fontSize: 14,
                        show: true,
                        showMaxLabel: true,
                        color: OPTIONDATA.xAxis.axisLabel.color,
                    },

                    data: dValuationSnapshot2_4_1_X,
                    // data: ['1','2','3','4'],
                },
                yAxis: [
                    {
                        // type: 'value',
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        position: 'left',
                        axisLabel: {
                            fontSize: 14,
                            show: true,
                            color: OPTIONDATA.yAxis.axisLabel.color,
                            formatter: function (args) {
                                let num = fnGetMuchDecimals(args);

                                if (args == 0) {
                                    return 0;
                                } else {
                                    return args.toFixed(num);
                                }
                            },
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                    {
                        // type: 'value',
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        position: 'right',
                        axisLabel: {
                            fontSize: 14,
                            show: true,
                            color: OPTIONDATA.yAxis.axisLabel.color,
                            formatter: function (args) {
                                let num = fnGetMuchDecimals(args);

                                if (args == 0) {
                                    return 0;
                                } else {
                                    return args.toFixed(num) + '%';
                                }
                            },
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                ],
                series: [
                    {
                        name: '指数走势（左轴）',
                        data: dValuationSnapshot2_4_1_YL,
                        yAxisIndex: 0,
                        type: 'line',

                        itemStyle: {
                            color: color,
                        },
                        symbolSize: 18,
                        symbol: dValuationSnapshot2_4_1_YL.length == 1 ? 'circle' : 'none',
                    },
                    {
                        name: '分派率（右轴）',
                        data: dValuationSnapshot2_4_1_YR,
                        yAxisIndex: 1,
                        type: 'line',
                        areaStyle: {
                            color: theme == 'dark' ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
                        },
                        itemStyle: {
                            color: theme == 'dark' ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
                        },
                        symbolSize: 18,
                        symbol: dValuationSnapshot2_4_1_YR.length == 1 ? 'circle' : 'none',
                    },
                ],
            };

            this.chartColumn2_4_1.setOption(option);
            this.chartColumn2_4_1.resize();
        },

        initChart2_4_2() {
            this.chartColumn2_4_2 = this.$echarts.init(this.$refs.chartColumn2_4_2);

            let dValuationSnapshot2_4_2 = this.dValuationSnapshot2_4_2;
            let X = Object.keys(dValuationSnapshot2_4_2.bar);
            X = X.map((node) => {
                return node.slice(0, -4);
            });
            let Y = Object.values(dValuationSnapshot2_4_2.bar);

            let colors = [
                {
                    name: 'PNAV',
                    value: 'rgba(99, 230, 226, 1)',
                },
                {
                    name: 'PFFO',
                    value: 'rgba(86, 84, 202, 1)',
                },
                {
                    name: 'PCFO',
                    value: 'rgba(89, 143, 200, 1)',
                },
                {
                    name: 'EV/EBITDA',
                    value: 'rgba(97, 172, 228, 1)',
                },
            ];

            let OPTIONDATA =
                this.$store.state.theme == 'dark'
                    ? this.$store.state.OPTIONDATA
                    : this.$store.state.OPTIONDATA_light;

            let option = {
                grid: {
                    top: 30,
                    bottom: 10,
                    left: 0,
                    right: 0,
                    containLabel: true,
                },

                xAxis: {
                    type: 'category',
                    data: X,

                    axisLine: {
                        show: true,
                        onZero: false, //轴线是否在0刻度轴上
                    },

                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        interval: 0,
                        fontSize: 14,
                        // rotate: 5,
                        show: true,
                        showMaxLabel: true,
                        margin: 10,
                        color: OPTIONDATA.xAxis.axisLabel.color,
                    },
                },
                yAxis: {
                    // type: 'value',
                    axisLine: {
                        show: false,
                    },

                    axisTick: {
                        show: false,
                        //x轴刻度相关设置
                        alignWithLabel: true,
                    },
                    axisLabel: {
                        fontSize: 12,
                        show: false,
                        color: OPTIONDATA.yAxis.axisLabel.color,
                    },
                    // data: [0, 1, 2, 3, 4],
                    splitLine: {
                        show: false,
                        lineStyle: {
                            type: 'dashed',
                            color: OPTIONDATA.splitLine.lineStyle.color,
                        },
                    },
                },
                series: [
                    {
                        type: 'bar',
                        data: Y,
                        itemStyle: {
                            // color:'#5f7fe5'
                            color: function (params) {
                                let name = params.name;

                                let obj2 = {};
                                obj2 = colors.find((item) => {
                                    return item.name == name;
                                });
                                return obj2.value;
                            },
                        },
                        barWidth: '16px',
                        label: {
                            show: true,
                            position: 'top',
                            textStyle: {
                                color: OPTIONDATA.title.textStyle.color,
                            },
                            formatter: function (val) {
                                val = Number(val.value).toFixed(1);
                                val = val + 'x';
                                return val;
                            },
                        },
                    },
                ],
            };

            this.chartColumn2_4_2.setOption(option);
            this.chartColumn2_4_2.resize();
        },

        initChart2_4_3() {
            this.chartColumn2_4_3 = this.$echarts.init(this.$refs.chartColumn2_4_3);

            let dValuationSnapshot2_4_3 = this.dValuationSnapshot2_4_3;
            let X = Object.keys(dValuationSnapshot2_4_3.scatter);
            let Y = Object.values(dValuationSnapshot2_4_3.scatter);
            X = X.map((node) => {
                return node.slice(0, -4);
            });

            let colors = [
                {
                    name: 'PNAV',
                    value: 'rgba(99, 230, 226, 1)',
                },
                {
                    name: 'PFFO',
                    value: 'rgba(86, 84, 202, 1)',
                },
                {
                    name: 'PCFO',
                    value: 'rgba(89, 143, 200, 1)',
                },
                {
                    name: 'EV/EBITDA',
                    value: 'rgba(97, 172, 228, 1)',
                },
            ];

            let OPTIONDATA =
                this.$store.state.theme == 'dark'
                    ? this.$store.state.OPTIONDATA
                    : this.$store.state.OPTIONDATA_light;

            let option = {
                grid: {
                    top: 30,
                    left: 0,
                    bottom: 10,
                    right: 0,
                    containLabel: true,
                },

                xAxis: {
                    type: 'category',
                    boundaryGap: true,
                    axisLine: {
                        show: true,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        interval: 0,
                        fontSize: 14,
                        show: true,
                        showMaxLabel: true,
                        margin: 10,
                        color: OPTIONDATA.xAxis.axisLabel.color,
                    },

                    data: X,
                    // data: ['1','2','3','4'],
                },
                yAxis: {
                    type: 'value',
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        fontSize: 14,
                        show: false,
                        color: '#8d8d8e',
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            type: 'dashed',
                            color: '#48494c',
                        },
                    },
                },
                series: [
                    {
                        // data: [29000, 33000, 34000, 34100],
                        name: '公募REITs',
                        data: Y,
                        type: 'line',
                        smooth: false,
                        lineStyle: {
                            color: 'rgba(118, 118, 118, 1)',
                        },
                        itemStyle: {
                            color: function (params) {
                                let name = params.name;

                                let obj2 = {};
                                obj2 = colors.find((item) => {
                                    return item.name == name;
                                });
                                return obj2.value;
                            },
                        },
                        label: {
                            show: true,
                            position: 'top',
                            textStyle: {
                                color: OPTIONDATA.title.textStyle.color,
                            },
                            formatter: function (val) {
                                val = Number(val.value).toFixed(1);
                                return val;
                            },
                        },

                        symbol: 'circle',
                        symbolSize: 18,
                    },
                ],
            };

            this.chartColumn2_4_3.setOption(option);
            this.chartColumn2_4_3.resize();
        },

        // 市政生态
        async fnGetValuationSnapshot8() {
            let r_type = '市政生态';

            const { data } = await this.$https.get('/api/valuation_snapshot?r_type=' + r_type);

            if (data.code !== 200) return this.$message.error(data.msg);

            // 第一个图
            this.dValuationSnapshot3_1_1 = data.data.data1;
            this.dValuationSnapshot3_1_1_X = [];
            this.dValuationSnapshot3_1_1_YL = [];
            this.dValuationSnapshot3_1_1_YR = [];

            for (let index = 0; index < this.dValuationSnapshot3_1_1.length; index++) {
                this.dValuationSnapshot3_1_1_X.push(this.dValuationSnapshot3_1_1[index].tr_date);
                this.dValuationSnapshot3_1_1_YL.push(this.dValuationSnapshot3_1_1[index].close);
                this.dValuationSnapshot3_1_1_YR.push(this.dValuationSnapshot3_1_1[index].div_yield * 100);
            }

            // 第二个图
            this.dValuationSnapshot3_1_2 = data.data.data2;
            this.JYQMAX =
                Math.max(...Object.values(this.dValuationSnapshot3_1_2.bar)) > this.JYQMAX
                    ? Math.max(...Object.values(this.dValuationSnapshot3_1_2.bar))
                    : this.JYQMAX;

            // 第三个图
            this.dValuationSnapshot3_1_3 = data.data.data3;

            this.JYQMAX_percen =
                Math.max(...Object.values(this.dValuationSnapshot3_1_3.scatter)) > this.JYQMAX_percen
                    ? Math.max(...Object.values(this.dValuationSnapshot3_1_3.scatter))
                    : this.JYQMAX_percen;
            this.$nextTick(() => {
                this.initChart3_1_1();
            });
            // this.initChart3_1_2();
            // this.initChart3_1_3();
        },
        initChart3_1_1() {
            this.chartColumn3_1_1 = this.$echarts.init(this.$refs.chartColumn3_1_1);

            let dValuationSnapshot3_1_1_X = this.dValuationSnapshot3_1_1_X;
            let dValuationSnapshot3_1_1_YL = this.dValuationSnapshot3_1_1_YL;
            let dValuationSnapshot3_1_1_YR = this.dValuationSnapshot3_1_1_YR;

            let OPTIONDATA =
                this.$store.state.theme == 'dark'
                    ? this.$store.state.OPTIONDATA
                    : this.$store.state.OPTIONDATA_light;

            let theme = this.$store.state.theme;

            let color = this.$store.state.colors.find((node) => node.name == '市政生态').value;
            this.SZST = color;

            let option = {
                grid: {
                    height: '75%',
                    top: 50,
                    bottom: 0,
                    right: 50,
                    left: 30,
                    containLabel: true,
                },
                tooltip: {
                    trigger: 'axis',
                    triggerOn: 'mousemove|click',
                    formatter: function (args) {
                        let str = '';
                        str += `${args[0].axisValueLabel}`;
                        str += '<br>';
                        args.forEach((node, idx) => {
                            let val = Number(node.value).toFixed(2);
                            if (idx == 0) {
                                str += `${node.marker}${node.seriesName}：${val}`;
                            } else {
                                str += `${node.marker}${node.seriesName}：${val}%`;
                            }
                            str += '<br>';

                            return str;
                        });
                        return str;
                    },
                },

                legend: [
                    {
                        show: true,
                        itemWidth: 30,
                        itemHeight: 3,
                        data: [
                            {
                                name: '指数走势（左轴）',
                                color: 'rgba(86, 84, 202, 1)',
                            },
                        ],
                        textStyle: {
                            color: OPTIONDATA.legend.textStyle.color,
                            fontSize: 14,
                        },
                        left: 30,
                        y: 8,
                    },

                    {
                        show: true,
                        itemWidth: 30,
                        itemHeight: 10,
                        data: [
                            {
                                name: '分派率（右轴）',
                                icon: 'roundRect',
                                color: 'rgba(56, 56, 118, 1)',
                            },
                        ],
                        textStyle: {
                            color: OPTIONDATA.legend.textStyle.color,
                            fontSize: 14,
                        },
                        left: 200,
                        y: 8,
                    },
                ],

                xAxis: {
                    type: 'category',
                    // boundaryGap: true,
                    boundaryGap: true,
                    splitNumber: 4,
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        margin: 20,
                        fontSize: 14,
                        show: true,
                        showMaxLabel: true,
                        color: OPTIONDATA.xAxis.axisLabel.color,
                    },

                    data: dValuationSnapshot3_1_1_X,
                    // data: ['1','2','3','4'],
                },
                yAxis: [
                    {
                        // type: 'value',
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        position: 'left',
                        axisLabel: {
                            fontSize: 14,
                            show: true,
                            color: OPTIONDATA.yAxis.axisLabel.color,
                            formatter: function (args) {
                                if (String(args).length == 3) {
                                    return args + '0';
                                } else {
                                    return args;
                                }
                            },
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                    {
                        // type: 'value',
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        position: 'right',
                        axisLabel: {
                            fontSize: 14,
                            show: true,
                            color: OPTIONDATA.yAxis.axisLabel.color,
                            formatter: function (args) {
                                if (String(args).length == 2) {
                                    return args + '.0' + '%';
                                }
                                if (String(args).length == 1 && args !== 0) {
                                    return args + '.00' + '%';
                                } else {
                                    return 0;
                                }
                            },
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                ],
                series: [
                    {
                        name: '指数走势（左轴）',
                        data: dValuationSnapshot3_1_1_YL,
                        yAxisIndex: 0,
                        type: 'line',

                        itemStyle: {
                            color: color,
                        },
                        symbol: 'none',
                    },
                    {
                        name: '分派率（右轴）',
                        data: dValuationSnapshot3_1_1_YR,
                        yAxisIndex: 1,
                        type: 'line',
                        areaStyle: {
                            color: theme == 'dark' ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
                        },
                        itemStyle: {
                            color: theme == 'dark' ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
                        },
                        symbol: 'none',
                    },
                ],
            };

            this.chartColumn3_1_1.setOption(option);
            this.chartColumn3_1_1.resize();
            // this.funResize()
        },

        initChart3_1_2() {
            this.chartColumn3_1_2 = this.$echarts.init(this.$refs.chartColumn3_1_2);

            let dValuationSnapshot3_1_2 = this.dValuationSnapshot3_1_2;
            let X = Object.keys(dValuationSnapshot3_1_2.bar);
            X = X.map((node) => {
                return node.slice(0, -4);
            });
            let Y = Object.values(dValuationSnapshot3_1_2.bar);

            let colors = [
                {
                    name: 'PNAV',
                    value: 'rgba(99, 230, 226, 1)',
                },
                {
                    name: 'PFFO',
                    value: 'rgba(86, 84, 202, 1)',
                },
                {
                    name: 'PCFO',
                    value: 'rgba(89, 143, 200, 1)',
                },
                {
                    name: 'EV/EBITDA',
                    value: 'rgba(97, 172, 228, 1)',
                },
            ];

            let OPTIONDATA =
                this.$store.state.theme == 'dark'
                    ? this.$store.state.OPTIONDATA
                    : this.$store.state.OPTIONDATA_light;

            let JYQMAX = this.JYQMAX;

            let option = {
                grid: {
                    top: 30,
                    bottom: 10,
                    left: 0,
                    right: 0,
                    containLabel: true,
                },

                xAxis: {
                    type: 'category',
                    // data: ['S＆P500', '10Yr国债', '沪深300', '公用指数', 'CREITs','','','',''],
                    data: X,

                    axisLine: {
                        show: true,
                        onZero: false, //轴线是否在0刻度轴上
                    },

                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        interval: 0,
                        fontSize: 14,
                        // rotate: 5,
                        show: true,
                        showMaxLabel: true,
                        margin: 10,
                        color: OPTIONDATA.xAxis.axisLabel.color,
                    },
                },
                yAxis: {
                    max: JYQMAX,
                    type: 'value',
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                        //x轴刻度相关设置
                        alignWithLabel: true,
                    },
                    axisLabel: {
                        fontSize: 12,
                        show: false,
                        color: OPTIONDATA.yAxis.axisLabel.color,
                    },
                    // data: [0, 1, 2, 3, 4],
                    splitLine: {
                        show: false,
                        lineStyle: {
                            type: 'dashed',
                            color: OPTIONDATA.splitLine.lineStyle.color,
                        },
                    },
                },
                series: [
                    {
                        type: 'bar',
                        data: Y,
                        itemStyle: {
                            // color:'#5f7fe5'
                            color: function (params) {
                                let name = params.name;

                                let obj2 = {};
                                obj2 = colors.find((item) => {
                                    return item.name == name;
                                });
                                return obj2.value;
                            },
                        },
                        barWidth: '16px',
                        label: {
                            show: true,
                            position: 'top',
                            textStyle: {
                                color: OPTIONDATA.title.textStyle.color,
                            },
                            formatter: function (val) {
                                val = Number(val.value).toFixed(1);
                                val = val + 'x';
                                return val;
                            },
                        },
                    },
                ],
            };

            this.chartColumn3_1_2.setOption(option);
            this.chartColumn3_1_2.resize();
        },

        initChart3_1_3() {
            this.chartColumn3_1_3 = this.$echarts.init(this.$refs.chartColumn3_1_3);

            let dValuationSnapshot3_1_3 = this.dValuationSnapshot3_1_3;
            let X = Object.keys(dValuationSnapshot3_1_3.scatter);
            let Y = Object.values(dValuationSnapshot3_1_3.scatter);
            X = X.map((node) => {
                return node.slice(0, -4);
            });

            let colors = [
                {
                    name: 'PNAV',
                    value: 'rgba(99, 230, 226, 1)',
                },
                {
                    name: 'PFFO',
                    value: 'rgba(86, 84, 202, 1)',
                },
                {
                    name: 'PCFO',
                    value: 'rgba(89, 143, 200, 1)',
                },
                {
                    name: 'EV/EBITDA',
                    value: 'rgba(97, 172, 228, 1)',
                },
            ];

            let OPTIONDATA =
                this.$store.state.theme == 'dark'
                    ? this.$store.state.OPTIONDATA
                    : this.$store.state.OPTIONDATA_light;

            let JYQMAX_percen = this.JYQMAX_percen;

            let option = {
                grid: {
                    top: 30,
                    left: 0,
                    bottom: 10,
                    right: 0,
                    containLabel: true,
                },

                xAxis: {
                    type: 'category',
                    boundaryGap: true,
                    axisLine: {
                        show: true,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        interval: 0,
                        fontSize: 14,
                        show: true,
                        showMaxLabel: true,
                        margin: 10,
                        color: OPTIONDATA.xAxis.axisLabel.color,
                    },

                    data: X,
                    // data: ['1','2','3','4'],
                },
                yAxis: {
                    max: JYQMAX_percen,
                    type: 'value',
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        fontSize: 14,
                        show: false,
                        color: OPTIONDATA.yAxis.axisLabel.color,
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            type: 'dashed',
                            color: OPTIONDATA.splitLine.lineStyle.color,
                        },
                    },
                },
                series: [
                    {
                        // data: [29000, 33000, 34000, 34100],
                        name: '公募REITs',
                        data: Y,
                        type: 'line',
                        smooth: false,
                        lineStyle: {
                            color: 'rgba(118, 118, 118, 1)',
                        },
                        itemStyle: {
                            color: function (params) {
                                let name = params.name;

                                let obj2 = {};
                                obj2 = colors.find((item) => {
                                    return item.name == name;
                                });
                                return obj2.value;
                            },
                        },
                        label: {
                            show: true,
                            position: 'top',
                            textStyle: {
                                color: OPTIONDATA.title.textStyle.color,
                            },
                            formatter: function (val) {
                                val = Number(val.value).toFixed(1);
                                return val;
                            },
                        },

                        symbol: 'circle',
                        symbolSize: 18,
                    },
                ],
            };

            this.chartColumn3_1_3.setOption(option);
            this.chartColumn3_1_3.resize();
        },

        // 能源
        async fnGetValuationSnapshot9() {
            let r_type = '能源';

            const { data } = await this.$https.get('/api/valuation_snapshot?r_type=' + r_type);

            if (data.code !== 200) return this.$message.error(data.msg);

            // 第一个图
            this.dValuationSnapshot3_2_1 = data.data.data1;
            this.dValuationSnapshot3_2_1_X = [];
            this.dValuationSnapshot3_2_1_YL = [];
            this.dValuationSnapshot3_2_1_YR = [];

            for (let index = 0; index < this.dValuationSnapshot3_2_1.length; index++) {
                this.dValuationSnapshot3_2_1_X.push(this.dValuationSnapshot3_2_1[index].tr_date);
                this.dValuationSnapshot3_2_1_YL.push(this.dValuationSnapshot3_2_1[index].close);
                this.dValuationSnapshot3_2_1_YR.push(this.dValuationSnapshot3_2_1[index].div_yield * 100);
            }

            // 第二个图
            this.dValuationSnapshot3_2_2 = data.data.data2;
            // 第三个图
            this.dValuationSnapshot3_2_3 = data.data.data3;

            this.JYQMAX =
                Math.max(...Object.values(this.dValuationSnapshot3_2_2.bar)) > this.JYQMAX
                    ? Math.max(...Object.values(this.dValuationSnapshot3_2_2.bar))
                    : this.JYQMAX;

            this.JYQMAX_percen =
                Math.max(...Object.values(this.dValuationSnapshot3_2_3.scatter)) > this.JYQMAX_percen
                    ? Math.max(...Object.values(this.dValuationSnapshot3_2_3.scatter))
                    : this.JYQMAX_percen;
            this.$nextTick(() => {
                this.initChart3_2_1();
            });
            // this.initChart3_2_2();
            // this.initChart3_2_3();
        },
        initChart3_2_1() {
            this.chartColumn3_2_1 = this.$echarts.init(this.$refs.chartColumn3_2_1);

            let dValuationSnapshot3_2_1_X = this.dValuationSnapshot3_2_1_X;
            let dValuationSnapshot3_2_1_YL = this.dValuationSnapshot3_2_1_YL;
            let dValuationSnapshot3_2_1_YR = this.dValuationSnapshot3_2_1_YR;

            let OPTIONDATA =
                this.$store.state.theme == 'dark'
                    ? this.$store.state.OPTIONDATA
                    : this.$store.state.OPTIONDATA_light;

            let theme = this.$store.state.theme;

            let color = this.$store.state.colors.find((node) => node.name == '能源').value;
            this.NY = color;

            let option = {
                grid: {
                    height: '75%',
                    top: 50,
                    bottom: 0,
                    right: 50,
                    left: 30,
                    containLabel: true,
                },
                tooltip: {
                    trigger: 'axis',
                    triggerOn: 'mousemove|click',
                    formatter: function (args) {
                        let str = '';
                        str += `${args[0].axisValueLabel}`;
                        str += '<br>';
                        args.forEach((node, idx) => {
                            let val = Number(node.value).toFixed(2);
                            if (idx == 0) {
                                str += `${node.marker}${node.seriesName}：${val}`;
                            } else {
                                str += `${node.marker}${node.seriesName}：${val}%`;
                            }
                            str += '<br>';

                            return str;
                        });
                        return str;
                    },
                },

                legend: [
                    {
                        show: true,
                        itemWidth: 30,
                        itemHeight: 3,
                        data: [
                            {
                                name: '指数走势（左轴）',
                                color: 'rgba(86, 84, 202, 1)',
                            },
                        ],
                        textStyle: {
                            color: OPTIONDATA.legend.textStyle.color,
                            fontSize: 14,
                        },
                        left: 30,
                        y: 8,
                    },

                    {
                        show: true,
                        itemWidth: 30,
                        itemHeight: 10,
                        data: [
                            {
                                name: '分派率（右轴）',
                                icon: 'roundRect',
                                color: 'rgba(56, 56, 118, 1)',
                            },
                        ],
                        textStyle: {
                            color: OPTIONDATA.legend.textStyle.color,
                            fontSize: 14,
                        },
                        left: 200,
                        y: 8,
                    },
                ],

                xAxis: {
                    type: 'category',
                    // boundaryGap: true,
                    boundaryGap: true,
                    splitNumber: 4,
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        margin: 20,
                        fontSize: 14,
                        show: true,
                        showMaxLabel: true,
                        color: OPTIONDATA.xAxis.axisLabel.color,
                    },

                    data: dValuationSnapshot3_2_1_X,
                    // data: ['1','2','3','4'],
                },
                yAxis: [
                    {
                        // type: 'value',
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        position: 'left',
                        axisLabel: {
                            fontSize: 14,
                            show: true,
                            color: OPTIONDATA.yAxis.axisLabel.color,
                            formatter: function (args) {
                                if (String(args).length == 3) {
                                    return args + '0';
                                } else {
                                    return args;
                                }
                            },
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                    {
                        // type: 'value',
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        position: 'right',
                        axisLabel: {
                            fontSize: 14,
                            show: true,
                            color: OPTIONDATA.yAxis.axisLabel.color,
                            formatter: function (args) {
                                if (String(args).length == 2) {
                                    return args + '.0' + '%';
                                }
                                if (String(args).length == 1 && args !== 0) {
                                    return args + '.00' + '%';
                                } else {
                                    return 0;
                                }
                            },
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                ],
                series: [
                    {
                        name: '指数走势（左轴）',
                        data: dValuationSnapshot3_2_1_YL,
                        yAxisIndex: 0,
                        type: 'line',

                        itemStyle: {
                            color: color,
                        },
                        symbol: 'none',
                    },
                    {
                        name: '分派率（右轴）',
                        data: dValuationSnapshot3_2_1_YR,
                        yAxisIndex: 1,
                        type: 'line',
                        areaStyle: {
                            color: theme == 'dark' ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
                        },
                        itemStyle: {
                            color: theme == 'dark' ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
                        },
                        symbol: 'none',
                    },
                ],
            };

            this.chartColumn3_2_1.setOption(option);
            this.chartColumn3_2_1.resize();
            // this.funResize()
        },

        initChart3_2_2() {
            this.chartColumn3_2_2 = this.$echarts.init(this.$refs.chartColumn3_2_2);

            let dValuationSnapshot3_2_2 = this.dValuationSnapshot3_2_2;
            let X = Object.keys(dValuationSnapshot3_2_2.bar);
            X = X.map((node) => {
                return node.slice(0, -4);
            });
            let Y = Object.values(dValuationSnapshot3_2_2.bar);

            let colors = [
                {
                    name: 'PNAV',
                    value: 'rgba(99, 230, 226, 1)',
                },
                {
                    name: 'PFFO',
                    value: 'rgba(86, 84, 202, 1)',
                },
                {
                    name: 'PCFO',
                    value: 'rgba(89, 143, 200, 1)',
                },
                {
                    name: 'EV/EBITDA',
                    value: 'rgba(97, 172, 228, 1)',
                },
            ];

            let OPTIONDATA =
                this.$store.state.theme == 'dark'
                    ? this.$store.state.OPTIONDATA
                    : this.$store.state.OPTIONDATA_light;

            let JYQMAX = this.JYQMAX;

            let option = {
                grid: {
                    top: 30,
                    bottom: 10,
                    left: 0,
                    right: 0,
                    containLabel: true,
                },

                xAxis: {
                    type: 'category',
                    // data: ['S＆P500', '10Yr国债', '沪深300', '公用指数', 'CREITs','','','',''],
                    data: X,

                    axisLine: {
                        show: true,
                        onZero: false, //轴线是否在0刻度轴上
                    },

                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        interval: 0,
                        fontSize: 14,
                        // rotate: 5,
                        show: true,
                        showMaxLabel: true,
                        margin: 10,
                        color: OPTIONDATA.xAxis.axisLabel.color,
                    },
                },
                yAxis: {
                    max: JYQMAX,
                    type: 'value',
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                        //x轴刻度相关设置
                        alignWithLabel: true,
                    },
                    axisLabel: {
                        fontSize: 12,
                        show: false,
                        color: OPTIONDATA.yAxis.axisLabel.color,
                    },
                    // data: [0, 1, 2, 3, 4],
                    splitLine: {
                        show: false,
                        lineStyle: {
                            type: 'dashed',
                            color: '#48494c',
                        },
                    },
                },
                series: [
                    {
                        type: 'bar',
                        data: Y,
                        itemStyle: {
                            // color:'#5f7fe5'
                            color: function (params) {
                                let name = params.name;

                                let obj2 = {};
                                obj2 = colors.find((item) => {
                                    return item.name == name;
                                });
                                return obj2.value;
                            },
                        },
                        barWidth: '16px',
                        label: {
                            show: true,
                            position: 'top',
                            textStyle: {
                                color: OPTIONDATA.title.textStyle.color,
                            },
                            formatter: function (val) {
                                val = Number(val.value).toFixed(1);
                                val = val + 'x';
                                return val;
                            },
                        },
                    },
                ],
            };

            this.chartColumn3_2_2.setOption(option);
            this.chartColumn3_2_2.resize();
        },

        initChart3_2_3() {
            this.chartColumn3_2_3 = this.$echarts.init(this.$refs.chartColumn3_2_3);

            let dValuationSnapshot3_2_3 = this.dValuationSnapshot3_2_3;
            let X = Object.keys(dValuationSnapshot3_2_3.scatter);
            let Y = Object.values(dValuationSnapshot3_2_3.scatter);
            X = X.map((node) => {
                return node.slice(0, -4);
            });

            let colors = [
                {
                    name: 'PNAV',
                    value: 'rgba(99, 230, 226, 1)',
                },
                {
                    name: 'PFFO',
                    value: 'rgba(86, 84, 202, 1)',
                },
                {
                    name: 'PCFO',
                    value: 'rgba(89, 143, 200, 1)',
                },
                {
                    name: 'EV/EBITDA',
                    value: 'rgba(97, 172, 228, 1)',
                },
            ];

            let OPTIONDATA =
                this.$store.state.theme == 'dark'
                    ? this.$store.state.OPTIONDATA
                    : this.$store.state.OPTIONDATA_light;

            let JYQMAX_percen = this.JYQMAX_percen;

            let option = {
                grid: {
                    top: 30,
                    left: 0,
                    bottom: 10,
                    right: 0,
                    containLabel: true,
                },

                xAxis: {
                    type: 'category',
                    boundaryGap: true,
                    axisLine: {
                        show: true,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        interval: 0,
                        fontSize: 14,
                        show: true,
                        showMaxLabel: true,
                        margin: 10,
                        color: OPTIONDATA.xAxis.axisLabel.color,
                    },

                    data: X,
                    // data: ['1','2','3','4'],
                },
                yAxis: {
                    max: JYQMAX_percen,
                    type: 'value',
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        fontSize: 14,
                        show: false,
                        color: OPTIONDATA.yAxis.axisLabel.color,
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            type: 'dashed',
                            color: '#48494c',
                        },
                    },
                },
                series: [
                    {
                        // data: [29000, 33000, 34000, 34100],
                        name: '公募REITs',
                        data: Y,
                        type: 'line',
                        smooth: false,
                        lineStyle: {
                            color: 'rgba(118, 118, 118, 1)',
                        },
                        itemStyle: {
                            color: function (params) {
                                let name = params.name;

                                let obj2 = {};
                                obj2 = colors.find((item) => {
                                    return item.name == name;
                                });
                                return obj2.value;
                            },
                        },
                        label: {
                            show: true,
                            position: 'top',
                            textStyle: {
                                color: OPTIONDATA.title.textStyle.color,
                            },
                            formatter: function (val) {
                                val = Number(val.value).toFixed(1);
                                return val;
                            },
                        },

                        symbol: 'circle',
                        symbolSize: 18,
                    },
                ],
            };

            this.chartColumn3_2_3.setOption(option);
            this.chartColumn3_2_3.resize();
        },

        // 收费公路
        async fnGetValuationSnapshot10() {
            let r_type = '收费公路';

            const { data } = await this.$https.get('/api/valuation_snapshot?r_type=' + r_type);

            if (data.code !== 200) return this.$message.error(data.msg);

            // 第一个图
            this.dValuationSnapshot3_3_1 = data.data.data1;
            this.dValuationSnapshot3_3_1_X = [];
            this.dValuationSnapshot3_3_1_YL = [];
            this.dValuationSnapshot3_3_1_YR = [];

            for (let index = 0; index < this.dValuationSnapshot3_3_1.length; index++) {
                this.dValuationSnapshot3_3_1_X.push(this.dValuationSnapshot3_3_1[index].tr_date);
                this.dValuationSnapshot3_3_1_YL.push(this.dValuationSnapshot3_3_1[index].close);
                this.dValuationSnapshot3_3_1_YR.push(this.dValuationSnapshot3_3_1[index].div_yield * 100);
            }

            // 第二个图
            this.dValuationSnapshot3_3_2 = data.data.data2;
            // 第三个图
            this.dValuationSnapshot3_3_3 = data.data.data3;

            this.JYQMAX =
                Math.max(...Object.values(this.dValuationSnapshot3_3_2.bar)) > this.JYQMAX
                    ? Math.max(...Object.values(this.dValuationSnapshot3_3_2.bar))
                    : this.JYQMAX;

            this.JYQMAX_percen =
                Math.max(...Object.values(this.dValuationSnapshot3_3_3.scatter)) > this.JYQMAX_percen
                    ? Math.max(...Object.values(this.dValuationSnapshot3_3_3.scatter))
                    : this.JYQMAX_percen;
            this.$nextTick(() => {
                this.initChart3_3_1();
            });
            // this.initChart3_3_2();
            // this.initChart3_3_3();
        },
        initChart3_3_1() {
            this.chartColumn3_3_1 = this.$echarts.init(this.$refs.chartColumn3_3_1);

            let dValuationSnapshot3_3_1_X = this.dValuationSnapshot3_3_1_X;
            let dValuationSnapshot3_3_1_YL = this.dValuationSnapshot3_3_1_YL;
            let dValuationSnapshot3_3_1_YR = this.dValuationSnapshot3_3_1_YR;

            let OPTIONDATA =
                this.$store.state.theme == 'dark'
                    ? this.$store.state.OPTIONDATA
                    : this.$store.state.OPTIONDATA_light;

            let theme = this.$store.state.theme;

            let color = this.$store.state.colors.find((node) => node.name == '收费公路').value;
            this.SFGL = color;

            let option = {
                grid: {
                    height: '75%',
                    top: 50,
                    bottom: 0,
                    right: 50,
                    left: 30,
                    containLabel: true,
                },
                tooltip: {
                    trigger: 'axis',
                    triggerOn: 'mousemove|click',
                    formatter: function (args) {
                        let str = '';
                        str += `${args[0].axisValueLabel}`;
                        str += '<br>';
                        args.forEach((node, idx) => {
                            let val = Number(node.value).toFixed(2);
                            if (idx == 0) {
                                str += `${node.marker}${node.seriesName}：${val}`;
                            } else {
                                str += `${node.marker}${node.seriesName}：${val}%`;
                            }
                            str += '<br>';

                            return str;
                        });
                        return str;
                    },
                },

                legend: [
                    {
                        show: true,
                        itemWidth: 30,
                        itemHeight: 3,
                        data: [
                            {
                                name: '指数走势（左轴）',
                                color: 'rgba(86, 84, 202, 1)',
                            },
                        ],
                        textStyle: {
                            color: OPTIONDATA.legend.textStyle.color,
                            fontSize: 14,
                        },
                        left: 30,
                        y: 8,
                    },

                    {
                        show: true,
                        itemWidth: 30,
                        itemHeight: 10,
                        data: [
                            {
                                name: '分派率（右轴）',
                                icon: 'roundRect',
                                color: 'rgba(56, 56, 118, 1)',
                            },
                        ],
                        textStyle: {
                            color: OPTIONDATA.legend.textStyle.color,
                            fontSize: 14,
                        },
                        left: 200,
                        y: 8,
                    },
                ],

                xAxis: {
                    type: 'category',
                    // boundaryGap: true,
                    boundaryGap: true,
                    splitNumber: 4,
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        margin: 20,
                        fontSize: 14,
                        show: true,
                        showMaxLabel: true,
                        color: OPTIONDATA.xAxis.axisLabel.color,
                    },

                    data: dValuationSnapshot3_3_1_X,
                    // data: ['1','2','3','4'],
                },
                yAxis: [
                    {
                        // type: 'value',
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        position: 'left',
                        axisLabel: {
                            fontSize: 14,
                            show: true,
                            color: OPTIONDATA.yAxis.axisLabel.color,
                            formatter: function (args) {
                                if (String(args).length == 3) {
                                    return args + '0';
                                } else {
                                    return args;
                                }
                            },
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                    {
                        // type: 'value',
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        position: 'right',
                        axisLabel: {
                            fontSize: 14,
                            show: true,
                            color: OPTIONDATA.yAxis.axisLabel.color,
                            formatter: function (args) {
                                if (String(args).length == 2) {
                                    return args + '.0' + '%';
                                }
                                if (String(args).length == 1 && args !== 0) {
                                    return args + '.00' + '%';
                                } else {
                                    return 0;
                                }
                            },
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                ],
                series: [
                    {
                        name: '指数走势（左轴）',
                        data: dValuationSnapshot3_3_1_YL,
                        yAxisIndex: 0,
                        type: 'line',

                        itemStyle: {
                            color: color,
                        },
                        symbol: 'none',
                    },
                    {
                        name: '分派率（右轴）',
                        data: dValuationSnapshot3_3_1_YR,
                        yAxisIndex: 1,
                        type: 'line',
                        areaStyle: {
                            color: theme == 'dark' ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
                        },
                        itemStyle: {
                            color: theme == 'dark' ? hexToRgba(color, 0.3) : hexToRgba(color, 0.8),
                        },
                        symbol: 'none',
                    },
                ],
            };

            this.chartColumn3_3_1.setOption(option);
            this.chartColumn3_3_1.resize();
        },

        initChart3_3_2() {
            this.chartColumn3_3_2 = this.$echarts.init(this.$refs.chartColumn3_3_2);

            let dValuationSnapshot3_3_2 = this.dValuationSnapshot3_3_2;
            let X = Object.keys(dValuationSnapshot3_3_2.bar);
            X = X.map((node) => {
                return node.slice(0, -4);
            });
            let Y = Object.values(dValuationSnapshot3_3_2.bar);

            let colors = [
                {
                    name: 'PNAV',
                    value: 'rgba(99, 230, 226, 1)',
                },
                {
                    name: 'PFFO',
                    value: 'rgba(86, 84, 202, 1)',
                },
                {
                    name: 'PCFO',
                    value: 'rgba(89, 143, 200, 1)',
                },
                {
                    name: 'EV/EBITDA',
                    value: 'rgba(97, 172, 228, 1)',
                },
            ];

            let OPTIONDATA =
                this.$store.state.theme == 'dark'
                    ? this.$store.state.OPTIONDATA
                    : this.$store.state.OPTIONDATA_light;

            let JYQMAX = this.JYQMAX;

            let option = {
                grid: {
                    top: 30,
                    bottom: 10,
                    left: 0,
                    right: 0,
                    containLabel: true,
                },

                xAxis: {
                    type: 'category',
                    // data: ['S＆P500', '10Yr国债', '沪深300', '公用指数', 'CREITs','','','',''],
                    data: X,

                    axisLine: {
                        show: true,
                        onZero: false, //轴线是否在0刻度轴上
                    },

                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        interval: 0,
                        fontSize: 14,
                        // rotate: 5,
                        show: true,
                        showMaxLabel: true,
                        margin: 10,
                        color: OPTIONDATA.xAxis.axisLabel.color,
                    },
                },
                yAxis: {
                    max: JYQMAX,
                    type: 'value',
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                        //x轴刻度相关设置
                        alignWithLabel: true,
                    },
                    axisLabel: {
                        fontSize: 12,
                        show: false,
                        color: OPTIONDATA.yAxis.axisLabel.color,
                    },
                    // data: [0, 1, 2, 3, 4],
                    splitLine: {
                        show: false,
                        lineStyle: {
                            type: 'dashed',
                            color: OPTIONDATA.splitLine.lineStyle.color,
                        },
                    },
                },
                series: [
                    {
                        type: 'bar',
                        data: Y,
                        itemStyle: {
                            // color:'#5f7fe5'
                            color: function (params) {
                                let name = params.name;

                                let obj2 = {};
                                obj2 = colors.find((item) => {
                                    return item.name == name;
                                });
                                return obj2.value;
                            },
                        },
                        barWidth: '16px',
                        label: {
                            show: true,
                            position: 'top',
                            textStyle: {
                                color: OPTIONDATA.title.textStyle.color,
                            },
                            formatter: function (val) {
                                val = Number(val.value).toFixed(1);
                                val = val + 'x';
                                return val;
                            },
                        },
                    },
                ],
            };

            this.chartColumn3_3_2.setOption(option);
            this.chartColumn3_3_2.resize();
        },

        initChart3_3_3() {
            this.chartColumn3_3_3 = this.$echarts.init(this.$refs.chartColumn3_3_3);

            let dValuationSnapshot3_3_3 = this.dValuationSnapshot3_3_3;
            let X = Object.keys(dValuationSnapshot3_3_3.scatter);
            let Y = Object.values(dValuationSnapshot3_3_3.scatter);
            X = X.map((node) => {
                return node.slice(0, -4);
            });

            let colors = [
                {
                    name: 'PNAV',
                    value: 'rgba(99, 230, 226, 1)',
                },
                {
                    name: 'PFFO',
                    value: 'rgba(86, 84, 202, 1)',
                },
                {
                    name: 'PCFO',
                    value: 'rgba(89, 143, 200, 1)',
                },
                {
                    name: 'EV/EBITDA',
                    value: 'rgba(97, 172, 228, 1)',
                },
            ];

            let OPTIONDATA =
                this.$store.state.theme == 'dark'
                    ? this.$store.state.OPTIONDATA
                    : this.$store.state.OPTIONDATA_light;

            let JYQMAX_percen = this.JYQMAX_percen;

            let option = {
                grid: {
                    top: 30,
                    left: 0,
                    bottom: 10,
                    right: 0,
                    containLabel: true,
                },

                xAxis: {
                    type: 'category',
                    boundaryGap: true,
                    axisLine: {
                        show: true,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        interval: 0,
                        fontSize: 14,
                        show: true,
                        showMaxLabel: true,
                        margin: 10,
                        color: OPTIONDATA.xAxis.axisLabel.color,
                    },

                    data: X,
                    // data: ['1','2','3','4'],
                },
                yAxis: {
                    max: JYQMAX_percen,
                    type: 'value',
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        fontSize: 14,
                        show: false,
                        color: '#8d8d8e',
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            type: 'dashed',
                            color: '#48494c',
                        },
                    },
                },
                series: [
                    {
                        // data: [29000, 33000, 34000, 34100],
                        name: '公募REITs',
                        data: Y,
                        type: 'line',
                        smooth: false,
                        lineStyle: {
                            color: 'rgba(118, 118, 118, 1)',
                        },
                        itemStyle: {
                            color: function (params) {
                                let name = params.name;

                                let obj2 = {};
                                obj2 = colors.find((item) => {
                                    return item.name == name;
                                });
                                return obj2.value;
                            },
                        },
                        label: {
                            show: true,
                            position: 'top',
                            textStyle: {
                                color: OPTIONDATA.title.textStyle.color,
                            },
                            formatter: function (val) {
                                val = Number(val.value).toFixed(1);
                                return val;
                            },
                        },

                        symbol: 'circle',
                        symbolSize: 18,
                    },
                ],
            };

            this.chartColumn3_3_3.setOption(option);
            this.chartColumn3_3_3.resize();
        },

        async fnSecondCategory(val) {
            console.log("fnSecondCategory val",val);
            if (val == '产权类') {
                this.bMAINCARD = false;
                this.bCQLCARD = true;
                this.bJYQLCARD = false;
                await this.fnGetValuationSnapshot4();
                await this.fnGetValuationSnapshot5();
                await this.fnGetValuationSnapshot6();

                await this.$nextTick(() => {
                    this.initChart2_1_2();
                });
                await this.$nextTick(() => {
                    this.initChart2_1_3();
                });

                await this.$nextTick(() => {
                    this.initChart2_2_2();
                });
                await this.$nextTick(() => {
                    this.initChart2_2_3();
                });

                await this.$nextTick(() => {
                    this.initChart2_3_2();
                });
                await this.$nextTick(() => {
                    this.initChart2_3_3();
                });

                // this.fnGetValuationSnapshot7();
            } else {
                this.bMAINCARD = false;
                this.bCQLCARD = false;
                this.bJYQLCARD = true;
                await this.fnGetValuationSnapshot8();
                await this.fnGetValuationSnapshot9();
                await this.fnGetValuationSnapshot10();

                await this.$nextTick(() => {
                    this.initChart3_1_2();
                });
                await this.$nextTick(() => {
                    this.initChart3_1_3();
                });

                await this.$nextTick(() => {
                    this.initChart3_2_2();
                });
                await this.$nextTick(() => {
                    this.initChart3_2_3();
                });

                await this.$nextTick(() => {
                    this.initChart3_3_2();
                });
                await this.$nextTick(() => {
                    this.initChart3_3_3();
                });
            }
        },



        fnMore() {
            console.log("fnMore");
            this.bCQLCARD = false;
            this.CQL_more = true;
            this.bMAINCARD = false;
            this.bJYQLCARD = false;

            this.fnGetValuationSnapshot7();
        },

        fnGoRight() {
            console.log("fnGoRight");
            this.bCQLCARD = true;
            this.CQL_more = false;
            this.bMAINCARD = false;
            this.bJYQLCARD = false;
            this.fnSecondCategory("产权类");
        },


        fnGetWidth() {
            var element = document.getElementById('rootchart');
            let ROOTFONTFIZE = element.offsetWidth;
            ROOTFONTFIZE = ROOTFONTFIZE > 1440 ? 1440 : ROOTFONTFIZE;
            ROOTFONTFIZE = ROOTFONTFIZE < 1440 ? Number(ROOTFONTFIZE) - 200 : ROOTFONTFIZE;
            this.ROOTFONTFIZE = ROOTFONTFIZE / 10;
            window.sessionStorage.setItem('ROOTFONTFIZE', this.ROOTFONTFIZE);
            let OFFSETHEIGHT = element.offsetHeight;
            this.TABPANEHEIGHT = OFFSETHEIGHT - 135;
            this.OFFSETHEIGHT = OFFSETHEIGHT;

            return;
        },

        //  表头方法
        styleBindFun() {
            let val = this.$store.state.theme == 'dark' ? true : false;
            return fnReturnTableStyle(val);
        },

        fnGetTheme() {
            this.sCurrentTheme = this.$store.state.theme == 'dark' ? true : false;
        },
    },
    filters: {
        isZero: function (val) {
            let value = val.substring(0, val.length - 1);
            if (value == 0) {
                return '';
            } else {
                return val;
            }
        },
    },
    components: {
        CSelect,
        CCategoryButton,
        CCascader,
        CButtonsSeparate,
        CTimeButtons,
        CotherButtons,
        CButtonsDownload,
        CDialog,
    },
    computed: {
        cssVars() {
            return {
                '--ROOTFONTFIZE': this.ROOTFONTFIZE + 'px',
                '--TABPANEHEIGHT': this.TABPANEHEIGHT + 'px',
                '--GMREITs': this.GMREITs,
                '--CQLREITs': this.CQLREITs,
                '--JYQLREITs': this.JYQLREITs,
                '--WLCC': this.WLCC,
                '--CYY': this.CYY,
                '--BZXZF': this.BZXZF,
                '--XF': this.XF,
                '--SZST': this.SZST,
                '--NY': this.NY,
                '--SFGL': this.SFGL,
            };
        },
        currentTheme() {
            return this.$store.state.theme; // 从Vuex中获取当前的主题值
        },


    },
    watch: {
        currentTheme(newVal, oldVal) {
            this.styleBindFun();
            this.fnGetTheme();
            fnDownloadBgc();
            this.$nextTick(() => {
                if (this.activeName != 'first') {
                    return;
                }
                if (this.bMAINCARD) {
                    this.initChart1();
                    this.initChart1_2();
                    this.initChart1_3();
                    this.initChart2();
                    this.initChart2_2();
                    this.initChart2_3();
                    this.initChart3();
                    this.initChart3_2();
                    this.initChart3_3();
                }

                if (this.bCQLCARD) {
                    this.initChart2_1_1();
                    this.initChart2_1_2();
                    this.initChart2_1_3();

                    this.initChart2_2_1();
                    this.initChart2_2_2();
                    this.initChart2_2_3();

                    this.initChart2_3_1();
                    this.initChart2_3_2();
                    this.initChart2_3_3();
                }

                if (this.bJYQLCARD) {
                    this.initChart3_1_1();
                    this.initChart3_1_2();
                    this.initChart3_1_3();

                    this.initChart3_2_1();
                    this.initChart3_2_2();
                    this.initChart3_2_3();

                    this.initChart3_3_1();
                    this.initChart3_3_2();
                    this.initChart3_3_3();
                }

                if (this.CQL_more) {
                    this.initChart2_4_1();
                    this.initChart2_4_2();
                    this.initChart2_4_3();
                }
            });
            return;
        },
    },
};
</script>

<style scoped>
.openBtn {
    background-color: var(--primary-color) !important;
    color: #333333 !important;
    border: none !important;
    margin-top: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.openBtn:hover {
    background-color: var(--primary-color) !important;
    color: #333333 !important;
    border: none !important;
    background-size: contain;
}

.btnArea_center {
    display: flex !important;
    justify-content: center !important;
    text-align: center !important;
    width: 100%;
}

.container {
    font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
    font-weight: 400;
    letter-spacing: 0.25px;
    /* line-height: calc(var(--ROOTFONTFIZE) * (20 / 144)); */
    height: 100%;
    /* padding-right: 20px; */
    color: rgba(255, 255, 255, 1);
    /* height: auto; */
}

.top {
    width: 100%;
    height: calc(var(--ROOTFONTFIZE) * (470 / 144));
    border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
    background: rgba(29, 31, 37, 1);
}

.bottom {
    display: flex;
    margin-top: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.bottom .left,
.bottom .right {
    flex: 1;
    height: calc(var(--ROOTFONTFIZE) * (430 / 144));
    border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
    background: rgba(29, 31, 37, 1);
}

.bottom .right {
    margin-left: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.tit {
    margin-top: calc(var(--ROOTFONTFIZE) * (15 / 144));
    font-size: calc(var(--ROOTFONTFIZE) * (22 / 144));
    font-weight: 700;
    line-height: calc(var(--ROOTFONTFIZE) * (28 / 144));
    letter-spacing: 0px;
    color: rgba(255, 255, 255, 1);
    border-left: calc(var(--ROOTFONTFIZE) * (5 / 144)) solid rgba(21, 115, 254, 1);
    padding-left: calc(var(--ROOTFONTFIZE) * (13 / 144));
}

.other_chart1_style {
    display: flex;
    margin-left: calc(var(--ROOTFONTFIZE) * (10 / 144));
    margin-top: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.other_chart1_style div:nth-child(1) {
    flex: 3;
    margin-right: calc(var(--ROOTFONTFIZE) * (30 / 144));
    display: flex;
    justify-content: center;
}

.other_chart1_style div:nth-child(2) {
    flex: 6;
}

.other_chart1 {
    /* width: 60%; */
}

/* ------------------------------------------------------------------ */
.card {
    /* background-color: rgba(29, 31, 37, 1); */
    background-color: var(--charts_bgc);
    height: auto;
    border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
    /* margin-top: calc(var(--ROOTFONTFIZE) * (5 / 144)); */
    padding-top: calc(var(--ROOTFONTFIZE) * (15 / 144));
    padding-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
    overflow: hidden;
}

.card_tit {
    font-size: calc(var(--ROOTFONTFIZE) * (22 / 144));
    font-weight: 700;
    letter-spacing: 0px;
    line-height: calc(var(--ROOTFONTFIZE) * (28 / 144));
    color: var(--chart_title);
    /* border-left: 5px solid rgba(21, 115, 254, 1); */
    border-left: calc(var(--ROOTFONTFIZE) * (5 / 144)) solid var(--primary-color);
    padding-left: calc(var(--ROOTFONTFIZE) * (13 / 144));
    display: flex;
    justify-content: space-between;
}

.card_tit2 {
    display: flex;
    justify-content: end;
    padding-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.card .main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 calc(var(--ROOTFONTFIZE) * (20 / 144));
    padding-top: calc(var(--ROOTFONTFIZE) * (20 / 144));
    overflow-x: hidden;
    height: 100%;
    position: relative;
}

.hasMore {}

.hasMore:hover .btnMo,
.hasMore:hover .leftBtn {
    opacity: 1;
    cursor: pointer;
}

.btnMo {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    height: 18%;
    width: auto;
    padding: 0 30px;
    display: flex;
    align-items: center;
    opacity: 0;
    background-color: rgba(127, 127, 127, 0.1);
    transition: opacity 0.5s;
}

.OVERVIEWICONSTYLE {
    background-color: #383838;
    color: #f7ecdc;
    font-size: 25px;
    padding: 10px;
    border-radius: 50%;
}

/* .btnMo:hover,
  .leftBtn:hover {
    opacity: 1;
    cursor: pointer;
  } */

.leftBtn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0 !important;
    height: 18%;
    width: auto !important;
    padding: 0 30px;
    display: flex;
    align-items: center;
    opacity: 0;
    background-color: rgba(127, 127, 127, 0.1);
    transition: opacity 0.5s;
}

.specialMain {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
}

.card .main .main_item {
    width: 30%;
    height: calc(var(--ROOTFONTFIZE) * (300 / 144));
    margin-bottom: calc(var(--ROOTFONTFIZE) * (30 / 144));
    /* background-color: #1a1c21; */
    background-color: var(--charts_bgc);
    border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
    /* border: 0.2px solid #35373b; */
    border: 1px solid var(--charts_brc);
    /* margin-left: 20px; */
}

.h400 {
    height: 400px !important;
}

.main_item_nothing {
    background-color: transparent !important;
    border: none !important;
}

.card .main .main_item:nth-child(n + 4) {
    height: calc(var(--ROOTFONTFIZE) * (420 / 144));
}

.main_item_first {
    margin: 0 calc(var(--ROOTFONTFIZE) * (20 / 144));
    padding-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
    border-bottom: calc(var(--ROOTFONTFIZE) * (2 / 144)) dotted rgba(118, 118, 118, 0.48);
}

.main_item_second {
    margin: 0 calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.main_item_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
    font-weight: 600;
    letter-spacing: 0px;
    line-height: calc(var(--ROOTFONTFIZE) * (18.96 / 144));
    color: var(--legndAreaItem_color);
    margin-top: calc(var(--ROOTFONTFIZE) * (15 / 144));
    margin-bottom: calc(var(--ROOTFONTFIZE) * (5 / 144));
    padding: 0 calc(var(--ROOTFONTFIZE) * (10 / 144));
}

.main_item_top div span {
    /* color: rgba(132, 131, 222, 1); */
    /* color: #6101ea; */
    /* color: #5ae8ed; */
    color: var(--GMREITs);
}

.CQL_color {
    color: var(--CQLREITs) !important;
}

.JYQL_color {
    color: var(--JYQLREITs) !important;
}

.WLCH_color {
    color: var(--WLCC) !important;
}

.CYY_color {
    color: var(--CYY) !important;
}

.BZXZF_color {
    color: var(--BZXZF) !important;
}

.XF_color {
    color: var(--XF) !important;
}

.SZST_color {
    color: var(--SZST) !important;
}

.NY_color {
    color: var(--NY) !important;
}

.SFGL_color {
    color: var(--SFGL) !important;
}

.main_item_tit {
    margin-top: calc(var(--ROOTFONTFIZE) * (10 / 144));
    margin-left: calc(var(--ROOTFONTFIZE) * (10 / 144));
    font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
    font-weight: 600;
    letter-spacing: 0px;
    line-height: calc(var(--ROOTFONTFIZE) * (18.96 / 144));
    color: var(--chart_title);
}

.card_top {
    display: flex;
    justify-content: space-between;
    padding: 0 calc(var(--ROOTFONTFIZE) * (25 / 144));
}

.card_top span {
    display: flex;
    align-items: center;
}

.mr20 {
    margin-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.ml20 {
    margin-left: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.card_bottom {
    padding: calc(var(--ROOTFONTFIZE) * (20 / 144));
    padding-bottom: 0 !important;
}

.card_history_top {
    display: flex;
    padding-left: calc(var(--ROOTFONTFIZE) * (25 / 144));
    margin-top: calc(var(--ROOTFONTFIZE) * (30 / 144));
    align-items: center;
}

.pl {
    padding-left: 0 !important;
}

.card_history_top div {
    color: rgba(255, 255, 255, 1);
}

.valuationCard {
    height: calc(var(--ROOTFONTFIZE) * (920 / 144));
}

.historymain {
    height: calc(var(--ROOTFONTFIZE) * (530 / 144));
    max-height: calc(var(--ROOTFONTFIZE) * (530 / 144));
    border-radius: calc(var(--ROOTFONTFIZE) * (10 / 144));
    /* background-color: #1a1c21;
    border: 0.2px solid rgba(135, 135, 135, 0.3); */
    background-color: var(--charts_bgc);
    border: 1px solid var(--charts_brc);
    margin: 0 calc(var(--ROOTFONTFIZE) * (25 / 144));
    margin-top: calc(var(--ROOTFONTFIZE) * (35 / 144));
    margin-bottom: calc(var(--ROOTFONTFIZE) * (10 / 144));
}

.abslouteMain {
    height: calc(var(--ROOTFONTFIZE) * (365 / 144));
    border-radius: calc(var(--ROOTFONTFIZE) * (10 / 144));
    background-color: #1a1c21;
    border: 0.2px solid rgba(135, 135, 135, 0.3);
    margin: calc(var(--ROOTFONTFIZE) * (38 / 144)) calc(var(--ROOTFONTFIZE) * (45 / 144)) calc(var(--ROOTFONTFIZE) * (50 / 144));
}

.abslouteMainUp {
    padding-bottom: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.abslouteChecked {
    margin: 0 calc(var(--ROOTFONTFIZE) * (50 / 144));
}

.abslouteChecked_span {
    color: var(--stock_area_item_color1);
}

.HistoricalValuation {
    padding-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.HistoricalValuationMain {
    padding-top: 0px !important;
}

.HistoricalValuation_info {
    padding-left: calc(var(--ROOTFONTFIZE) * (20 / 144));
    margin-top: calc(var(--ROOTFONTFIZE) * (10 / 144)) !important;
    padding-bottom: calc(var(--ROOTFONTFIZE) * (50 / 144)) !important;
    font-size: calc(var(--ROOTFONTFIZE) * (12 / 144));
    font-weight: 400;
    letter-spacing: 0px;
    line-height: calc(var(--ROOTFONTFIZE) * (17.38 / 144));
    color: var(--stock_area_item_color2);
}

.scatterArea {
    display: flex;
    gap: calc(var(--ROOTFONTFIZE) * (30 / 144));
    padding-top: calc(var(--ROOTFONTFIZE) * (20 / 144)) !important;
}

.scatterArea div:nth-child(1) {
    flex: 3;
    /* margin-right: calc(var(--ROOTFONTFIZE) * (30 / 144)); */
    border-radius: calc(var(--ROOTFONTFIZE) * (10 / 144));
    background-color: var(--charts_bgc);
    border: 0.2px solid var(--charts_brc);
}

.scatterArea div:nth-child(2) {
    flex: 7;
    border-radius: calc(var(--ROOTFONTFIZE) * (10 / 144));
    background-color: var(--charts_bgc);
    border: 0.2px solid var(--charts_brc);
}

.el-tabs {
    background-color: var(--bigger-card-bgc);
    /* padding-left: calc(var(--ROOTFONTFIZE) * (13 / 144)); */
}

::v-deep .el-tabs__item {
    color: var(--el_tabs_item_color);
    font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
    font-weight: 400;
    letter-spacing: 0.1px;
}

::v-deep .el-tabs__item:hover {
    color: var(--el_tabs_item_color_active_hover) !important;
}

::v-deep .el-tabs__item.is-active {
    color: var(--el_tabs_item_color_active) !important;
    font-size: calc(var(--ROOTFONTFIZE) * (18 / 144));
}

/*去掉tabs底部的下划线*/
::v-deep .el-tabs__nav-wrap::after {
    position: static !important;
}

/*去掉切换时el-tab-pane底部的蓝色下划线*/
::v-deep .el-tabs__active-bar {
    /* background-color: #1573fe !important; */
    background-color: var(--primary-color) !important;
}

/* el-table start */
::v-deep .table1 table {
    border: none;
}

::v-deep .el-table::before {
    background-color: var(--table_bgc) !important;
}

::v-deep .el-table__body .el-table__row .el-table__cell {
    background-color: var(--table_bgc);
    color: var(--table_color);
    font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
    height: calc(var(--ROOTFONTFIZE) * (60 / 144));
    border-bottom: 1px solid var(--table_brc);
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
    background-color: var(--table_bgc_hover);
    color: var(--table_color_hover);
    cursor: pointer;
}

::v-deep .el-table {
    border-bottom: none !important;
    background-color: var(--table_bgc) !important;
    padding-bottom: 90px;
}

::v-deep .el-table__body-wrapper {
    height: auto !important;
    max-height: 100% !important;
    padding-bottom: 10px !important;
}


::v-deep .el-tab-pane {
    display: block;
    width: 100%;
    height: var(--TABPANEHEIGHT);
    overflow-y: auto;
    box-sizing: border-box;
    padding-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.value_area {
    display: flex;
    align-items: center;
    justify-content: start;
}

.a {
    height: calc(var(--ROOTFONTFIZE) * (20 / 144));
    border-radius: calc(var(--ROOTFONTFIZE) * (3 / 144));
    margin-right: calc(var(--ROOTFONTFIZE) * (10 / 144));
    background-color: #4ec0c5;
}

.b {
    height: calc(var(--ROOTFONTFIZE) * (20 / 144));
    border-radius: calc(var(--ROOTFONTFIZE) * (3 / 144));
    margin-right: calc(var(--ROOTFONTFIZE) * (10 / 144));
    background-color: #4ec0c5;
}

.c {
    height: calc(var(--ROOTFONTFIZE) * (20 / 144));
    border-radius: calc(var(--ROOTFONTFIZE) * (3 / 144));
    margin-right: calc(var(--ROOTFONTFIZE) * (10 / 144));
    background-color: #5387be;
}

.d {
    height: calc(var(--ROOTFONTFIZE) * (20 / 144));
    border-radius: calc(var(--ROOTFONTFIZE) * (3 / 144));
    margin-right: calc(var(--ROOTFONTFIZE) * (10 / 144));
    background-color: #526cbf;
}

.e {
    height: calc(var(--ROOTFONTFIZE) * (20 / 144));
    border-radius: calc(var(--ROOTFONTFIZE) * (3 / 144));
    margin-right: calc(var(--ROOTFONTFIZE) * (10 / 144));
    background-color: #5150bf;
}

.canvasArea {
    /* background-color: pink !important; */
}

.historymain2 {
    height: calc(var(--ROOTFONTFIZE) * (620 / 144));
}

.DDLINFO {
    margin-left: calc(var(--ROOTFONTFIZE) * (16 / 144));
    margin-bottom: calc(var(--ROOTFONTFIZE) * (16 / 144));
    color: rgba(158, 158, 158, 1);
}

.main_item_bottom {
    margin-top: calc(var(--ROOTFONTFIZE) * (10 / 144));
    text-align: right;
    padding-right: calc(var(--ROOTFONTFIZE) * (50 / 144));
    color: #5ae8ed;
}

.main_item_bottom:hover {
    cursor: pointer;
}

.summary_style {
    display: flex;
    color: var(--stock_area_item_color1);
}

.noVip {
    font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
    letter-spacing: calc(var(--ROOTFONTFIZE) * (2 / 144));
    color: #78787a;
    color: #bababb;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(var(--ROOTFONTFIZE) * (500 / 144));
    border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
    background-color: #373a42;
    margin: calc(var(--ROOTFONTFIZE) * (20 / 144)) calc(var(--ROOTFONTFIZE) * (30 / 144));
    border: 1px solid #333335;
}

.display_none {
    display: none;
}

.icon-taocan-suoxiao {
    font-size: calc(var(--ROOTFONTFIZE) * (50 / 144));
    margin-bottom: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.echarts_area {
    position: relative;
    padding: 0 !important;
    overflow: hidden;
}
</style>