<template>
    <div class="container">
        <slot></slot>
    </div>
</template>


<script>

export default {
    // 从父组件传值的
    props:{
        // imgSrc:{
        //     type:String,
        //     default:""
        
    },
    methods:{
       
    },
    components:{
      
    },
    computed:{
       
    },
    data(){
        return {
           
        }

    }
}
</script>

<style lang="less"  scoped>
    .container{
          font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.25px;
            line-height: 20px;
            color: rgba(255, 255, 255, 1);
            margin-left: 18px;
    }

    .container span .el-radio-group {
         margin-left: 22px;
    }





</style>