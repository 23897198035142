<template>
  <div class="CH_Card profit_card profit_card3">
    <div class="CH_Card_tit">管理费人费用构成及占比</div>
    <div class="CH_Card_content" v-show="isVip">
      <span>
        显示类型
        <CButtonsSeparate
          @Cbtns_sep="fnGetCbtnsSep"
          class="CButtonsSeparate_style"
          :dButtons1="['大类', '个股']"
          :disabled="false"
          :dButtons1_current="select_type" />
      </span>
      <CCascader
        v-show="select_type === '大类'"
        key="大类"
        @CCascader_select_val="fnCCascader_select_val_chart3"
        :default="da_lei_default_value"
        :checkStrictly="false"
        :multiple="true"
        :collapsetags="true"
        :options="category_cascader_options"
        >选择类别
      </CCascader>
      <CCascader
        v-show="select_type === '个股'"
        key="个股"
        class="chart3_cascasder"
        :checkStrictly="false"
        :multiple="true"
        :isDelete="false"
        :default="reit_code_default"
        placeholder="请选择个股"
        :collapsetags="true"
        @CCascader_select_val="fnSelect_select_val_chart1_codename"
        :options="CODENAMEDATA">
        选择对比REITs
      </CCascader>
      <CSelect
        class="chart3_cascasder"
        @CSelect_select_val="change4"
        :options="options4"
        :clearable="false"
        :mini="true"
        :default="rp_type">
        财报类型</CSelect
      >
      <CSelect
        class="chart3_cascasder"
        @CSelect_select_val="change5"
        :options="options5"
        :clearable="false"
        :mini="true"
        :default="rp_period_start">
        选择报告期</CSelect
      >
      <span style="margin: 0 10px">to</span>
      <CSelect
        style="margin-left: -20px"
        @CSelect_select_val="change6"
        :options="options6"
        :clearable="false"
        :mini="true"
        :default="rp_period_end">
      </CSelect>
      <div class="downloadBtns">
        <CButtonsDownload
          :dButtonsDownloads="dButtonsDownloads"
          @fnButtondownload="fnButtonDL6_1" />
        <span style="display: none">
          <download-excel
            id="downloadExcel_mgt"
            :data="echart_data"
            :fields="json_fields"
            :name="overviewName">
            <i class="el-icon-download"></i>
          </download-excel>
        </span>
      </div>
    </div>
    <div class="CH_Card_content" v-show="isVip">
      <CSelect
        @CSelect_select_val="change7"
        :options="options7"
        :clearable="false"
        :mini="true"
        :default="fenzi">
        占比分子选择</CSelect
      >
      <CSelect
        class="chart3_cascasder"
        @CSelect_select_val="change8"
        :options="options8"
        :clearable="false"
        :mini="true"
        :default="fenmu">
        占比分母选择</CSelect
      >
    </div>
    <div class="CH_Card_content" v-show="isVip">
      <span @click="fnOpenDialog" class="iconfont icon-fangda icon-fangda2"></span>
      <div
        class="canvasArea1"
        style="width: 100%; height: 560px"
        ref="mgtRef"
        v-loading="loading"
        element-loading-text="数据量巨大，正在计算中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 1)"></div>
    </div>
    <div v-show="!isVip" class="noVip">
      <i class="iconfont icon-taocan-suoxiao"></i>
      抱歉，当前内容只能VIP客户查看
      <el-button size="small" class="openBtn">联系管理员开通</el-button>
    </div>
    <CDialog
      id="dialog1"
      ref="CDialog"
      :DIALOGHEIGHT="height_dialog"
      :dialogVisible="dialogVisible">
      <el-dialog
        title="葱花投研"
        :visible.sync="dialogVisible"
        :width="width_dialog"
        :top="top_dialog">
        <div>
          <el-dropdown size="mini" trigger="click" type="primary" @command="fnDropdown">
            <span class="el-dropdown-link">
              {{ popUpWindowDatas[popUpWindowIndex]
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="idx" v-for="(node, idx) in popUpWindowDatas" :key="idx">
                {{ node }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div :style="{ height: height_chart }">
          <div ref="mgtDialogRef" style="width: 100%; height: 100%"></div>
          <!-- <div class="Yield_Style">年化收益率</div> -->
          <!-- <div class="Volatility_Style">年化波动率</div> -->
        </div>
      </el-dialog>
    </CDialog>
  </div>
</template>

<script>
import CButtonsDownload from '@/components/Basic/CButtons_download';
import CCascader from '@/components/Basic/CCascader';
import CSelect from '@/components/Basic/CSelect';
import CButtonsSeparate from '@/components/Basic/CButtons_separate';
import CDialog from '@/components/Basic/CDialog';

import { debounce, codesToNameMap2, categroyDefaultValue } from '../../utils';
import { fnDownloadBgc, fnGetLevelName } from '../../utils/util';

import html2canvas from 'html2canvas';

export default {
  name: 'MGT',
  components: { CButtonsDownload, CCascader, CSelect, CButtonsSeparate, CDialog },
  data() {
    const CATEGORYDATA = JSON.parse(localStorage.getItem('CATEGORYDATA')) || [];
    const REITsNAME = JSON.parse(localStorage.getItem('REITsNAME')) || [];
    const da_lei_default_value = categroyDefaultValue(CATEGORYDATA);
    return {
      isVip: false,
      //
      dialogVisible: false,
      width_dialog: '80%',
      height_dialog: '73vh',
      height_chart: '72vh',
      popUpWindowDatas: this.$store.state.popUpWindowDatas,
      popUpWindowIndex: 0,
      top_dialog: '5vh',
      //
      /* codes_name_map: Object.freeze(
        codesToNameMap(JSON.parse(localStorage.getItem('REITsNAME')) || [])
      ), */
      overviewName: '管理费人费用构成及占比',
      json_fields: {},
      loading: true,
      echart_data: [],
      // 显示类型
      select_type: '大类',
      dButtonsDownloads: [
        {
          id: '241020',
          name: '下载图片',
        },
        {
          id: '241020',
          name: '下载表格',
        },
      ],
      sCurrentTheme: this.$store.state.theme == 'dark' ? true : false,
      // 选择类别
      da_lei_default_value: Object.freeze(da_lei_default_value),
      category_cascader_val_chart3: [...new Set(da_lei_default_value.flat(1))],
      category_cascader_options: Object.freeze(CATEGORYDATA),
      CODENAMEDATA: Object.freeze(REITsNAME),
      reit_code_default: codesToNameMap2(REITsNAME),
      reit_code: [],
      options4: Object.freeze([
        { label: '季报', value: 'quarterly' },
        { label: '中报', value: 'midterm' },
        { label: '年报', value: 'annual' },
      ]),
      rp_type: 'quarterly',
      // 财报时间范围筛选条件
      period: Object.freeze(
        JSON.parse(localStorage.getItem('period2')) || { quarterly: [], midterm: [], annual: [] }
      ),
      /*    period: {
        quarterly: [
          '2024Q3',
          '2024Q2',
          '2024Q1',
          '2023Q4',
          '2023Q3',
          '2023Q2',
          '2023Q1',
          '2022Q4',
          '2022Q3',
          '2022Q2',
          '2022Q1',
          '2021Q4',
        ],
        midterm: ['2024H1', '2023H2', '2023H1', '2022H2', '2022H1'],
        annual: ['2024A', '2023A', '2022A'],
      }, */
      // 开始时间
      options5: [],
      rp_period_start: '',
      // 结束时间
      options6: [],
      rp_period_end: '',
      // 分子
      options7: Object.freeze([
        { label: '管理费类型', value: 'fee_type' },
        { label: '管理人类型', value: 'manager_type' },
      ]),
      fenzi: 'fee_type',
      // 分母
      options8: Object.freeze([
        { label: '总收入', value: 'revenue' },
        { label: '基金净值', value: 'nav' },
        { label: '资产估值', value: 'asset_valuation' },
      ]),
      fenmu: 'revenue',
      ROOTFONTFIZE: '',
    };
  },
  computed: {
    currentTheme() {
      return this.$store.state.theme; // 从Vuex中获取当前的主题值
    },
  },
  watch: {
    currentTheme(newVal, oldVal) {
      this.fnGetTheme();
      this.renderEchart(this.echart_data);
    },
  },
  created() {
    this.isVip = fnGetLevelName() === 'VIP客户';
    // 颜色数组 不正确的数据结构浪费时间和性能
    const colors = this.$store.state.colors || [];
    this.colors = colors.reduce((p, c) => {
      p[c.name] = c.value;
      return p;
    }, {});
  },
  mounted() {
    this.resize();
    // 个股默认值（组件封装太垃圾，手动处理）
    this.reit_code = this.reit_code_default
      .map((item) => {
        return item.find((n) => n.indexOf('.') >= 0);
      })
      .filter((item) => item);
    this.change4(this.rp_type);
    window.addEventListener('resize', this.resize);
  },
  beforeDestroy() {
    this.echart_instance && this.echart_instance.dispose();
    this.echart_dialog_instance && this.echart_dialog_instance.dispose();
    window.removeEventListener('resize', this.resize);
  },
  methods: {
    fnOpenDialog() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.renderEchart(this.echart_data, true);
      });
    },
    fnDropdown(val) {
      let conf = this.$store.state.popUpWindowConfi[val];
      this.width_dialog = conf.width_dialog;
      this.top_dialog = conf.top_dialog;
      this.height_dialog = conf.height_dialog;
      this.popUpWindowIndex = val;
      this.height_chart = conf.height_chart;
      this.$nextTick(() => {
        this.renderEchart(this.echart_data, true);
      });
    },
    fnGetTheme() {
      this.sCurrentTheme = this.$store.state.theme == 'dark' ? true : false;
    },
    resize() {
      this.fnGetWidth();
      this.echart_instance && this.echart_instance.resize();
    },
    fnGetWidth() {
      var element = document.getElementById('rootchart');
      let ROOTFONTFIZE = element.offsetWidth;
      ROOTFONTFIZE = ROOTFONTFIZE > 1440 ? 1440 : ROOTFONTFIZE;
      ROOTFONTFIZE = ROOTFONTFIZE < 1400 ? ROOTFONTFIZE - 200 : ROOTFONTFIZE;
      this.ROOTFONTFIZE = ROOTFONTFIZE / 10;

      window.sessionStorage.setItem('ROOTFONTFIZE', this.ROOTFONTFIZE);
    },
    fnButtonDL6_1(val) {
      if (val == '下载图片') {
        this.fnButtonDL6_4_1();
      } else {
        setTimeout(() => {
          document.getElementById('downloadExcel_mgt').click();
        }, 0);
      }
    },
    //下载图片
    fnButtonDL6_4_1() {
      let h = this.$refs.mgtRef.scrollHeight;
      let w = this.$refs.mgtRef.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.mgtRef, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL('image/png');
        var a = document.createElement('a'); // 生成一个a元素
        var event = new MouseEvent('click'); // 创建一个单击事件
        a.download = '管理人费用分析'; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },
    // 表格数据
    tabelData() {
      const is_fee_type = this.fenzi != 'fee_type';
      const is_single = this.select_type === '个股';
      const base_meta = {};
      if (is_single) {
        base_meta['类型1'] = 'category_1';
        base_meta['类型2'] = 'category_2';
        base_meta['名称'] = 'name';
      } else {
        base_meta['类型'] = 'index';
      }
      const json_fields_1 = {
        固定管理费: 'mgt_fee_fix',
        浮动管理费: 'mgt_fee_float',
        托管费: 'reits_custody_fee',
      };
      const json_fields_2 = {
        基金管理人管理费: 'fm_pfm_fee',
        计划管理人管理费: 'pm_pfm_fee',
        外部管理机构管理费: 'eamf_fee_total',
        托管费: 'implied_custody_fee',
      };
      this.json_fields = Object.assign(base_meta, json_fields_1);
      if (is_fee_type) {
        this.json_fields = Object.assign(base_meta, json_fields_2);
      }
    },
    // 选择类别
    fnCCascader_select_val_chart3(val) {
      this.category_cascader_val_chart3 = val;
      this.loadingData();
    },
    // 选择个股
    fnSelect_select_val_chart1_codename(val) {
      val = val.filter((node) => {
        return node.includes('.');
      });

      this.reit_code = val;
      this.loadingData();
    },
    // 选择财报类型
    change4(val) {
      this.rp_type = val;
      const filter_list = this.period[val]
        .map((item) => ({
          label: item,
          value: item,
        }))
        .reverse();
      this.options5 = filter_list;
      this.options6 = filter_list;
      if (filter_list.length > 6) {
        this.rp_period_start = filter_list[5].value;
      } else {
        this.rp_period_start = filter_list[filter_list.length - 1].value;
      }
      this.rp_period_end = filter_list[0].value;
      this.loadingData();
    },
    // 选择开始时间
    change5(val) {
      this.rp_period_start = val;
      this.loadingData();
    },
    // 选择结束时间
    change6(val) {
      this.rp_period_end = val;
      this.loadingData();
    },
    // 选择分子
    change7(val) {
      this.fenzi = val;
      this.loadingData();
    },
    // 选择分母
    change8(val) {
      this.fenmu = val;
      this.loadingData();
    },
    // 显示类型
    fnGetCbtnsSep(val) {
      this.select_type = val;
      this.loadingData();
    },
    loadingData: debounce(function () {
      this.$nextTick(this._loadingData);
    }, 300),
    async _loadingData() {
      this.loading = true;
      if (!this.isVip) return;
      const is_single = this.select_type === '个股';
      const codes = !is_single ? '' : this.reit_code.join('&codes=');
      const r_type = is_single ? '' : this.category_cascader_val_chart3.join('&r_type=');
      const queryString = new URLSearchParams({
        cal_function: is_single ? 'single' : 'type',
        rp_type: this.rp_type,
        rp_period_start: this.rp_period_start || '2023Q1',
        rp_period_end: this.rp_period_end || '2023Q2',
        fenzi: this.fenzi,
        fenmu: this.fenmu,
      }).toString();
      const { data } = await this.$https.get(
        '/api/v2/get_mgt_fee_data?' + queryString + '&r_type=' + r_type + '&codes=' + codes
      );
      this.loading = false;
      if (data.code !== 200) return this.$message.error(data.msg);
      // 过滤3项都是0的数据
      if (this.fenzi == 'fee_type') {
        data.data = data.data.filter(
          ({ reits_custody_fee, mgt_fee_float, mgt_fee_fix }) =>
            reits_custody_fee + mgt_fee_float + mgt_fee_fix > 0
        );
      } else {
        //获取人类型
        data.data = data.data.filter(
          ({ fm_pfm_fee, pm_pfm_fee, eamf_fee_total, implied_custody_fee }) =>
            fm_pfm_fee + pm_pfm_fee + eamf_fee_total + implied_custody_fee > 0
        );
      }

      // 排序 大=>小
      data.data.sort((a, b) => b.total - a.total);
      const echart_data = data.data;
      this.echart_data = echart_data;
      this.$nextTick(() => {
        this.tabelData();
      });
      this.renderEchart(echart_data);
    },
    //重绘图表
    renderEchart(echart_data, flag) {
      if (!echart_data.length) return;

      if (flag) {
        if (!this.echart_dialog_instance) {
          this.echart_dialog_instance = this.$echarts.init(this.$refs.mgtDialogRef);
        }
        this.echart_dialog_instance.clear();
      }
      if (!this.echart_instance) {
        this.echart_instance = this.$echarts.init(this.$refs.mgtRef);
      }
      !flag && this.echart_instance.clear();

      let OPTIONDATA =
        this.$store.state.theme == 'dark'
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;
      const theme = this.$store.state.theme;

      let series_map = ['mgt_fee_fix', 'mgt_fee_float', 'reits_custody_fee'];
      let seriesName_map = {
        mgt_fee_fix: '固定管理费',
        mgt_fee_float: '浮动管理费',
        reits_custody_fee: '托管费',
      };
      if (this.fenzi != 'fee_type') {
        series_map = ['fm_pfm_fee', 'pm_pfm_fee', 'eamf_fee_total', 'implied_custody_fee'];
        seriesName_map = {
          fm_pfm_fee: '基金管理人管理费',
          pm_pfm_fee: '计划管理人管理费',
          eamf_fee_total: '外部管理机构管理费',
          implied_custody_fee: '托管费',
        };
      }
      const color1 = ['#61bae3', '#608fe5', '#5e63e6', '#3151d3'];
      const color2 = color1;

      const item_length = (echart_data || []).length || 20;
      const font_r = item_length > 20 ? 20 / item_length : 1;

      const options = {
        legend: {
          type: 'plain',
          left: 'center',
          top: this.ROOTFONTFIZE * (10 / 144),
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
          },
          formatter: (name) => {
            return seriesName_map[name];
          },
        },
        tooltip: {
          trigger: 'axis',
          confine: true,
          triggerOn: 'mousemove|click',
          formatter: (items) => {
            const name = items[0].name;
            const v = items
              .map(
                ({ seriesName, value, marker }) =>
                  `${marker}${
                    seriesName_map[seriesName]
                  } <span style="margin-right:4px">:</span> ${(value * 100).toFixed(3)}%`
              )
              .join('<br/>');
            return `${name}<br/>${v}`;
          },
        },
        xAxis: {
          data: echart_data.map((item, index) => item.index || item.name),
          axisLabel: {
            interval: 0,
            rotate: 35,
            margin: OPTIONDATA.xAxis.axisLabel.margin,
            // fontSize: OPTIONDATA.xAxis.axisLabel.fontSize,
            fontSize: OPTIONDATA.xAxis.axisLabel.fontSize * (flag ? font_r : 1),
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            show: true,
            interval: 'auto',
            formatter: (value) => {
              return (value * 100).toFixed(2) + '%';
            },
            fontSize: OPTIONDATA.yAxis.axisLabel.fontSize,
            color: OPTIONDATA.yAxis.axisLabel.color,
          },
          splitLine: OPTIONDATA.yAxis.splitLine,
        },
        series: series_map.map((k, index) => {
          const data = echart_data.map((item) => item[k]);
          return {
            type: 'bar',
            stack: 'all',
            data,
            name: k,
            barMinHeight: 16,
            z: index + 1,
            color: (this.fenzi != 'fee_type' ? color2 : color1)[index],
          };
        }),
        grid: {
          top: this.ROOTFONTFIZE * (60 / 144),
          left: this.ROOTFONTFIZE * (35 / 144),
          right: this.ROOTFONTFIZE * (60 / 144),
          bottom: this.ROOTFONTFIZE * (20 / 144),
          containLabel: true,
        },
      };
      if (flag) {
        this.echart_dialog_instance.setOption(options);
        this.echart_dialog_instance.resize();
        return;
      }
      this.echart_instance.setOption(options);
      this.echart_instance.resize();
    },
  },
};
</script>

<style lang="less" scoped>
.openBtn {
  background-color: var(--primary-color) !important;
  color: #333333 !important;
  border: none !important;
  margin-top: calc(var(--ROOTFONTFIZE) * (20 / 144));
}
.icon-taocan-suoxiao {
  font-size: calc(var(--ROOTFONTFIZE) * (50 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (30 / 144));
}
.noVip {
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  letter-spacing: calc(var(--ROOTFONTFIZE) * (2 / 144));
  color: #78787a;
  color: #bababb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(var(--ROOTFONTFIZE) * (500 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  background-color: #373a42;
  margin: calc(var(--ROOTFONTFIZE) * (20 / 144)) calc(var(--ROOTFONTFIZE) * (30 / 144));
  border: 1px solid #333335;
}

.downloadBtns {
  margin-right: 0;
  margin-left: auto;
}

.profit_card3 {
  height: calc(var(--ROOTFONTFIZE) * (740 / 144)) !important;
}

.canvasArea1 {
  width: 100%;

  // background-color: #1a1c21;
  // border: 1px solid #4f5053;
  background-color: var(--charts_bgc);
  border: 1px solid var(--charts_brc);
  border-radius: calc(var(--ROOTFONTFIZE) * (10 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  position: relative;
  // margin-left:20px;
  // margin-right:20px;
}

.CButtonsSeparate_style {
  // margin-left: calc(var(--ROOTFONTFIZE) * (20 / 144));
  margin-left: 20px;
}

.CH_Card_tit {
  font-size: calc(var(--ROOTFONTFIZE) * (20 / 144));
  font-weight: 700;
  letter-spacing: 0px;
  line-height: calc(var(--ROOTFONTFIZE) * (28 / 144));
  color: var(--chart_title);
  // border-left: 5px solid rgba(21, 115, 254, 1);
  border-left: calc(var(--ROOTFONTFIZE) * (5 / 144)) solid var(--primary-color);
  padding-right: calc(var(--ROOTFONTFIZE) * (20 / 144));

  padding-left: calc(var(--ROOTFONTFIZE) * (13 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  display: flex;
  justify-content: space-between;
}

.CH_Card_content {
  display: flex;
  align-items: center;
  margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding: 0 calc(var(--ROOTFONTFIZE) * (20 / 144));
  position: relative;
}

.CH_Card_content span {
  display: flex;
  align-items: center;
  color: var(--stock_area_item_color1);
  margin-right: calc(var(--ROOTFONTFIZE) * (30 / 144));
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
}

.chart3_cascasder {
  margin-left: calc(var(--ROOTFONTFIZE) * (30 / 144));
}
</style>
