<template>
  <div class="container" :class="theme?'dark':''">
    <slot></slot>
    <el-date-picker
      popper-class="last-check"
      v-model="value"
      style="width: 100%"
      type="date"
      placeholder="选择日期"
      value-format="yyyy-MM-dd"
      @change="change">
    </el-date-picker>
  </div>
</template>

<script>

export default {
  // 从父组件传值的
  props: {
    date: String,
  },
  watch: {
    date(val) {
      this.value = val;
    },
    currentTheme(newVal, oldVal) {
      this.styleBindFun();
    },
  },
  methods: {
    change(val) {
      if(!val){
        val = '';
      }
      this.$emit('update:date', val);
      this.$nextTick(() => {
        this.$emit('fnGetBlockTradingDetail');
      });
    },
    styleBindFun() {
      let val = this.$store.state.theme == 'dark' ? true : false;
      this.theme = val;
    },
  },
  components: {},
  computed: {
    currentTheme() {
      return this.$store.state.theme; // 从Vuex中获取当前的主题值
    },
  },
  created() {
    this.styleBindFun();
  },
  data() {
    return {
      theme:false,
      value:'',
    };
  },
};
</script>

<style scoped lang="less">
.dark{
  ::v-deep .el-input__inner{
    background: #29292c;
    border:none;
  }
  ::v-deep .el-input__suffix-inner{
    color: #fff;
  }
}
</style>
