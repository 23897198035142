<template>
    <div class="container" :style="cssVars">
      <span>
        <slot></slot>
      </span>
    </div>
</template>


<script>

export default {
    // 从父组件传值的
    props:{
        // option:{
        //     type:Object,
        //     default:""
        // },
        DIALOGHEIGHT:{
            type:String,
            default: ''
        },
         dialogVisible:{
            type:Boolean,
            default:false
        },
    },
    methods:{
      //  fnButton(val){
      //   this.$emit('fnCloseDialog',false)
      //  },
       
    },
    components:{
      
    },
    // computed:{
    //   //   cssVars() {
    //   // return {
    //   //   "--ROOTFONTFIZE": this.ROOTFONTFIZE+'px',
    //   // };
    // },
    // },
    created(){
    //  this.ROOTFONTFIZE  = window.sessionStorage.getItem("ROOTFONTFIZE");


    return
       
    },  
    data(){
        return {
          ROOTFONTFIZE:'',
          chartColumn:null  ,
          option:{},
        }

    },
    computed:{
       cssVars() {
          return {
            "--DIALOGHEIGHT": this.DIALOGHEIGHT ,
          };
    },
    }
}
</script>

<style lang="less"  scoped>

.chart{
  padding-top: 0 !important;
}

 ::v-deep .el-dialog__header{
    background-color: var(--dialog_header_bgc);
  }

  ::v-deep .el-dialog__title{
    color: var(--dialog_header_title_color);
  }

  ::v-deep .el-dialog__close.el-icon{
    color: var(--dialog_header_icon_color) !important;
  }

  ::v-deep .el-dialog__close.el-icon:hover{
    color: var(--primary-color) !important;
  }

  ::v-deep .el-dialog__body{
    background-color: var(--dialog_body_bgc);
    // height: auto;
    // height: 73vh;
    height: var(--DIALOGHEIGHT);
    border-top: 1px solid var(--dialog_body_btc);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

  }

  ::v-deep .el-dialog__footer{
    background-color: var(--dialog_footer_bgc);

  }

  // .frontBtn{
  //   background-color: var(--primary-color);
  //   color: #333;
  // }

  // .frontBtn:hover{
  //   background-color: var(--primary-color);
  //   color: #fff;
  // }


  

</style>