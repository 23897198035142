<template>
<div>
  <CTimeButtons  :default="start_date" />
    <!-- <div  v-for="(item, index) in arr2" :key="index"
          :ref="'chartColumn'+index"
          style="width: 500px; height: 450px"
        ></div> -->
     
</div>

</template>

<script>

 import CSelects from '@/components/Basic/CSelects'
 import CCategoryRadio from '@/components/Basic/CCategoryRadio'
 import CTimeButtons from '@/components/Basic/CTimeButtons'




export default {  
  data() {
    return {
      
      start_date: "3M",

      chartColumn1:null,
      arr2:[1,2]
      

    };
  },
  created(){
       
  },
  mounted(){
    this.initChart1()
    // this.funResize();
  },
   components: {
    CTimeButtons,
  },
  methods:{
 
  
    initChart1() {
      console.log(this.chartColumn2,'chartColumn12');
      
      this.chartColumn1 = this.$echarts.init(this.$refs.chartColumn1);

     let  option = {
          xAxis: {
            type: 'category',
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              data: [150, 230, 224, 218, 135, 147, 260],
              type: 'line'
            }
          ]
        };   
     

      this.chartColumn1.setOption(option);
      this.chartColumn1.resize();
      // this.funResize()
    },


  }
  
};
</script>

<style lang="less" scoped>
.container {
}

.riskRelatedCard {
  background-color: rgba(29, 31, 37, 1);
  border-radius: 20px;
  padding-top: 15px;
  margin-bottom: 64px;
  height: 654px;
}

.riskRelatedCard_tit {
  padding-left: 18px;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 28px;
  color: rgba(255, 255, 255, 1);
  border-left: 5px solid rgba(21, 115, 254, 1);
}

.riskRelatedCard_ChooseArea {
}

.riskRelatedCard_ChooseArea_top {
  margin-top: 10px;
  margin-bottom: 30px;
}


 ::v-deep .riskRelatedCard_ChooseArea_bottom span .el-radio-group  .el-radio-button__inner {  
   background: transparent;
   color: #b0b1b3;
   border: 1px solid #494a50;
 }

 .realFluctuations{
    display: flex;
  align-items: center ;

 }

 .time{
  margin-top: 0px;
  height: 100%;
  margin-left: 90px;
}


//============================ echarts=========================
.chartColumn1_style{
  border: 1px solid #2a2c31;
  background-color: #1a1c21;
  margin-top: 45px;
  border-radius: 10px;
}

.riskRelatedCard_content{
  padding: 0 15px;
}


</style>
