<template>
  <div class="container" id="rootchart" :style="cssVars">
    <div class="CARD">
      <div class="CARD_tit">
        {{ STOCKNAME }} | <span class="CARD_tit_code">{{ CODE }}</span>
      </div>
      <div class="CARD_body">
        <div class="tabs_case">
          <CButtons
            @Cbtns="fnGetCbtns"
            class="CARD_body_tabs"
            :dButtons1="dButtons1"
            :defaultVal="dButtons1_current" />
        </div>
        <Property ref="property" v-if="dButtons1_current == '资产列表'" />
        <Operation ref="operation" v-if="dButtons1_current == '运营数据'" />
      </div>
    </div>
  </div>
</template>

<script>
import CButtons from '@/components/Basic/CButtons';

import Property from './property.vue';
import Operation from './operation/index.vue';

export default {
  data() {
    return {
      sCurrentTheme: this.$store.state.theme == 'dark' ? true : false,

      //================ new =================================
      dButtons1: ['资产列表', '运营数据'],
      dButtons1_current: '资产列表',
      CODE: '',

      ROOTFONTFIZE: 0,
      STOCKNAME: '',
    };
  },
  created() {},
  mounted() {
    this.fnGetWidth();
    window.addEventListener('resize', this.funResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.funResize);
  },
  methods: {
    fnGetTheme() {
      this.sCurrentTheme = this.$store.state.theme == 'dark' ? true : false;
    },
    funResize() {
      this.fnGetWidth();
    },

    fnGetWidth() {
      this.ROOTFONTFIZE = localStorage.getItem('ROOTFONTFIZE');
    },

    fnGetCbtns(data) {
      this.STOCKNAME = window.sessionStorage.getItem('CURRENTNAME');
      this.CODE = window.sessionStorage.getItem('CURRENTCODE');
      if (data == '资产列表') {
        this.dButtons1_current = '资产列表';
        this.$nextTick(() => {
          this.$refs.property.reload();
        });
      }

      if (data == '运营数据') {
        this.dButtons1_current = '运营数据';
        this.$nextTick(() => {
          this.$refs.operation.reload();
        });
      }
    },
  },
  components: {
    CButtons,
    Property,
    Operation,
  },
  computed: {
    cssVars() {
      return {
        '--ROOTFONTFIZE': this.ROOTFONTFIZE + 'px',
      };
    },
    currentTheme() {
      return this.$store.state.theme;
    },
  },
  watch: {
    currentTheme(newVal, oldVal) {
      this.fnGetTheme();
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  // background-color: pink;
  .CARD {
    border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
    background-color: rgba(29, 31, 37, 1);
    background-color: var(--charts_bgc);
    padding: calc(var(--ROOTFONTFIZE) * (15 / 144)) 0;
  }

  .CARD_tit {
    padding-left: calc(var(--ROOTFONTFIZE) * (22 / 144));
    // border-left: 5px solid rgba(21, 115, 254, 1);
    border-left: calc(var(--ROOTFONTFIZE) * (5 / 144)) solid var(--primary-color);
    font-size: calc(var(--ROOTFONTFIZE) * (22 / 144));
    font-weight: 700;
    letter-spacing: 0px;
    line-height: calc(var(--ROOTFONTFIZE) * (30 / 144));
    // height: 20px;
    color: var(--chart_title);
  }
  .CARD_body {
    padding: 0 calc(var(--ROOTFONTFIZE) * (20 / 144));
  }
}

.tabs_case {
  margin-top: calc(var(--ROOTFONTFIZE) * (30 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (10 / 144));
}
</style>
