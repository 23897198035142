<template>
  <div class="container">
        <slot></slot>
        <el-checkbox-group v-model="checked" @change="handleCheckedItemChange">
          <el-checkbox v-for="(item,index) in options" :label="item" :key="index">{{item}}</el-checkbox>
         </el-checkbox-group>    
         <el-checkbox v-show="isCheckAll" :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                                                 
  </div>
</template>


<script>
export default {
  // 从父组件传值的
  props: {
    // value:{
    //     type:String,
    //     default:""
    // },
    options: {
      type: Array,
      default: [],
    },
     isCheckAll: {
      type: Boolean,
      default: true,
    },
    // price:{
    //     type:String,
    //     default:""
    // },
    // marketPrice:{
    //     type:String,
    //     default:""
    // },
  },
  methods: {
    handleCheckedItemChange(val){
         let checkedCount = val.length;
         this.checkAll = checkedCount === this.options.length;
         this.isIndeterminate = checkedCount > 0 && checkedCount < this.options.length;
    },
    handleCheckAllChange(val){
       this.checked = val ? this.options : [];
       this.isIndeterminate = false;
    },

  },
  components: {},
  computed: {},
  data() {
    return {
      checked: "",
      checkAll:false,
      isIndeterminate:''
      // options: [
      //     {
      //     name: "下拉菜单",
      //     value: "0",
      //     },
      // ],
    };
  },
};
</script>

<style  scoped>
.container {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 20px;
  color: rgba(255, 255, 255, 1);
}

.el-checkbox-group{
  margin-left: 36px;
  display: flex;
  align-items: center;
}

.el-checkbox{
  margin-left: 22px;
    color: #a5a6a9;

}

.el-checkbox__input{
  display: flex;
}



</style>