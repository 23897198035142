<template>
  <div class="container" :style="cssVars">
    <span class="btnArea">
      <el-button
        v-for="(item, index) in dBtn1"
        :key="index"
        :class="item == activeBtn1 ? 'isActiveStyle' : ''"
        size="mini"
        plain
        @click="fnChangeBtn1(item)"
        >{{ item }}</el-button
      >
    </span>
  </div>
</template>

<script>
export default {
  // 从父组件传值的
  props: {
    // dButtons1:{
    //     type:Array,
    //     default:""
    // },

    default: {
      type: String,
      default: "",
    },
  },
  methods: {
    fnChangeBtn1(val) {
      //    if(val == 'MAX') val ='max'
      // console.log(val,'val1231');
      this.activeBtn1 = val;
      this.$emit("CotherButtons_select_val", val);
    },
  },
  components: {},
  computed: {
    cssVars() {
      return {
        "--ROOTFONTFIZE": this.ROOTFONTFIZE + "px",
      };
    },
  },
  created() {
    this.ROOTFONTFIZE = window.sessionStorage.getItem("ROOTFONTFIZE");
  },
  data() {
    return {
      dBtn1: ["1M", "3M", "6M", "1Y"],
      activeBtn1: this.default,
      ROOTFONTFIZE: "",
    };
  },
};
</script>

<style lang="less" scoped>
.container {
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0.25px;
  // line-height: calc(var(-  -ROOTFONTFIZE) * (20 / 144));
  color: rgba(255, 255, 255, 1);
  color: #ffffff;
  display: flex;
  align-items: center;
  // background-color: var(--Btn-bgc2);
  padding: calc(var(--ROOTFONTFIZE) * (10 / 144))
    calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding: 10px 20px;
  border-radius: calc(var(--ROOTFONTFIZE) * (8 / 144));
  width: 240px;
}

.el-button {
  // border: 1px solid #434343 !important;
  box-sizing: border-box !important;
  height: 35px;
  color: var(--Btn-color2) !important;


  border: 1px solid var(--Btn-borderColor) !important;
  background-color: var(--Btn-bgc2);
  color: var(--stock_area_item_color1) !important;

}

.btnArea {
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0.25px;
  display: flex;
  align-items: center;
}

.btnArea .el-button:hover {
  // background-color: transparent;
  // color: var(--Btn-color2) !important;
  // border: 1px solid var(--Btn-brc) !important;

  background-color: var(--Btn-bgc_hover);
  color: var(--Btn-color2) !important;
  border: 1px solid var(--primary-color) !important;


}

.btnArea .isActiveStyle {
  background-color: var(--primary-color) !important;
  color: var(--Btn-color) !important;
  border: 1px solid var(--primary-color) !important;

}

.btnArea .isActiveStyle:hover {
  color: var(--Btn-color) !important;
}
</style>
