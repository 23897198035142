<template>
  <div class="operation">
    <Highway v-if="asset_type === '收费公路'" :asset_name="asset_name" />
    <IndustrialPark v-if="asset_type === '产业园'" :asset_name="asset_name" />
  </div>
</template>

<script>
import Highway from './highway';
import IndustrialPark from './IndustrialPark';
export default {
  components: {
    Highway,
    IndustrialPark,
  },
  data() {
    return {
      ROOTFONTFIZE: 0,
      CODE: '',
      asset_name: [],
      asset_type: '',
    };
  },
  created() {
    this.reload();
    this.getAssetValues();
  },
  mounted() {
    this.fnGetWidth();
    window.addEventListener('resize', this.funResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.funResize);
  },
  methods: {
    async getAssetValues() {
      let code = this.CODE;

      const { data } = await this.$https.get('/api/asset_value_by_code?code=' + code);

      if (data.code !== 200) return this.$message.error(data.msg);
      const result = data.data || [];
      this.asset_name = [...new Set(result.map((item) => item['资产名称']))];
      this.asset_type = (result[0] || {})['资产类型'];
    },
    fnGetTheme() {
      this.sCurrentTheme = this.$store.state.theme == 'dark' ? true : false;
    },
    funResize() {
      this.fnGetWidth();
    },

    fnGetWidth() {
      this.ROOTFONTFIZE = localStorage.getItem('ROOTFONTFIZE');
    },

    reload() {
      this.CODE = window.sessionStorage.getItem('CURRENTCODE');
    },
  },

  computed: {
    cssVars() {
      return {
        '--ROOTFONTFIZE': this.ROOTFONTFIZE + 'px',
      };
    },
    currentTheme() {
      return this.$store.state.theme;
    },
  },
  watch: {
    currentTheme(newVal, oldVal) {
      this.fnGetTheme();
    },
  },
};
</script>

<style lang="less" scoped>
.operation {
}
</style>
