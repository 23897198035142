<template>
  <div class="container">
    <div>
    <slot></slot>
    </div>
    <div class="right">

    <el-select v-model="value" clearable placeholder="下拉菜单">
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>

    <div class="showArea">
        <el-tag
          v-for="(tag,index) in checkedData"
          :key="index"
          closable
          :disable-transitions="true"
          @close="handleClose(tag)"
          >
          {{tag}}
        </el-tag>
    </div>

    </div>
  </div>
</template>


<script>
export default {
  // 从父组件传值的
  props: {
    // value:{
    //     type:String,
    //     default:""
    // },
    options: {
      // type: Array,
      // default: [],
    },
    // price:{
    //     type:String,
    //     default:""
    // },
    // marketPrice:{
    //     type:String,
    //     default:""
    // },
  },
  methods: {
      handleClose(tag) {
        this.checkedData.splice(this.checkedData.indexOf(tag), 1);
      },
  },
  components: {},
  computed: {},
  data() {
    return {
      value: "",
      checkedData:['张江光大','中金普洛斯','国君东久']
      // options: [
      //     {
      //     name: "下拉菜单",
      //     value: "0",
      //     },
      // ],
    };
  },
};
</script>

<style  scoped>
.container {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 20px;
  color: rgba(255, 255, 255, 1);
  display: flex;
}

.container .right{
}
.el-select {
  margin-left: 27px;
  width: 285px;
  height: 32px;
  color: rgba(255, 255, 255, 1);
  border: 1px solid #949495;
  border-radius: 5px;
  padding-right: 10px;
  box-sizing: border-box;
}

::v-deep .el-select .el-input__inner {
  background-color: rgba(0, 0, 255, 0) !important;
  border: none;
  padding: 0 !important;
  margin: 0 !important;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.65);
}

::v-deep .el-input__icon {
  height: 32px;
  line-height: 32px;
}


.showArea{
  width: 285px;
  height: 87px;
  border: 1px solid #949495;
  border-radius: 3px;
  box-sizing: border-box;
  margin-left: 27px;
  margin-top: 7px;
  background-color: #1d1f25;
  padding: 8px 14px;
}

.el-tag{
  /* width: 57px; */
  height: 20px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: 20px;
  color: rgba(246, 246, 246, 0.8);
  background: rgba(42, 64, 55, 1);
  border: none;
  margin-right: 10px;
  margin-bottom: 10px;
}

::v-deep .el-tag__close{
  color: #fff !important;
}
</style>