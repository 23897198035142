<template>
  <div id="app">
    <router-view></router-view>
    <UpdateDialog v-bind.sync="update_dialog" />
  </div>
</template>

<script>
import UpdateDialog from './components/dialog/update.vue';

export default {
  name: 'app',
  components: { UpdateDialog },
  data() {
    return {
      update_dialog: { show: false, callback: () => {} },
    };
  },
  provide() {
    return {
      showUpdateDialog: this.showUpdateDialog,
    };
  },
  created() {
    let val = localStorage.getItem('Theme');
    this.$store.commit('setTheme', val);
  },
  //监听vuex中的主题
  computed: {
    currentTheme() {
      return this.$store.state.theme; // 从Vuex中获取当前的主题值
    },
  },
  mounted() {
    window.addEventListener('popstate', this.handleBackButton);
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.handleBackButton);
  },
  watch: {
    currentTheme(newVal, oldVal) {
      if (newVal == 'light') {
        document.body.classList.add('conghua-light');
      } else {
        document.body.classList.remove('conghua-light');
      }
    },
  },
  methods: {
    handleBackButton(val) {
      // console.log(val,'肥肥肥肥肥肥');
    },
    showUpdateDialog(show, callback) {
      this.update_dialog = {
        show,
        callback,
      };
    },
  },
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
}

#app {
}
html,
body {
  overflow: hidden; /* 禁用外层滚动条 */
}
</style>
