<template>
    <div class="container" :style="cssVars">
      <span>
        <el-button 
         v-for="(item, index) in dButtons1"
         :key="index"
         :disabled="disabled"
         :round="round"
          @click="fnButtons1(item)"
          :class="item == dButtons1_current ? 'checkedBtnStyle' : ''"
          class="noActive" size="mini" >
          {{item}}
          </el-button>
      </span>
    </div>
</template>


<script>

export default {
    // 从父组件传值的
    props:{
        dButtons1:{
            type:Array,
            default:""
        },
        dButtons1_current:{
            type:String,
            default:""
        },
         round:{
            type:Boolean,
            default:false
        },
        disabled:{
           type:Boolean,
           default:false
        }
    },
    methods:{
       fnButtons1(val){
        this.$emit('Cbtns_sep',val)
       }
    },
    components:{
      
    },
    computed:{
        cssVars() {
      return {
        "--ROOTFONTFIZE": this.ROOTFONTFIZE+'px',
      };
    },
    },
    created(){
    //  this.ROOTFONTFIZE  = window.sessionStorage.getItem("ROOTFONTFIZE");
        
    },
    data(){
        return {
          // ROOTFONTFIZE:''
            //  dButtons1:['概览','市值相关','流动性相关'],
            //  dButtons1_current:'概览',
        }

    }
}
</script>

<style lang="less"  scoped>
    
.container{   
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.25px;
    display: flex;
    align-items: center;
    margin-left: 20px;
}

span{
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

// 切换按钮  start --------------------------------------------

 .el-button {
    font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  height: 35px;
  box-sizing: border-box !important;
}

 .noActive {
  // background-color: var(--Btn-sep_bgc);
  // color: var(--Btn-sep_color);
  // border: 1px solid #4a4c51;

  background-color: var(--Btn-bgc2);
  color: var(--stock_area_item_color1) ;
  border: 1px solid var(--Btn-borderColor);
}

.checkedBtnStyle{
  background-color: var(--primary-color) !important;
  color: var(--Btn-color) !important;
  // border: 1px solid transparent;
  border: 1px solid var(--primary-color) !important;
  box-sizing: border-box;
}


 .el-button:hover {
  // background-color: var(--primary-color);
  // color: var(--Btn-color);
  // background-color: transparent;
  // color: var(--Btn-color2) !important;
  // border: 1px solid var(--Btn-brc) !important;
  background-color: var(--Btn-bgc_hover);
  color: var(--Btn-color2) ;
  border: 1px solid var(--primary-color) ;
}

 .el-button.is-disabled{
    background-color: var(--Btn-bgc2);
   color: var(--stock_area_item_color1) ;
    border: 1px solid var(--Btn-borderColor);
}


 .el-button:hover.is-disabled {
  // background-color: var(--primary-color);
  // color: var(--Btn-color);
  // background-color: transparent;
  // color: var(--Btn-color2) !important;
  // border: 1px solid var(--Btn-brc) !important;
  background-color: var(--Btn-bgc_hover);
  color: var(--Btn-color2) ;
  border: 1px solid var(--primary-color) ;
}



</style>