import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
  //状态值
  state: {
    lightOpacity: 0.8,
    IncomeAnalysisCasCaderData: [
      ['产权类', '物流仓储'],
      ['产权类', '产权类'],
      ['产权类', '产业园'],
      ['产权类', '保障性住房'],
      ['产权类', '消费'],
      ['经营权类', '经营权类'],
      ['经营权类', '市政生态'],
      ['经营权类', '能源'],
      ['经营权类', '收费公路'],
      ['公募REITs'],
    ],

    SPEColor: [
      'rgba(0, 175, 65, 1)',
      'rgba(102, 207, 141, 1)',
      'rgba(179, 231, 198, 1)',
      'rgba(255, 179, 179, 1)',
      'rgba(255, 102, 102, 1)',
      'rgba(255, 0, 0, 1)',
    ],

    popUpWindowDatas: ['样式一', '样式二', '样式三'],
    popUpWindowConfi: [
      {
        width_dialog: '80%',
        top_dialog: '5vh',
        height_dialog: '73vh',
        height_chart: '71vh',
      },
      {
        width_dialog: '50%',
        top_dialog: '5vh',
        height_dialog: '73vh',
        height_chart: '71vh',
      },
      {
        width_dialog: '80%',
        top_dialog: '15vh',
        height_dialog: '50vh',
        height_chart: '47vh',
      },
    ],
    theme: 'dark',
    Folder_L1: [
      {
        label: '定期公告',
        value: 'Periodic_Report',
      },
      {
        label: '募集发行',
        value: 'Issuance',
      },
      {
        label: '底层资产公告',
        value: 'REITs_Assets',
      },
      {
        label: '基金运营公告',
        value: 'Fund_Operation',
      },
      {
        label: '其他公告',
        value: 'Others',
      },
    ],
    Folder_L2: [
      {
        label: '季报',
        value: 'Quarterly_report',
      },
      {
        label: '年报',
        value: 'Annual_report',
      },

      {
        label: '中期报告',
        value: 'Midterm_report',
      },
      {
        label: 'IPO',
        value: 'IPO',
      },
      {
        label: 'IPO附件',
        value: 'IPO_appendix',
      },
      {
        label: '扩募',
        value: 'SO',
      },
      {
        label: '扩募附件',
        value: 'SO_appendix',
      },
      {
        label: '评估报告',
        value: 'Valuation_report',
      },
      {
        label: '运营数据',
        value: 'Operational_data',
      },
      {
        label: '收益分配公告',
        value: 'Div_notice',
      },
      {
        label: '限售及解除限售公告',
        value: 'Restrictions',
      },
      {
        label: '投资者关系活动公告',
        value: 'IR',
      },
      {
        label: '风险提示公告',
        value: 'Risk_Warning',
      },
      {
        label: '停牌/复牌公告',
        value: 'Suspension_Resumption',
      },
      {
        label: '增持及回购公告',
        value: 'Accumulation_Repurchase',
      },
      {
        label: '做市商调整公告',
        value: 'Market_Maker',
      },
      {
        label: '提示性公告',
        value: 'Informative_Notice',
      },
    ],

    Folder_L3: [
      {
        label: '定期公告',
        value: 'Periodic_Report',
        children: [
          {
            label: '季报',
            value: 'Quarterly_report',
          },
          {
            label: '年报',
            value: 'Annual_report',
          },
          {
            label: '中期报告',
            value: 'Midterm_report',
          },
        ],
      },

      {
        label: '募集发行',
        value: 'Issuance',
        children: [
          {
            label: 'IPO',
            value: 'IPO',
          },
          {
            label: 'IPO附件',
            value: 'IPO_appendix',
          },
          {
            label: '扩募',
            value: 'SO',
          },
          {
            label: '扩募附件',
            value: 'SO_appendix',
          },
        ],
      },

      {
        label: '底层资产公告',
        value: 'REITs_Assets',
        children: [
          {
            label: '评估报告',
            value: 'Valuation_report',
          },
          {
            label: '运营数据',
            value: 'Operational_data',
          },
        ],
      },
      {
        label: '基金运营公告',
        value: 'Fund_Operation',
        children: [
          {
            label: '收益分配公告',
            value: 'Div_notice',
          },
          {
            label: '限售及解除限售公告',
            value: 'Restrictions',
          },
          {
            label: '投资者关系活动公告',
            value: 'IR',
          },
          {
            label: '风险提示公告',
            value: 'Risk_Warning',
          },
          {
            label: '停牌/复牌公告',
            value: 'Suspension_Resumption',
          },
          {
            label: '增持及回购公告',
            value: 'Accumulation_Repurchase',
          },
          {
            label: '做市商调整公告',
            value: 'Market_Maker',
          },
          {
            label: '提示性公告',
            value: 'Informative_Notice',
          },
        ],
      },
      {
        label: '其他公告',
        value: 'Others',
      },
      {
        label: '全部',
        value: 'ALL',
        children: [
          {
            label: '全部',
            value: 'all',
          },
        ],
      },
    ],

    // X轴相关数值大小
    OPTIONDATA: {
      toolbox: {
        iconStyle: {
          borderColor: '#fff',
        },
      },
      title: {
        textStyle: {
          color: 'rgba(218, 218, 218, 1)',
          fontSize: 16,
        },
        top: 15,
      },
      legend: {
        right: 20,

        textStyle: {
          color: '#fff',
          fontSize: 14,
        },
      },
      xAxis: {
        axisLabel: {
          fontSize: 14,
          margin: 20,
          color: 'rgba(218, 218, 218, 1)',
        },
      },
      yAxis: {
        axisLine: {
          lineStyle: {
            color: '#545b5f',
          },
        },
        axisLabel: {
          fontSize: 14,
          color: 'rgba(243, 246, 247, 0.84)',
        },
        splitLine: {
          show: true,
          lineStyle: {
            type: 'dashed',
            color: '#48494c',
          },
        },
        nameTextStyle: {
          color: '#c4c6c9',
          fontSize: 14,
        },
        nameGap: 15,
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed',
          color: '#48494c',
        },
      },
      grid: {
        bottom: 20,
      },
      markLine: {
        lineStyle: {
          color: '#e8e6e6',
        },
      },
      label: {
        color: '#b6b6b6',
      },
      emphasis: {
        label: {
          color: '#333',
          backgroundColor: '#fff',
        },
      },
    },

    OPTIONDATA_light: {
      toolbox: {
        iconStyle: {
          borderColor: '#707070',
        },
      },
      title: {
        textStyle: {
          color: '#5a4f4f',
          fontSize: 16,
        },
        top: 15,
      },
      legend: {
        right: 20,

        textStyle: {
          color: '#544949',
          fontSize: 14,
        },
      },
      xAxis: {
        axisLabel: {
          fontSize: 14,
          margin: 20,
          color: '#707070',
        },
      },
      yAxis: {
        axisLine: {
          lineStyle: {
            color: '#fff',
          },
        },
        axisLabel: {
          fontSize: 14,
          color: '#707070',
        },
        splitLine: {
          show: true,
          lineStyle: {
            type: 'dashed',
            color: '#bebebe',
          },
        },
        nameTextStyle: {
          color: '#5a4f4f',
          fontSize: 14,
        },
        nameGap: 15,
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed',
          color: '#c0c0c0',
        },
      },
      grid: {
        bottom: 20,
      },
      markLine: {
        lineStyle: {
          color: '#000000',
        },
      },
      label: {
        color: '#403e3e',
      },
      emphasis: {
        label: {
          color: '#fff',
          backgroundColor: '#333',
        },
      },
    },

    // X，Y基准项
    basicOption: {
      title: {
        // 标题设置
        textStyle: {
          // 标题文字样式设置
          color: 'rgba(218, 218, 218, 1)',
        },
        left: 'center',
      },

      legend: {
        show: true,
        icon: 'roundRect',
        padding: [0, 0, 0, 0],
        lineStyle: {
          color: 'inherit',
        },
        textStyle: {
          color: '#fff',
        },
      },
      tooltip: {
        trigger: 'axis',
      },
      xAxis: {
        type: 'category',
        boundaryGap: true,
        axisLine: {
          show: true,
        },
        axisTick: {
          show: true,
        },
      },
      yAxis: {
        // scale: true,
        type: 'value',
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
    },

    category_cascader_options: [
      {
        name: '产权类',
        data: ['物流仓储', '产业园', '保障性住房', '消费'],
      },
      {
        name: '经营权类',
        data: ['市政生态', '能源', '收费公路'],
      },
    ],
    colors: [
      {
        name: '公募REITs',
        value: '#5ae8ed',
      },
      {
        name: '产权类',
        value: '#00A6FF',
      },
      {
        name: '经营权类',
        value: '#85D907',
      },
      {
        name: '产业园',
        value: '#BEE5EB',
      },
      {
        name: '保障性住房',
        value: '#1B75CF',
      },
      {
        name: '消费',
        value: '#6600FF',
      },
      {
        name: '物流仓储',
        value: '#87CEFA',
      },
      {
        name: '市政生态',
        value: '#C8FF00',
      },

      {
        name: '收费公路',
        value: '#FFFF44',
      },
      {
        name: '能源',
        value: '#C3E874',
      },
      {
        name: '沪深300',
        value: '#ff461e',
      },
      {
        name: '公用指数',
        value: '#f98d34',
      },
    ],
    MAP_L2: {
      '180101.SZ': '产业园',
      '508000.SH': '产业园',
      '508027.SH': '产业园',
      '508099.SH': '产业园',
      '180102.SZ': '产业园',
      '508021.SH': '产业园',
      '508088.SH': '产业园',
      '180103.SZ': '产业园',
      '508019.SH': '产业园',
      '180501.SZ': '保障性住房',
      '508058.SH': '保障性住房',
      '508068.SH': '保障性住房',
      '508077.SH': '保障性住房',
      '180301.SZ': '物流仓储',
      '508056.SH': '物流仓储',
      '508098.SH': '物流仓储',
      '180801.SZ': '市政生态',
      '508006.SH': '市政生态',
      '180201.SZ': '收费公路',
      '508001.SH': '收费公路',
      '180202.SZ': '收费公路',
      '508018.SH': '收费公路',
      '508008.SH': '收费公路',
      '508066.SH': '收费公路',
      '508009.SH': '收费公路',
      '180401.SZ': '能源',
      '508028.SH': '能源',
      '508096.SH': '能源',
      '508007.SH': '收费公路',
    },
    CODENAME: {
      // "180101.SZ": "招蛇产园",
      // "508000.SH": "张江光大",
      // "508027.SH": "东吴苏园",
      // "508099.SH": "建信中关村",
      // "180102.SZ": "华夏合肥高新",
      // "508021.SH": "国君临港",
      // "508088.SH": "国君东久",
      // "180103.SZ": "华夏和达高科",
      // "508019.SH": "中金湖北科投",
      // "180501.SZ": "深圳红土安居",
      // "508058.SH": "中金厦门安居",
      // "508068.SH": "华夏北京保障房",
      // "508077.SH": "华夏华润有巢",
      // "180301.SZ": "红土盐港",
      // "508056.SH": "GLP仓储",
      // "508098.SH": "嘉实京东",
      // "180801.SZ": "首钢绿能",
      // "508006.SH": "首创水务",
      // "180201.SZ": "广州广河",
      // "508001.SH": "沪杭甬高速",
      // "180202.SZ": "越秀高速",
      // "508018.SH": "中交公路",
      // "508008.SH": "国金中铁建",
      // "508066.SH": "江苏交控",
      // "508009.SH": "安徽交控",
      // "180401.SZ": "深圳能源",
      // "508028.SH": "国电投",
      // "508096.SH": "中航京能",
      // "508007.SH": "中金山东高速",
      // "508031.SH": "国君宽庭",
    },
    MAP_L1: {
      '180101.SZ': '产权类',
      '508000.SH': '产权类',
      '508027.SH': '产权类',
      '508099.SH': '产权类',
      '180102.SZ': '产权类',
      '508021.SH': '产权类',
      '508088.SH': '产权类',
      '180103.SZ': '产权类',
      '508019.SH': '产权类',
      '180501.SZ': '产权类',
      '508058.SH': '产权类',
      '508068.SH': '产权类',
      '508077.SH': '产权类',
      '180301.SZ': '产权类',
      '508056.SH': '产权类',
      '508098.SH': '产权类',
      '180801.SZ': '经营权类',
      '508006.SH': '经营权类',
      '180201.SZ': '经营权类',
      '508001.SH': '经营权类',
      '180202.SZ': '经营权类',
      '508018.SH': '经营权类',
      '508008.SH': '经营权类',
      '508066.SH': '经营权类',
      '508009.SH': '经营权类',
      '180401.SZ': '经营权类',
      '508028.SH': '经营权类',
      '508096.SH': '经营权类',
      '508007.SH': '经营权类',
    },

    BenchMarkcolors: [
      {
        name: 'REITs',
        value: '#5ae8ed',
      },
      {
        name: 'global_equity',
        value: '#5654ca',
      },
      {
        name: 'cn_equity',
        value: '#61ace4',
      },
      {
        name: 'bond',
        value: '#2b7eb3',
      },
      {
        name: '公募REITs',
        value: '#d43030',
      },
      {
        name: 'commodity',
        value: '#598fc8',
      },
    ],

    valuationTable: [
      {
        label: 'PFFO',
        value: 'PFFO',
      },
      {
        label: 'PCFO',
        value: 'PCFO',
      },
      {
        label: 'PNAV',
        value: 'PNAV',
      },
      {
        label: 'PB',
        value: 'PB',
      },
      {
        label: 'EV/EBITDA',
        value: 'EV/EBITDA',
      },
      {
        label: '分红回报率',
        value: 'div_yield_ttm',
        // value: "div_yield",
      },

      // {
      //   label: "Pdiv",
      //   value: "pdiv_ttm",
      // },
      // {
      //   label: "目前分派率",
      //   value: "div_yield",
      // },
      // {
      //   label: "分红回报率",
      //   value: "div_yield",
      // },
    ],

    valuationTable4: [
      {
        label: 'PFFO',
        value: 'PFFO',
      },
      {
        label: 'PCFO',
        value: 'PCFO',
      },
      {
        label: 'PNAV',
        value: 'PNAV',
      },
      {
        label: 'PB',
        value: 'PB',
      },
      {
        label: 'EV/EBITDA',
        value: 'EV/EBITDA',
      },
      {
        label: '分红回报率',
        value: 'div_yield_ttm',
      },
      {
        label: '隐含Cap Rate',
        value: 'implied_cap_rate',
      },
      {
        label: 'PAFFO',
        value: 'PAFFO',
      },
    ],

    valuationTable3: [
      {
        label: 'PFFO',
        value: 'PFFO',
      },
      {
        label: 'PCFO',
        value: 'PCFO',
      },
      {
        label: 'PNAV',
        value: 'PNAV',
      },
      {
        label: 'PB',
        value: 'PB',
      },
      {
        label: 'EV/EBITDA',
        value: 'EV/EBITDA',
      },
      {
        label: '分红回报率',
        value: 'div_yield_ttm',
        // value: "div_yield",
      },
    ],

    valuationTable2: [
      {
        label: 'PFFO',
        value: 'PFFO',
      },

      {
        label: 'PNAV',
        value: 'PNAV',
      },
      {
        label: 'PB',
        value: 'PB',
      },

      {
        label: '分派率',
        value: 'div_yield',
      },
    ],

    BreadcrumbsName: '概览',

    benchmark: [
      {
        value: 'CN10YR',
        label: 'CN10YR',
      },
      {
        value: 'US10YR',
        label: 'US10YR',
      },
      {
        value: 'JP10YR',
        label: 'JP10YR',
      },
      {
        value: 'UK10YR',
        label: 'UK10YR',
      },
      {
        value: '现货黄金',
        label: '现货黄金',
      },
      {
        value: '沪深300',
        label: '沪深300',
      },
      {
        value: '中证500',
        label: '中证500',
      },
      {
        value: '中证100',
        label: '中证100',
      },
      {
        value: '大宗商品',
        label: '大宗商品',
      },
      {
        value: '公用指数',
        label: '公用指数',
      },

      {
        value: '上证50',
        label: '上证50',
      },
      {
        value: '红利指数',
        label: '红利指数',
      },
      {
        value: '深证红利',
        label: '深证红利',
      },
      {
        value: '中证新能源指数',
        label: '中证新能源指数',
      },
      {
        value: '红利低波100',
        label: '红利低波100',
      },
      {
        value: '中证红利',
        label: '中证红利',
      },
      {
        value: '电力指数',
        label: '电力指数',
      },
      {
        value: '绿色能源',
        label: '绿色能源',
      },
      {
        value: '股息龙头',
        label: '股息龙头',
      },
      {
        value: '全指公用',
        label: '全指公用',
      },

      {
        value: '上证公用',
        label: '上证公用',
      },
      {
        value: '高股息策略',
        label: '高股息策略',
      },
      {
        value: '东证红利低波',
        label: '东证红利低波',
      },
      {
        value: '国企红利',
        label: '国企红利',
      },
      {
        value: 'sw_公用事业',
        label: 'sw_公用事业',
      },
      {
        value: '国证交通运输行业指数',
        label: '国证交通运输行业指数',
      },
      {
        value: '运输指数',
        label: '运输指数',
      },
      {
        value: '国证物流',
        label: '国证物流',
      },
      {
        value: 'sw_高速公路',
        label: 'sw_高速公路',
      },
      {
        value: '国证电力公用事业行业指数',
        label: '国证电力公用事业行业指数',
      },

      {
        value: 'sw_电力',
        label: 'sw_电力',
      },
      {
        value: 'sw_物流',
        label: 'sw_物流',
      },
      {
        value: 'sw_产业地产',
        label: 'sw_产业地产',
      },
      {
        value: 'sw_水务及水治理',
        label: 'sw_水务及水治理',
      },
      {
        value: 'sw_环保',
        label: 'sw_环保',
      },
      {
        value: 'CN1YR',
        label: 'CN1YR',
      },
      {
        value: 'CN2YR',
        label: 'CN2YR',
      },
      {
        value: 'CN3YR',
        label: 'CN3YR',
      },
      {
        value: 'CN5YR',
        label: 'CN5YR',
      },
      {
        value: 'US1YR',
        label: 'US1YR',
      },

      {
        value: 'US2YR',
        label: 'US2YR',
      },
      {
        value: 'US3YR',
        label: 'US3YR',
      },
      {
        value: 'US5YR',
        label: 'US5YR',
      },
      {
        value: 'JP3YR',
        label: 'JP3YR',
      },
      {
        value: 'JP5YR',
        label: 'JP5YR',
      },
      {
        value: '现货白银',
        label: '现货白银',
      },
      {
        value: '日经225',
        label: '日经225',
      },
      {
        value: '标普500',
        label: '标普500',
      },
      {
        value: '恒生指数',
        label: '恒生指数',
      },
      {
        value: '道琼斯工业平均',
        label: '道琼斯工业平均',
      },

      {
        value: 'cni_风光装备',
        label: 'cni_风光装备',
      },
      {
        value: 'wi_科技园区指数',
        label: 'wi_科技园区指数',
      },
      {
        value: 'cj_园区开发(长江)',
        label: 'cj_园区开发(长江)',
      },
      {
        value: 'wi_房屋租赁指数',
        label: 'wi_房屋租赁指数',
      },
      {
        value: 'cj_垃圾焚烧(长江)',
        label: 'cj_垃圾焚烧(长江)',
      },
      {
        value: 'wi_垃圾发电指数',
        label: 'wi_垃圾发电指数',
      },
      {
        value: 'wi_生物质能指数',
        label: 'wi_生物质能指数',
      },
      {
        value: '恒生地产分类指数',
        label: '恒生地产分类指数',
      },
    ],

    CODELIST: [],

    test: '相泽楠',
  },
  // 修改状态
  mutations: {
    // 存储面包屑
    fnSetBreadcrumbs(state, data) {
      state.BreadcrumbsName = data;
    },

    setCodeName(state, obj) {
      state.CODENAME = obj;
    },

    setCodeList(state, data) {
      state.CODELIST = data;
    },

    setTheme(state, data) {
      state.theme = data;
    },

    setColor(state, data) {
      data.forEach((node) => {
        state.colors = state.colors.map((item) => {
          if (item['name'] == node['tagName']) {
            item['value'] = node['tagColor'];
          }
          return item;
        });
      });
    },
    setColor2(state, data) {
      data.forEach((node) => {
        state.colors = state.colors.map((item) => {
          if (item['name'] == node['tagName']) {
            item['value'] = node['tagColor'];
          }
          return item;
        });
      });
    },
    setColor3(state, data) {
      data.forEach((node) => {
        state.BenchMarkcolors = state.BenchMarkcolors.map((item) => {
          if (item['name'] == node['tagName']) {
            item['value'] = node['tagColor'];
          }
          return item;
        });
      });
    },
  },
  actions: {},
  modules: {},
});
