<template>
    <div class="container">
        <slot></slot>
    </div>
</template>


<script>

export default {
    // 从父组件传值的
    props:{
        // imgSrc:{
        //     type:String,
        //     default:""
        // },
        // title:{
        //     type:String,
        //     default:""
        // },
        // price:{
        //     type:String,
        //     default:""
        // },
        // marketPrice:{
        //     type:String,
        //     default:""
        // },
    },
    methods:{
       
    },
    components:{
      
    },
    computed:{
       
    },
    data(){
        return {
        }

    }
}
</script>

<style  scoped>
    .container span{
      font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.25px;
        line-height: 20px;
        color: rgba(255, 255, 255, 1);
        margin-left: 18px;
    }

    .container span .el-select {
  border: 1px solid #999999;
  margin-left: 10px;
  padding: 5px 0;
  padding-right: 10px;
  border-radius: 5px;
}

</style>