export function setCookie(c_name,value,expire) {
    var date=new Date()
    date.setSeconds(date.getSeconds()+expire)
    document.cookie=c_name+ "="+escape(value)+"; expires="+date.toGMTString()
    console.log(document.cookie,'dede ')
    
}

export function delCookie(c_name){
    setCookie(c_name, "", -1)
}