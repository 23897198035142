<template>
  <div class="shadow" v-show="show">
    <CDialog ref="CDialog" :DIALOGHEIGHT="height_dialog" :dialogVisible="dialogVisible2">
      <el-dialog
        title="葱花投研"
        :visible.sync="dialogVisible2"
        :width="width_dialog"
        :top="top_dialog">
        <div>
          <el-table
            class="table1"
            :class="theme=='dark'?'dark_mark':''"
            :header-cell-style="styleBindFun"
            :data="tableData"
            :summary-method="getSummaries"
            show-summary>
            <el-table-column align="center" prop="id" label="发行序号" width="180">
            </el-table-column>
            <el-table-column align="center" prop="ipo_date" label="发行时间"> </el-table-column>
            <el-table-column align="center" prop="issue_of_units" sortable label="发行份额(万份)">
              <template slot-scope="scope">
                <span>{{ (Number(scope.row.issue_of_units) / 10000).toFixed(2) }} </span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="issue_price" sortable label="发行价格(元/份)">
              <template slot-scope="scope">
                <span>{{ Number(scope.row.issue_price).toFixed(2) }} </span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="total_value" sortable label="发行金额(亿元)">
              <template slot-scope="scope">
                <span>{{ (Number(scope.row.total_value) / 100000000).toFixed(2) }} </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-dialog>
    </CDialog>
  </div>
</template>
  
  <script>
import CDialog from '@/components/Basic/CDialog';
import { fnReturnTableStyle } from '@/utils/util';
export default {
  components: {
    CDialog,
  },
  computed: {
    currentTheme() {
      return this.$store.state.theme; // 从Vuex中获取当前的主题值
    },
  },
  props: {
    show: Boolean,
    code: String,
  },
  watch: {
    dialogVisible2(val) {
      if (!val) {
        this.close();
      }
    },
    show(val) {
      if (val) {
        this.styleBindFun();
        this.fnGetAssetValueByCode();
        this.dialogVisible2 = true;
      }
    },
    currentTheme(newVal, oldVal) {
      this.styleBindFun();
    },
  },
  data() {
    return {
      theme:'',
      tableData: [],
      dialogVisible2: false,
      width_dialog: '60%',
      //   height_dialog: '73vh',
      height_dialog: 'auto',
      height_chart: '71vh',
      top_dialog: '5vh',
    };
  },
  methods: {
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          if(index===3){
            var i =0;
            sums[index] = values.reduce((prev, curr) => {
              i++;
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] = (sums[index]/i).toFixed(2);
          }else if(index===4){
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] = (Number(sums[index]) / 100000000).toFixed(2);
          }else{
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] = (Number(sums[index]) / 10000).toFixed(2);
          }
        } else {
          sums[index] = '';
        }
      });

      return sums;
    },
    close() {
      this.$emit('update:show', false);
    },
    styleBindFun() {
      let val = this.$store.state.theme == 'dark' ? true : false;
      this.theme = this.$store.state.theme;
      return fnReturnTableStyle(val);
    },
    async fnGetAssetValueByCode() {
      // let code = '508000.SH';

      const { data } = await this.$https.get('/api/v2/stock_ipo_hist_table?code=' + this.code);

      if (data.code !== 200) return this.$message.error(data.msg);
      //TODO,这里接口报错
      this.tableData = data.data;
      var i = 0;
      this.tableData.forEach((item) => {
        i++;
        item['id'] = i;
      });
    },
  },
  mounted() {},
};
</script>
  
<style lang="less" scoped>
.dark_mark {
  /deep/ .el-table__footer tr td{
    background:#343742 !important;
    font-size:16px;color:#dadada !important;
    border-top: 1px solid #4c4d5f !important;
  }
}
</style>
  