<template>
  <div class="CH_Card profit_card profit_card3">
    <div class="CH_Card_tit">REITs-IPO 各财务指标 完成率</div>

    <div class="CH_Card_content">
      <span>
        显示类型
        <CButtonsSeparate
          @Cbtns_sep="fnGetCbtnsSep"
          class="CButtonsSeparate_style"
          :dButtons1="['大类', '个股']"
          :disabled="false"
          :dButtons1_current="select_type" />
      </span>
      <CCascader
        v-show="select_type === '大类'"
        @CCascader_select_val="fnCCascader_select_val_chart3"
        :default="da_lei_default_value"
        :checkStrictly="false"
        :multiple="true"
        :collapsetags="true"
        :options="category_cascader_options"
        >选择类别
      </CCascader>
      <CCascader
        v-show="select_type === '个股'"
        :checkStrictly="false"
        :default="reit_code_default"
        :multiple="true"
        :isDelete="false"
        placeholder="请选择个股"
        :collapsetags="true"
        @CCascader_select_val="fnSelect_select_val_chart1_codename"
        :options="CODENAMEDATA"
        >选择个股
      </CCascader>

      <CSelect
        class="chart3_cascasder"
        @CSelect_select_val="change3"
        :options="options3"
        :auto="true"
        :clearable="false"
        :default="factor">
        选择财务指标</CSelect
      >
      <div class="downloadBtns">
        <CButtonsDownload
          :dButtonsDownloads="dButtonsDownloads"
          @fnButtondownload="fnButtonDL6_1" />
        <span style="display: none">
          <download-excel
            id="downloadExcel_ipo"
            :data="excel_data"
            :fields="json_fields"
            :name="overviewName">
            <i class="el-icon-download"></i>
          </download-excel>
        </span>
      </div>
    </div>

    <!-- <div class="CH_Card_content">
      <span>
        显示类型
        <CButtonsSeparate
          @Cbtns_sep="fnGetCbtnsSep"
          class="CButtonsSeparate_style"
          :dButtons1="['大类', '个股']"
          :disabled="false"
          :dButtons1_current="select_type" />
      </span>
    </div> -->
    <div class="CH_Card_content">
      <span @click="fnOpenDialog" class="iconfont icon-fangda icon-fangda2"></span>
      <div
        class="canvasArea1"
        style="width: 100%; height: 420px"
        ref="ipoRef"
        v-loading="loading"
        element-loading-text="数据量巨大，正在计算中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 1)"></div>
    </div>
    <CDialog
      id="dialog1"
      ref="CDialog"
      :DIALOGHEIGHT="height_dialog"
      :dialogVisible="dialogVisible">
      <el-dialog
        title="葱花投研"
        :visible.sync="dialogVisible"
        :width="width_dialog"
        :top="top_dialog">
        <div>
          <el-dropdown size="mini" trigger="click" type="primary" @command="fnDropdown">
            <span class="el-dropdown-link">
              {{ popUpWindowDatas[popUpWindowIndex]
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="idx" v-for="(node, idx) in popUpWindowDatas" :key="idx">
                {{ node }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div :style="{ height: height_chart }">
          <div ref="ipoDialogRef" style="width: 100%; height: 100%"></div>
          <!-- <div class="Yield_Style">年化收益率</div> -->
          <!-- <div class="Volatility_Style">年化波动率</div> -->
        </div>
      </el-dialog>
    </CDialog>
  </div>
</template>

<script>
import CButtonsDownload from '@/components/Basic/CButtons_download';
import CCascader from '@/components/Basic/CCascader';
import CSelect from '@/components/Basic/CSelect';
import CButtonsSeparate from '@/components/Basic/CButtons_separate';
import CDialog from '@/components/Basic/CDialog';

import { debounce, codesToNameMap2, categroyDefaultValue } from '../../utils';
import { fnDownloadBgc } from '../../utils/util';

import html2canvas from 'html2canvas';

export default {
  name: 'IPO',
  components: { CButtonsDownload, CCascader, CSelect, CButtonsSeparate, CDialog },
  data() {
    const CATEGORYDATA = JSON.parse(localStorage.getItem('CATEGORYDATA')) || [];
    const REITsNAME = JSON.parse(localStorage.getItem('REITsNAME')) || [];
    const da_lei_default_value = categroyDefaultValue(CATEGORYDATA);
    return {
      dialogVisible: false,
      width_dialog: '80%',
      height_dialog: '73vh',
      height_chart: '72vh',
      popUpWindowDatas: this.$store.state.popUpWindowDatas,
      popUpWindowIndex: 0,
      top_dialog: '5vh',
      //
      overviewName: 'REITs-IPO 各财务指标 完成率',
      json_fields: {},
      loading: true,
      date_map: [],
      excel_data: [],
      echart_data: [],
      // 显示类型
      select_type: '大类',
      // select_type: '个股',
      dButtonsDownloads: [
        {
          id: '241020',
          name: '下载图片',
        },
        {
          id: '241020',
          name: '下载表格',
        },
      ],
      sCurrentTheme: this.$store.state.theme == 'dark' ? true : false,
      // 选择大类
      da_lei_default_value: Object.freeze(da_lei_default_value),
      category_cascader_val_chart3: [...new Set(da_lei_default_value.flat(1))],
      category_cascader_options: Object.freeze(CATEGORYDATA),
      // 个股
      CODENAMEDATA: Object.freeze(REITsNAME),
      // 选择个股
      reit_code_default: codesToNameMap2(REITsNAME),
      reit_code: [],
      // 财务指标
      factor: 'revenue',
      options3: Object.freeze([
        { label: '总收入', value: 'revenue' },
        { label: 'EBITDA', value: 'ebitda' },
        { label: '可供分配金额', value: 'distributable_amt' },
      ]),
      ROOTFONTFIZE: '',
      colors: {},
    };
  },
  computed: {
    currentTheme() {
      return this.$store.state.theme; // 从Vuex中获取当前的主题值
    },
  },
  watch: {
    currentTheme(newVal, oldVal) {
      this.fnGetTheme();
      this.renderEchart(this.echart_data);
    },
  },
  created() {
    // 颜色数组 不正确的数据结构浪费时间和性能
    const colors = this.$store.state.colors || [];
    this.colors = colors.reduce((p, c) => {
      p[c.name] = c.value;
      return p;
    }, {});
    // 个股默认值（组件封装太垃圾，手动处理）
    this.reit_code = this.reit_code_default
      .map((item) => {
        return item.find((n) => n.indexOf('.') >= 0);
      })
      .filter((item) => item);
  },
  mounted() {
    this.resize();
    this.loadingData();
    window.addEventListener('resize', this.resize);
  },
  beforeDestroy() {
    this.echart_instance && this.echart_instance.dispose();
    this.echart_dialog_instance && this.echart_dialog_instance.dispose();
    window.removeEventListener('resize', this.resize);
  },
  methods: {
    fnOpenDialog() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.renderEchart(this.echart_data, true);
      });
    },
    fnDropdown(val) {
      let conf = this.$store.state.popUpWindowConfi[val];
      this.width_dialog = conf.width_dialog;
      this.top_dialog = conf.top_dialog;
      this.height_dialog = conf.height_dialog;
      this.popUpWindowIndex = val;
      this.height_chart = conf.height_chart;
      this.$nextTick(() => {
        this.renderEchart(this.echart_data, true);
      });
    },
    fnGetTheme() {
      this.sCurrentTheme = this.$store.state.theme == 'dark' ? true : false;
    },
    resize() {
      this.fnGetWidth();
      this.echart_instance && this.echart_instance.resize();
    },
    fnGetWidth() {
      var element = document.getElementById('rootchart');
      let ROOTFONTFIZE = element.offsetWidth;
      ROOTFONTFIZE = ROOTFONTFIZE > 1440 ? 1440 : ROOTFONTFIZE;
      ROOTFONTFIZE = ROOTFONTFIZE < 1400 ? ROOTFONTFIZE - 200 : ROOTFONTFIZE;
      this.ROOTFONTFIZE = ROOTFONTFIZE / 10;
    },
    fnButtonDL6_1(val) {
      if (val == '下载图片') {
        this.fnButtonDL6_4_1();
      } else {
        setTimeout(() => {
          document.getElementById('downloadExcel_ipo').click();
        }, 0);
      }
    },
    fnButtonDL6_4_1() {
      let h = this.$refs.ipoRef.scrollHeight;
      let w = this.$refs.ipoRef.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.ipoRef, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL('image/png');
        var a = document.createElement('a'); // 生成一个a元素
        var event = new MouseEvent('click'); // 创建一个单击事件
        a.download = 'REITs-IPO 各财务指标 完成率'; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },
    // 表格数据
    tabelData() {
      const is_single = this.select_type === '个股';
      const type = this.factor;
      const type_name = (this.options3.find((item) => item.value === type) || {}).label;
      const json_fields = {
        category_1: 'category_1',
        category_2: 'category_2',
        name: 'name',
        reit_code: 'reit_code',
      };
      if (!is_single) {
        delete json_fields.category_1;
        delete json_fields.category_2;
        delete json_fields.reit_code;
      }
      this.date_map.forEach((k) => {
        json_fields[k + type_name + '完成率'] = {
          field: k,
          callback: (v = 0) => {
            return Number(v).toFixed(2) + '%';
          },
        };
      });
      this.overviewName = 'REITs-IPO ' + type_name + '完成率';
      this.json_fields = json_fields;
    },
    // 选择类别
    fnCCascader_select_val_chart3(val) {
      this.category_cascader_val_chart3 = val;
      this.loadingData();
    },
    // 选择个股
    fnSelect_select_val_chart1_codename(val) {
      val = val.filter((node) => {
        return node.includes('.');
      });

      this.reit_code = val;
      this.loadingData();
    },
    // 选择财务指标
    change3(val) {
      this.factor = val;
      this.loadingData();
    },
    // 显示类型
    fnGetCbtnsSep(val) {
      this.select_type = val;
      this.loadingData();
    },
    loadingData: debounce(function () {
      this.$nextTick(this._loadingData);
    }, 500),
    async _loadingData() {
      this.loading = true;
      const is_single = this.select_type === '个股';
      const codes = !is_single ? '' : this.reit_code.join('&codes=');
      const r_type = is_single ? '' : this.category_cascader_val_chart3.join('&r_type=');
      const queryString = new URLSearchParams({
        factor: this.factor,
        by_type: is_single ? 'single' : 'type',
      }).toString();
      const { data } = await this.$https.get(
        '/api/calIPOCompletion?' + queryString + '&r_type=' + r_type + '&codes=' + codes
      );
      this.loading = false;
      if (data.code !== 200) return this.$message.error(data.msg);
      const echart_data = data.data;
      this.renderEchart(echart_data);
      this.echart_data = echart_data;
    },
    renderEchart(echart_data, flag) {
      if (flag) {
        if (!this.echart_dialog_instance) {
          this.echart_dialog_instance = this.$echarts.init(this.$refs.ipoDialogRef);
        }
        this.echart_dialog_instance.clear();
      }
      if (!this.echart_instance) {
        this.echart_instance = this.$echarts.init(this.$refs.ipoRef);
      }
      !flag && this.echart_instance.clear();

      const source = Object.values(
        echart_data.reduce((p, c) => {
          const name = c.name || c.category;
          if (!p[name]) {
            p[name] = {
              name: name,
              category_1: c.category_1,
              category_2: c.category_2,
              reit_code: c.reit_code,
            };
          }
          const year = new Date(c.rp_date).getFullYear();
          p[name][year] = c.data;
          return p;
        }, {})
      );
      const _date_map = source
        .map((item) => Object.keys(item))
        .reduce((p, c) => {
          const list = c.filter(
            (k) => !['name', 'reit_code', 'category_2', 'category_1'].includes(k)
          );
          p.push(...list);
          return p;
        }, []);
      const date_map = [...new Set(_date_map)].sort((a, b) => a - b);
      this.date_map = date_map;
      this.excel_data = source;

      this.$nextTick(() => {
        this.tabelData();
      });

      let OPTIONDATA =
        this.$store.state.theme == 'dark'
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      const colorArr = [
        // '#0891b2',
        // '#06b6d4',
        '#22d3ee',
        '#67e8f9',
        '#a5f3fc',
        '#cffafe',
        '#ecfeff',
        // 'rgba(85, 226, 231, 1)',
        // 'rgba(114, 231, 235, 1)',
        // 'rgba(142, 236, 239, 1)',
        // 'rgba(170, 240, 243, 1)',
        // 'rgba(198, 245, 247, 1)',
      ];
      const theme = this.$store.state.theme;

      const item_length = (source || []).length || 30;
      const font_r = item_length > 30 ? 30 / item_length : 1;

      const options = {
        color: colorArr,
        legend: {
          type: 'plain',
          left: this.ROOTFONTFIZE * (35 / 144),
          top: this.ROOTFONTFIZE * (10 / 144),
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
          },
        },
        tooltip: {
          trigger: 'axis',
          confine: true,
          triggerOn: 'mousemove|click',
          formatter: (data) => {
            const name = data[0].name;
            const str = data
              .map(({ marker, value, seriesName }, index) => {
                const k = seriesName;
                const v = value[k] || 0;
                return marker + k + `<span style="margin-left:10px">${v.toFixed(2)}%</span>`;
              })
              .join('<br />');
            return `${name}<br/>${str}`;
          },
        },
        dataset: {
          dimensions: ['name', ...date_map],
          source,
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            interval: 0,
            rotate: flag && this.popUpWindowIndex === 1 ? 45 : 30,
            margin: OPTIONDATA.xAxis.axisLabel.margin,
            fontSize: OPTIONDATA.xAxis.axisLabel.fontSize * (flag ? font_r : 1),
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
        },
        yAxis: {
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            formatter: (v) => {
              return v + '%';
            },
            fontSize: OPTIONDATA.yAxis.axisLabel.fontSize,
            color: OPTIONDATA.yAxis.axisLabel.color,
          },
          splitLine: OPTIONDATA.yAxis.splitLine,
        },
        series: date_map.map(() => ({
          type: 'bar',
        })),
        grid: {
          top: this.ROOTFONTFIZE * (60 / 144),
          left: this.ROOTFONTFIZE * (35 / 144),
          right: this.ROOTFONTFIZE * (60 / 144),
          bottom: this.ROOTFONTFIZE * (30 / 144),
          containLabel: true,
        },
      };
      if (flag) {
        this.echart_dialog_instance.setOption(options);
        this.echart_dialog_instance.resize();
        return;
      }
      this.echart_instance.setOption(options);
      this.echart_instance.resize();
    },
  },
};
</script>

<style lang="less" scoped>
.downloadBtns {
  margin-right: 0;
  margin-left: auto;
}

.canvasArea1 {
  width: 100%;

  // background-color: #1a1c21;
  // border: 1px solid #4f5053;
  background-color: var(--charts_bgc);
  border: 1px solid var(--charts_brc);
  border-radius: calc(var(--ROOTFONTFIZE) * (10 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  position: relative;
  // margin-left:20px;
  // margin-right:20px;
}

.CButtonsSeparate_style {
  // margin-left: calc(var(--ROOTFONTFIZE) * (20 / 144));
  margin-left: 20px;
}
.CH_Card_tit {
  font-size: calc(var(--ROOTFONTFIZE) * (20 / 144));
  font-weight: 700;
  letter-spacing: 0px;
  line-height: calc(var(--ROOTFONTFIZE) * (28 / 144));
  color: var(--chart_title);
  // border-left: 5px solid rgba(21, 115, 254, 1);
  border-left: calc(var(--ROOTFONTFIZE) * (5 / 144)) solid var(--primary-color);
  padding-right: calc(var(--ROOTFONTFIZE) * (20 / 144));

  padding-left: calc(var(--ROOTFONTFIZE) * (13 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  display: flex;
  justify-content: space-between;
}

.CH_Card_content {
  display: flex;
  align-items: center;
  margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding: 0 calc(var(--ROOTFONTFIZE) * (20 / 144));
  position: relative;
}
.CH_Card_content span {
  display: flex;
  align-items: center;
  color: var(--stock_area_item_color1);
  margin-right: calc(var(--ROOTFONTFIZE) * (50 / 144));
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
}
.chart3_cascasder {
  margin-left: calc(var(--ROOTFONTFIZE) * (50 / 144));
}
</style>
