<template>
    <div class="containerCButtons">
      <span>
        <el-button 
         v-for="(item, index) in dButtons1"
         :key="index"
          @click="fnButtons1(item)"
          :class="item == defaultVal ? 'checkedBtnStyle' : ''"
          class="noActive" size="mini" >
          {{item}}
          </el-button>
      </span>
    </div>
</template>


<script>

export default {
    // 从父组件传值的
    props:{
        dButtons1:{
            type:Array,
            default:""
        },
        defaultVal:{
            type:String,
            default:""
        },
    },
    methods:{
       fnButtons1(val){
        this.$emit('Cbtns',val)
       }
    },
    components:{
      
    },
    computed:{
       
    },
    data(){
        return {
          // dButtons1_current:this.default
            //  dButtons1:['概览','市值相关','流动性相关'],
            //  dButtons1_current:'概览',
        }

    }
}
</script>

<style lang="less"  scoped>
    
.containerCButtons{
   
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.25px;
    line-height: 20px;
    color: rgba(255, 255, 255, 1);
    display: flex;
    align-items: center;
}

span{
  background-color: var(--buttons_bgc);
  box-sizing: border-box;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 0 5px;
}

// 切换按钮  start --------------------------------------------

 .el-button {
  min-width: 101px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.1px;
  height: 30px;
}

 .noActive {
  background-color: transparent ;
  // color: var(--buttons_noActive_color);
  color: var(--buttons_noActive_color);
  border: none;
 }

.checkedBtnStyle{
  background-color:var(--primary-color) !important;
  color: var(--table_bgc) !important;
}


 .el-button:hover {
  background-color:var(--primary-color) !important;
  color: var(--table_bgc) !important;
}





</style>