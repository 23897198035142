export default {
    bind(el,bindings){
        // 所有权限
        let levelList=JSON.parse(window.sessionStorage.getItem('levelList')).map(node=>{
            return node['funcId']
        }) 
        let need =Number(bindings.value) 
        let flag=levelList.includes(need)
        if(!flag){
            setTimeout(() => {
               el.parentNode.removeChild(el) 
            },0);
        }
    }   
}