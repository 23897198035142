<template>
  <div class="info-gTEwi3yWTcTe5O" :class="currentTheme">
    <div class="title" v-if="hasData">发行金额及比例</div>
    <div class="line" v-if="hasData">
      <div class="text">发行规模{{ info_data['发行规模'] | price }}份</div>
    </div>
    <div class="bar" v-if="hasData">
      <div
        class="gray"
        data-title="战配份额"
        v-show="info_data['战配比例'] > 0"
        :style="{ width: info_data['战配比例'] + '%' }">
        {{ info_data['战配份额'] | price }}（{{ info_data['战配比例'] | priceV }}%）
      </div>
      <div
        class="blue_1"
        data-title="网下份额"
        v-show="info_data['网下比例'] > 0"
        :style="{ width: info_data['网下比例'] + '%' }">
        {{ info_data['网下份额'] | price }}（{{ info_data['网下比例'] | priceV }}%）
      </div>
      <div
        class="blue_2"
        data-title="公众份额"
        v-show="info_data['公众比例'] > 0"
        :style="{ width: info_data['公众比例'] + '%' }">
        {{ info_data['公众份额'] | price }}（{{ info_data['公众比例'] | priceV }}%）
      </div>
    </div>
    <div class="tab-1" v-if="hasData">
      <table style="width: 80%" border="0">
        <tr style="background-color: #343742; color: #fff">
          <td align="center" style="width: 10%">发行规模(份)</td>
          <td align="center" style="width: 10%">战配份额(份)</td>
          <td align="center" style="width: 10%">战配比例</td>
          <td align="center" style="width: 10%">网下份额(份)</td>
          <td align="center" style="width: 10%">网下占比</td>
          <td align="center" style="width: 15%">有效认购比例(份)</td>
          <td align="center" style="width: 10%">公众份额</td>
          <td align="center" style="width: 10%">公众占比</td>
          <td align="center" style="width: 15%">有效认购比例</td>
        </tr>
        <tr style="background-color: rgba(0, 0, 0, 0); color: #fff">
          <td align="center" style="width: 10%">{{ info_data['发行规模'] | price }}</td>
          <td align="center" style="width: 10%">{{ info_data['战配份额'] | price }}</td>
          <td align="center" style="width: 10%">{{ info_data['战配比例'] | priceV }}%</td>
          <td align="center" style="width: 10%">{{ info_data['网下份额'] | price }}</td>
          <td align="center" style="width: 10%">{{ info_data['网下比例'] | priceV }}%</td>
          <td align="center" style="width: 15%">
            {{ info_data['网下投资者有效认购申请比例'] | priceV }}%
          </td>
          <td align="center" style="width: 10%">{{ info_data['公众份额'] | price }}</td>
          <td align="center" style="width: 10%">{{ info_data['公众比例'] | priceV }}%</td>
          <td align="center" style="width: 15%">
            {{ info_data['公众投资者有效认购申请比例'] | priceV }}%
          </td>
        </tr>
      </table>
      <div class="pie-wrap">
        <div
          class="pie"
          v-show="info_data['网下投资者有效认购申请倍数']"
          :style="{ '--p': info_data['网下投资者有效认购申请比例'], '--color1': '#3151d3' }"
          :data-p="(info_data['网下投资者有效认购申请比例'] || 0).toFixed(1) + '%'">
          <div class="t">{{ (info_data['网下投资者有效认购申请倍数'] || 0).toFixed(2) }}x</div>
        </div>
        <div
          class="pie"
          v-show="info_data['公众投资者有效认购申请倍数']"
          :style="{ '--p': info_data['公众投资者有效认购申请比例'], '--color1': '#5e63e6' }"
          :data-p="(info_data['公众投资者有效认购申请比例'] || 0).toFixed(1) + '%'">
          <div class="t">{{ (info_data['公众投资者有效认购申请倍数'] || 0).toFixed(2) }}x</div>
        </div>
      </div>
      <div
        class="tip"
        v-if="!info_data['网下投资者有效认购申请倍数'] && !info_data['公众投资者有效认购申请倍数']">
        尚未发布
      </div>
    </div>
    <div class="tab-2" v-if="hasData">
      <div style="width: 50%">
        <div class="t-2">询价情况安排及日期</div>
        <table style="width: 100%" border="0">
          <tr style="background-color: #343742; color: #fff">
            <td align="center" style="width: 12%">询价下限</td>
            <td align="center" style="width: 12%">询价上限</td>
            <td align="center" style="width: 12%">发行价格</td>
            <td align="center" style="width: 12%">询价开始</td>
            <td align="center" style="width: 12%">询价结束</td>
            <td align="center" style="width: 12%">询价天数</td>
          </tr>
          <tr style="background-color: rgba(0, 0, 0, 0); color: #fff">
            <td align="center" style="width: 12%">{{ info_data['询价下限'] || '-' }}</td>
            <td align="center" style="width: 12%">{{ info_data['询价上限'] || '-' }}</td>
            <td align="center" style="width: 12%">{{ info_data['发行价格'] || '-' }}</td>
            <td align="center" style="width: 12%">{{ info_data['询价开始'] || '-' }}</td>
            <td align="center" style="width: 12%">{{ info_data['询价结束'] || '-' }}</td>
            <td align="center" style="width: 12%">{{ info_data['询价天数'] || '-' }}</td>
          </tr>
        </table>
      </div>
      <div class="e2" v-show="info_data['询价开始'] || info_data['询价结束']">
        <div class="e-line">
          <div class="d1" :style="{ '--x': '10%' }" v-show="info_data['询价开始']">
            <div class="text">询价开始<br />{{ info_data['询价开始'] }}</div>
          </div>
          <div class="d2" :style="{ '--x': '34%' }" v-show="info_data['询价结束']">
            <div class="text">询价结束<br />{{ info_data['询价结束'] }}</div>
          </div>
          <!-- <div class="d3" :style="{ '--x': '76%' }">
            <div class="text">预计发行<br />2024-07-02</div>
          </div> -->
          <div
            class="e-line-2"
            :style="{ '--x': '10%', '--w': 34 - 10 + '%' }"
            v-show="info_data['询价天数']">
            <div class="text">{{ info_data['询价天数'] }}天</div>
          </div>
        </div>
      </div>
      <div class="e3" v-show="info_data['询价下限'] && info_data['询价上限']">
        <div
          class="ball"
          :style="{ '--top': calcBar2() + '%' }"
          v-show="info_data['发行价格']"></div>
        <div class="text text-1">上限：{{ info_data['询价上限'] }}</div>
        <div
          class="text text-2"
          v-show="info_data['发行价格']"
          :style="{ '--top': calcBar2() + '%' }">
          发行价：{{ info_data['发行价格'] }}
        </div>
        <div class="text text-3">下限：{{ info_data['询价下限'] }}</div>
      </div>
    </div>
    <div class="not-data" v-if="!hasData">
      <div v-show="!loading">暂无数据</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Info',
  props: {
    auditid: String,
    code: String,
  },
  data() {
    return {
      loading: true,
      info_data: {},
    };
  },
  watch: {
    code(now) {
      if (!now) {
        this.info_data = {};
      }
    },
  },
  computed: {
    currentTheme() {
      return this.$store.state.theme; // 从Vuex中获取当前的主题值
    },
    hasData() {
      return Object.keys(this.info_data).length;
    },
  },
  filters: {
    price(v) {
      const str = v / 100000000 + '';
      const str2 = str.length > 4 ? str.slice(0, 4) : str;
      return str2 + '亿';
    },
    priceV(v) {
      if (!v) return '-';
      return (v + '').slice(0, 4);
    },
  },
  methods: {
    calcBar2() {
      const { 询价下限: bottom, 询价上限: top, 发行价格: current } = this.info_data;
      if (top === bottom || !current) return 50;
      const v = top - bottom;
      const r = current - bottom;
      return 100 - (r / v) * 100;
    },
    async loadData() {
      this.loading = true;
      const qs = new URLSearchParams({
        code: this.code || this.auditid,
        // auditid: this.auditid,
      }).toString();
      const { data: result } = await this.$https.get('/api/v2/newreitInfo?' + qs);
      this.loading = false;
      if (result.code !== 200) {
        this.info_data = {};
        return this.$message.error(result.msg);
      }
      const data = (result.data || [])[0] || {};
      this.info_data = data;
    },
  },
};
</script>

<style scoped lang="less">
.info-gTEwi3yWTcTe5O {
  position: relative;
  width: 100%;
  .title {
    position: relative;
    width: 100%;
    font-size: 18px;
    color: #fff;
    font-weight: 700;
    text-indent: 12px;
  }
  > .line {
    position: relative;
    width: 100%;
    height: 2px;
    margin-top: 10px;
    border-radius: 4px;
    background-color: #fff;
    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 2px;
      height: 8px;
      border-radius: 4px;
      background-color: #fff;
    }
    &::after {
      left: unset;
      right: 0;
    }
    > .text {
      position: absolute;
      left: 50%;
      top: 0;
      color: #fff;
      font-size: 16px;
      transform: translate(-50%, -26px);
    }
  }
  > .bar {
    display: flex;
    width: 100%;
    height: 40px;
    margin-top: 40px;
    > .gray,
    > .blue_1,
    > .blue_2 {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      text-align: center;
      &::before {
        content: attr(data-title);
        display: block;
        position: absolute;
        left: 50%;
        top: 0;
        color: #fff;
        font-size: 14px;
        white-space: nowrap;
        transform: translate(-50%, -100%);
      }
    }
    > .gray {
      color: #000;
      background-color: #d1d1d1;
    }
    > .blue_1 {
      color: #fff;
      background-color: #3151d3;
    }
    > .blue_2 {
      color: #fff;
      background-color: #5e63e6;
    }
  }
  > .tab-1,
  > .tab-2 {
    display: flex;
    margin-top: 60px;
    font-size: 17px;
    /deep/ td {
      height: 40px;
    }
  }
  > .tab-1 {
    .pie {
      position: relative;
      --w: 100px;
      width: var(--w);
      height: var(--w);
      // margin: 0 16px;
      &:before {
        content: '';
        position: absolute;
        border-radius: 50%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border: 1px solid #7e7e7f;
        font-size: 12px;
        background: conic-gradient(var(--color1) calc(var(--p) * 1%), #404040 0);
      }
      &::after {
        content: attr(data-p);
        position: absolute;
        right: 0;
        top: 0;
        display: block;
        transform: translate(50%, -50%);
        color: #fff;
        font-size: 12px;
      }
      > .t {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 3;
        color: #fff;
        width: 50px;
        height: 50px;
        border: 1px solid #7e7e7f;
        border-radius: 50%;
        font-size: 13px;
        white-space: nowrap;
        background-color: #3b3d42;
      }
    }
    .pie-wrap {
      flex: 1;
      display: flex;
      justify-content: space-around;
    }
    .tip {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: 700;
      color: #fff;
    }
  }
  > .tab-2 {
    margin-bottom: 42px;
    .t-2 {
      font-size: 16px;
      color: #fff;
      line-height: 1.45;
    }
    /deep/ td {
      height: 50px;
    }
    > .e2 {
      position: relative;
      width: 30%;
      margin-left: 2%;
      > .e-line {
        position: absolute;
        left: 0;
        top: 64px;
        width: 100%;
        height: 2px;
        background-color: #fff;
        &::after {
          content: '';
          position: absolute;
          z-index: 2;
          right: 0;
          top: 0;
          display: block;
          width: 0px;
          height: 0px;
          border-style: solid;
          border-width: 0 6px 9px 6px;
          border-color: transparent transparent #fff transparent;
          transform: translate(4px, -50%) rotate(90deg);
        }
        > .d1,
        > .d2,
        > .d3 {
          position: absolute;
          left: var(--x);
          width: 18px;
          height: 18px;
          border-radius: 50%;
          transform: translateY(-50%);
          background-color: #5ae7ec;
          > .text {
            position: absolute;
            left: 50%;
            text-align: center;
            white-space: nowrap;
            color: #fff;
            font-size: 14px;
            line-height: 1.3;
            transform: translate(-50%, -35px);
          }
        }
        > .d2 {
          > .text {
            transform: translate(-50%, 22px);
          }
        }
        .e-line-2 {
          position: absolute;
          top: 6px;
          left: var(--x);
          width: var(--w);
          height: 2px;
          color: #fff;
          font-size: 14px;
          border-radius: 4px;
          background-color: #fff;
          transform: translate(9px, 50px);
          &::before,
          &::after {
            content: '';
            position: absolute;
            left: 0;
            top: -8px;
            display: block;
            width: 2px;
            height: 8px;
            border-radius: 4px;
            background-color: #fff;
          }
          &::after {
            left: unset;
            right: 0;
          }
          > .text {
            text-align: center;
          }
        }
      }
    }
    > .e3 {
      position: relative;
      margin-left: 25px;
      margin-top: 24px;
      width: 40px;
      height: 100px;
      background-color: #9d9ea0;
      > .ball {
        position: absolute;
        left: 0;
        right: 0;
        top: var(--top);
        width: 32px;
        height: 32px;
        margin: auto;
        transform: translateY(-50%);
        border-radius: 50%;
        background-color: #5ae7ec;
      }
      > .text {
        position: absolute;
        right: 0;
        white-space: nowrap;
        font-size: 14px;
        color: #fff;
        &.text-1 {
          top: 0;
          transform: translate(110%, 0);
        }
        &.text-2 {
          top: var(--top);
          color: #5ae7ec;
          transform: translate(110%, -50%);
        }
        &.text-3 {
          top: 100%;
          transform: translate(110%, -100%);
        }
      }
    }
  }
  .not-data {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 500px;
    color: #fff;
    font-size: 30px;
  }
}
.info-gTEwi3yWTcTe5O.light {
  .title,
  .not-data {
    color: #727272;
  }
  > .line {
    background-color: #727272;
    &::before,
    &::after {
      background-color: #727272;
    }
    > .text {
      color: #727272;
    }
  }
  .bar {
    > .gray,
    > .blue_1,
    > .blue_2 {
      &::before {
        color: #727272;
      }
    }
  }
  > .tab-1 {
    .pie::before {
      background: conic-gradient(#3151d3 calc(var(--p) * 1%), #727272 0);
    }
    .pie::after {
      color: #000;
    }
    .pie > .t {
      color: #000;
      background-color: #fff;
    }
    .pie > .tip {
      color: #727272;
    }
    tr {
      &:nth-child(1) {
        background-color: #727272 !important;
      }
      &:nth-child(2) {
        color: #727272 !important;
      }
    }
  }
  .tab-2 {
    tr {
      &:nth-child(1) {
        background-color: #727272 !important;
      }
      &:nth-child(2) {
        color: #727272 !important;
      }
    }
    .e2 {
      > .e-line {
        background-color: #727272;
        &::after {
          border-color: transparent transparent #727272;
        }
        > .d1,
        > .d2,
        > .d3 {
          > .text {
            color: #727272;
          }
        }
        .e-line-2 {
          color: #727272;
          background-color: #727272;
          &::before,
          &::after {
            background-color: #727272;
          }
        }
      }
    }
    > .e3 {
      > .text {
        color: #727272;
        &.text-2 {
          color: #5ae7ec;
        }
      }
    }
  }
}
</style>
