export function once(fn) {
  let called = false;
  return function () {
    if (!called) {
      called = true;
      fn.apply(this, arguments);
    }
  };
}

export function debounce(fn, delay = 500) {
  let timer = null;
  return function () {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn.apply(this, arguments);
    }, delay);
  };
}

export function throttle(fn, delay = 500) {
  let timer = null;
  return function () {
    if (timer) {
      return;
    }
    fn.apply(this, arguments);
    timer = setTimeout(() => {
      timer = null;
    }, delay);
  };
}

export const codesToNameMap = (data) => {
  const getV = (list) => {
    return list.map((item) => {
      if (item.children instanceof Array) {
        return getV(item.children);
      }
      return item;
    });
  };
  return getV(data)
    .flat(Infinity)
    .reduce((p, c) => {
      p[c.value] = c.label;
      return p;
    }, {});
};

export const nestedArray = (value, z) => {
  let result = [value];
  for (let i = 0; i < z; i++) {
    result = [result];
  }
  return result;
};

// 个股默认值（全选）
export const codesToNameMap2 = (data) => {
  const getV = (data, ...args) => {
    return data.map((item) => {
      if (!item.children) {
        if (args.length) {
          return [...args, item.value];
        }
        // 第一层
        return [[[item.value]]];
      }
      return getV(item.children, ...args, item.value);
    });
  };
  return getV(data).flat(2);
};

// 大类默认值（全选）
export const categroyDefaultValue = (data) => {
  const getV = (data, ...args) => {
    return data.map((item) => {
      if (!item.children) {
        if (args.length) {
          return [...args, item.value];
        }
        return [[item.value]];
      }
      return [[item.value], ...getV(item.children, ...args, item.value)];
    });
  };
  return getV(data).flat(1);
};
